import React, { useContext, useEffect, useState } from "react";
import '../Investor/investor.css';
import investorimg from '../AboutCompany/image/investorimg.png'
import { LineAnimation } from "../../LineAinimation/LineAnimation";


import AOS from "aos";
import "aos/dist/aos.css";
//import context from "react-bootstrap/esm/AccordionContext";
import { Context } from "../../utils/context";



const Investor = () => {

    const { getData, imageUrl } = useContext(Context)
    const [getinvesterdata, setInvesterData] = useState();

    var HtmlToReactParser = require('html-to-react').Parser;
    var htmlToReactParser = new HtmlToReactParser();

    const getInvester = async () => {

        const res = await getData(`/invester`);

        if (res?.success) {
            setInvesterData(res.data)
        }
        else {
            console.error('Error fetching  data:', res?.error);
        }

    }

    useEffect(() => {
        AOS.init();
        AOS.refresh();

        getInvester();
    }, []);

    return (
        <>
            <section className='Investor'>

                <div className='container'>
                    {getinvesterdata?.map((data) => (
                        <div className='row'>
                            <div className='col-lg-6 col-md-6 mt-md-5  mt-0 mb-5'>
                                <div className='user-img-investor' data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
                                    <img src={imageUrl + data?.backimage} className="investorimg " alt="" />
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6 mt-md-5  mt-0 mb-5'>
                                <div className='text-holder-investor' data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine">
                                    <h1  className='special-font'><span >{data?.title}</span></h1>
                                    <h1>{data?.subtitle}</h1>
                                    <p className='mt-3'>{htmlToReactParser.parse(data?.description)}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>

            </section>

        </>
    )
}

export default Investor