import React from 'react'
import './uicustomer.css'
import ReviewAnimation from '../../animation/review-animation/ReviewAnimation'
import { LineAnimation } from "../../LineAinimation/LineAnimation";
import animationData1 from '../../animation/review-animation/review-animation.json';
import animationData2 from '../../animation/help-customer/support-help-customer.json'
import animationData3 from '../../animation/product-delivery/product-delivery.json';
const UiCustomer = () => {
    return (
        <>
            <section className='customer'>
                <LineAnimation />
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-4'>
                            <ReviewAnimation animationData={animationData1} />
                            <div className='text-holder text-center'>
                                <h4>CUSTOMER ACQUISITION</h4>
                                <h5>ATTRACTS NEW CUSTOMERS RAPIDLY</h5>
                            </div>

                        </div>
                        <div className='col-lg-4'>
                            <ReviewAnimation animationData={animationData2} />
                            <div className='text-holder text-center'>
                                <h4>RETAINS CUSTOMERS</h4>
                                <h5>HELPS WITH CUSTOMER RETENTION</h5>
                            </div>
                        </div>
                        <div className='col-lg-4'>
                            <ReviewAnimation animationData={animationData3} />
                            <div className='text-holder text-center'>
                                <h4>TIME AND COST</h4>
                                <h5>HELPS IN OPTIMIZING TIME AND COST</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default UiCustomer