import React, { useEffect, useRef } from "react";
import "../AboutBanner/aboutbanner.css";
// import aboutbanner from "../AboutBanner/image/aboutbanner.mp4";
import LazyLoad from "react-lazyload";

const AboutBanner = (props) => {
  const bannertitle = props.bannertitle;
  const bannersubtitle = props.bannersubtitle;
  const mediatype = props.mediatype;
  const aboutbanner = props.aboutbanner;

  return (
    <>
      <section className="about-banner-main">
        <div className="row ms-0 me-0">
          <div className="col-md-12 p-0">
            <div className="about-banner">
              {mediatype === "Video" ? (
                <LazyLoad offset={100} once>
                  <video
                    src={aboutbanner}
                    muted
                    autoPlay
                    loop
                    className="d-block video-class-about"
                  />
                </LazyLoad>
              ) : (
                <img
                  src={aboutbanner}
                  className="d-block video-class-about"
                  height={500}
                />
              )}
              <div className="img-overlay col-lg-6 col-md-12 ">
                <div className="text-holder-banner">
                  <p>{bannertitle}</p>
                  <h2>
                    <span>Reliable</span>
                    <span>And</span>
                    <span>Trustworthy</span>
                  </h2>
                  <hr className="horizental"></hr>
                </div>
              </div>

              <div className="overlay"></div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutBanner;
