import React, { useContext, useEffect, useState } from "react";
import "../Ideas/Ideas.css";
import Method from "../Animation/js/Method1.js";
import img1 from "../Image/planning.png";
import img2 from "../Image/construction.png";
import img3 from "../Image/Analytic-graph copy 2.png";

import AOS from "aos";
import "aos/dist/aos.css";
import { Context } from "../../utils/context.js";

const Ideas = () => {
  const { getData, imageUrl } = useContext(Context);
  var HtmlToReactParser = require("html-to-react").Parser;
  var htmlToReactParser = new HtmlToReactParser();

  const [getdevelopmentidea, setDevelopmentIdea] = useState();
  const getidea = async () => {
    const res = await getData(`/appdevidea`);

    if (res?.success) {
      setDevelopmentIdea(res.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  const [getdevelopmentapplication, setDevelopmentApplication] = useState();
  const getapplication = async () => {
    const res = await getData(`/appdevapplication`);

    if (res?.success) {
      setDevelopmentApplication(res.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  useEffect(() => {
    AOS.init();
    AOS.refresh();
    getidea();
    getapplication();
  }, []);
  return (
    <>
      <section className="Ideas">
        <div className="container">
          <div className="row">
            <div className="text-center title-ideas">
              <h3 className="ideas-title ideas-card-title mt-5">Ideas</h3>
            </div>
            <div class="col-lg-1 col-md-1 col-sm-1 mt-5">
              <div class="yellow-border mt-lg-5 mt-md-5 mt-3"></div>
            </div>
            <div class="col-lg-10 col-md-10 col-sm-10">
              <p className="ideas-subtitle text-center mt-lg-5 mt-md-5 mt-3">
                The Steps Involved In A Systems Lifecycle To Develop Computer
                Application Are
              </p>
              {/* <h1 class="Digital text-center  mt-lg-5 mt-md-5 mt-3"> The Steps Involved In A Systems Lifecycle To Develop Computer
                  Application Are</h1> */}
            </div>
            <div class="col-lg-1 col-md-1 col-sm-1  mt-lg-5 mt-md-5 mt-sm-5 mt-0">
              <div class="yellow-border mt-lg-5 mt-md-5 mt-3"></div>
            </div>

            {/* <div className="col-md-12">
              <div className="text-center title-ideas">
                <h3 className="ideas-title ideas-card-title mt-5">Ideas</h3>
                <p className="ideas-subtitle">
                  The Steps Involved In A Systems Lifecycle To Develop Computer
                  Application Are
                </p>
              </div>
            </div> */}
            {getdevelopmentidea?.map((data) => (
              <div
                className="col-xxl-4 xol-xl-4 col-lg-4 col-md-6 col-sm-6 mt-5"
                data-aos="fade-up"
                data-aos-duration="2000"
              >
                <div className="commerce">
                  <div class="accordion" id="accordionPanelsStayOpenExample">
                    <div class="accordion-item">
                      <h2
                        class="accordion-header"
                        id="panelsStayOpen-headingOne"
                      >
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#panelsStayOpen-collapseOne${data?.id}`}
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseOne"
                        >
                          <div className="service-vdo">
                            <img
                              src={imageUrl + data?.image}
                              class="card-img-top "
                              alt="..."
                            />
                          </div>

                          <div className=" service-heading mt-4">
                            <h5 className="heading-E text-center">
                              {data?.title}
                            </h5>
                          </div>
                        </button>
                      </h2>
                      <div
                        id={`panelsStayOpen-collapseOne${data?.id}`}
                        class="accordion-collapse collapse"
                        aria-labelledby="panelsStayOpen-headingOne"
                      >
                        <div class="accordion-body">
                          {htmlToReactParser.parse(data?.description)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}

          </div>
          {/* Method For Application */}

          {getdevelopmentapplication?.map((data) => (
            <div className="row">
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mt-5">
                <div className="method-title mt-md-5">
                  <h5>{data?.title}</h5>
                  <h3>{data?.subtitle}</h3>
                  <p>{htmlToReactParser.parse(data?.description)}</p>
                </div>
              </div>
              <div
                className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mt-5"
                data-aos="fade-left"
                data-aos-offset="300"
                data-aos-easing="ease-in-sine"
              >
                <div className=" text-center">
                  <video
                    src={imageUrl + data?.video}
                    height={300}
                    width={300}
                    muted
                    autoPlay
                    loop
                  />
                </div>

              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};

export default Ideas;
