import React, { useEffect, useState, useContext } from 'react'
import '../GraphicProject/GraphicProject.css'
import Fourth from '../animation/js/Fourth'
import Fifth from '../animation/js/Fifth'
import { Context } from '../../utils/context.js';


const GraphicProject = () => {

    const { getData, imageUrl } = useContext(Context);
    var HtmlToReactParser = require('html-to-react').Parser;
    var htmlToReactParser = new HtmlToReactParser();


    const [getdesigntype, setGraphicType] = useState();

    const gettype = async () => {
        const res = await getData(`/graphicdesigntype`);

        if (res?.success) {
            setGraphicType(res.data);
        }
        else {
            console.error('Error fetching  data:', res?.error);
        }
    }

    useEffect(() => {

        gettype();

    }, []);

    return (
        <>
            <section className='Graphicproject'>
                <div className='container'>
                    {getdesigntype?.map((data, index) => (
                        <div key={index} className='row mt-5'>
                            {index % 2 === 0 ? (
                                <>
                                    <div className='col-md-8'>
                                        <div className='text-holder-project mt-md-5'>
                                            <h3>{data?.title}</h3>
                                            <p>{htmlToReactParser.parse(data?.description)}</p>
                                        </div>
                                    </div>
                                    <div className='col-md-4'>
                                        <div className=' text-md-end text-center'>
                                            <video src={imageUrl + data?.video} height={200} width={200} autoPlay muted loop />
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className='col-md-4 order-md-0 order-2'>
                                        <div className='text-md-start text-center'>
                                            <video src={imageUrl + data?.video} height={200} width={200} autoPlay muted loop />
                                        </div>
                                    </div>
                                    <div className='col-md-8 order-md-0 order-1'>
                                        <div className='text-holder-project mt-md-5'>
                                            <h3>{data?.title}</h3>
                                            <p>{htmlToReactParser.parse(data?.description)}</p>
                                        </div>
                                    </div>

                                </>

                            )}
                        </div>
                    ))}
                </div>
              
            </section >
        </>
    )
}

export default GraphicProject