import React, { useContext, useState, useEffect } from 'react'
import '../GraphicSubProject/GraphicSubProject.css'
import pimg1 from '../image/pimg1.png'
import pimg2 from '../image/pimg2.png'
import pimg3 from '../image/pimg3.png'
import { Context } from '../../utils/context.js'

const GraphicSubProject = () => {

    const { getData, imageUrl } = useContext(Context);
    const [getgraphicproject, setGraphicProject] = useState();

    const getproject = async () => {
        const res = await getData(`/graphicdesignproject`);
        if (res?.success) {
            setGraphicProject(res.data);
        }
        else {
            console.error('Error fetching  data:', res?.error);
        }
    }

    useEffect(() => {
        getproject();
    }, [])
    return (
        <>
            <section className='GraphicSubProject'>
                <div className='container'>
                    <div className='row mt-5'>
                        <div className='col-md-1 text-center mt-md-5'>
                            <div className='yellow-line-title mt-lg-4 mt-md-4 mt-3'></div>
                        </div>
                        <div className='col-md-10 text-center title-project mt-md-5 mt-3'>
                            <h3>Our Brand Awareness Projects</h3>
                        </div>
                        <div className='col-md-1 text-center mt-md-5'>
                            <div className='yellow-line-title mt-lg-4 mt-md-4 mt-3'></div>
                        </div>
                        {getgraphicproject?.map((data) => (
                            <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 mt-5 mb-md-5'>
                                <img src={imageUrl + data?.image} className='pimg-class' alt='...' />
                            </div>
                        ))}
                       
                    </div>
                </div>
            </section >
        </>
    )
}

export default GraphicSubProject