import React, { useEffect } from 'react';
import HomeBanner from './HomeBanner/HomeBanner.js'
import WeFocus from './WeFocus/WeFocus.js'
import Services from './Services/Services.js'
import Partner from './ItPartner/Partner/Partner.js'
import OurWork from './OurWork/OurWork.js'
import Slider from './Slider/Slider.js'
import Companies from './Companies/Companies.js'
import Popup from '../AboutUs/Popup/Popup.js'
import ScrollTopBottom from '../ScrollTTopBottom/ScrollTopBottom.js'
import AOS from "aos";
import "aos/dist/aos.css";

const HomePage = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  AOS.init({ disable: 'mobile' });
  return (



    <>
      <HomeBanner />
      <WeFocus />
      <Services />
      <Partner />
      <OurWork />
      <Slider />
      <Companies />
      <Popup />
      <ScrollTopBottom />
    </>
  )
}

export default HomePage;