import React from 'react'
import './development.css'
import Isometric from '../../animation/isometric/Isometric'
import Developmentjson from '../../animation/Develop/Developmentjson'
const Development = () => {
    return (
        <>
            <section className='development-ui'>
                <div className='container'>
                    <div className='row'>
                        <div className='heading-holder text-center mt-3'>
                            <h4>UI/UX Design & Development</h4>
                        </div>
                        <div className='col-lg-6 '>
                            <div className='text-holder mt-lg-5 mt-md-5 mt-2'>
                                <p>UI means user interface and UX means user experience and both of them are involved in the process of designing the application. The user interface(UI) design and user experience(UX) design are treated as complementary to each other.</p>

                                <p>Our team conducts research to identify ways to make their brand stick. Using their logo, colors, tagline, and language. In the UX design, you tailor the experience for the user so that it is appealing to them and profitable for you. They think of the users’ problems and their solutions to these problems.</p>

                                <p>Our team creates user personas based on customer research to model their experiences. The user experience consists of the buttons that users click upon, the text they read, the images, sliders, text entry fields, and all the rest of the items the user interacts with. All visual elements, interaction, or animation must all be designed.</p>
                            </div>
                        </div>
                        <div className='col-lg-3 col-6'>
                        <Isometric/>
                        </div>
                        <div className='col-lg-3 col-6'>
                            <Developmentjson/>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Development