import React, { useContext, useEffect, useState } from 'react';
import BlogText from './BlogText/BlogText.js'
import BlogBanner from './BlogBanner/BlogBanner.js'
import { Context } from '../utils/context.js';

const Blog = () => {

    const { getData, imageUrl } = useContext(Context);
    const [getblogdata, setBlogData] = useState();

    const getblog = async () => {

        const res = await getData(`/globalbanner?bannertype=blogs`);

        if (res?.success) {
            setBlogData(res.data.list);
        }
        else {
            console.error('Error fetching  data:', res?.error);
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);

        getblog();

    }, []);

    return (
        <div>
            {getblogdata?.map((data) => (
                <BlogBanner blogbannertitle={data?.title} blogbannersubtitle={data?.subtitle} homebanner={imageUrl + data?.video} mediatype ={data?.mediatype}/>
            ))}
            <BlogText />

        </div>
    )
}

export default Blog;