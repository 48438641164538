import React, { useState, useContext, useEffect } from "react";
import "./DigitalMarketingBanner.css";
// import pc from '../video/pc.mp4';
import LazyLoad from "react-lazyload";

import { Context } from '../../utils/context.js';

const DigitalMarketingBanner = (props) => {


  const { getData, imageUrl } = useContext(Context);
  const [getdigitalbanner, setDigitalbanner] = useState();


  const getdigibanner = async () => {

    const res = await getData(`/globalbanner?bannertype=digitalmarketing`);
    if (res?.success) {

      setDigitalbanner(res.data.list);
    }
    else {
      console.error('Error fetching  data:', res?.error);
    }

  }


  useEffect(() => {

    getdigibanner();

  }, []);

  return (

    <>
      <section className="ServiceBanner about-banner-main ">
        <div className="container-fluid p-0">
          <div className="row me-0 ms-0">
            {getdigitalbanner?.map((data) => (
              <div className="col-md-12 p-0">
              <div className="about-banner">
                {data?.mediatype === 'Video' ? (
                  <>
                    <LazyLoad offset={100} once>
                      <video
                        src={imageUrl + data?.video}
                        muted
                        autoPlay
                        loop
                        className="d-block video-class-about"
                      />
                    </LazyLoad>
                  </>
                ) : (
                  <img
                    key={data?.id} src={imageUrl + data?.video}
                    className="d-block w-100 video-class-grid"
                    style={{ width: '100%'}}
                  />
                )}
               
                <div className="content">
                  <h1 className="website">
                    {data?.title}
                  </h1>
                  <p className="buisness">
                    {data?.subtitle}
                  </p>
                </div>

                <div class="overlay"></div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default DigitalMarketingBanner;
