import React, { useContext, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import '../../BlogInnerPage/BlogInnerText/BlogInnerText.css';
// import { LineAnimation } from "../../LineAinimation/LineAnimation";

import { Context } from '../../utils/context.js';

const BlogInnerText = () => {
    const { getData, imageUrl } = useContext(Context);

    const [getblogdes, setBlogDesc] = useState();

    var HtmlToReactParser = require('html-to-react').Parser;
    var htmlToReactParser = new HtmlToReactParser();

    const { id } = useParams();

    
    const getblogde = async () => {


        const res = await getData(`/blog/${id}`);

        if (res?.success) {
            setBlogDesc(res.data);
        }

        else {
            console.error('Error fetching  data:', res?.error);
        }
    }

    useEffect(() => {

        getblogde();

    }, []);

    return (
        <>
            <section className='Blog-Inner '>
                {/* <LineAnimation/> */}
                <div className='container'>

                    <div className='row'>

                        <div className='col-md-12'>

                            <div className='text-holder-bloginner'>
                                <h5 className='mt-5'>{getblogdes?.contenttitle}</h5> 
                                
                                
                                <p>{htmlToReactParser.parse(getblogdes?.contentdescription)}</p>
                                
                                
                               
                            </div>

                        </div>

                    </div>

                </div>
            </section >
        </>
    )
}

export default BlogInnerText