import React from 'react'
import arrowimg from '../image/arrow-dropright.png';
const ListDesign = ({ heading, modalId }) => {
    return (
        <>
            <div className='list-design'>
                <div className='d-flex'>
                    <div className='design-heading'>
                        <h5>{heading}</h5>
                       
                    </div>
                    <div className='arrow-icon'>
                        <img src={arrowimg} className='arrow-img' alt='' type='button' data-bs-toggle='modal' data-bs-target={`#id${modalId}`} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default ListDesign