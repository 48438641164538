import React, { useEffect, useState } from "react";
import "../Companies/companies.css";
import company1 from "./image/Logo/company1.png";
import company2 from "./image/Logo/company2.png";
import company3 from "./image/Logo/company3.png";
import company4 from "./image/Logo/company4.png";
import company5 from "./image/Logo/company5.png";
import company6 from "./image/Logo/company6.png";
import company7 from "./image/Logo/company7.png";
import company8 from "./image/Logo/company8.png";
import company9 from "./image/Logo/company9.png";
import company10 from "./image/Logo/company10.png";
import company11 from "./image/Logo/company11.png";
import company12 from "./image/Logo/company12.png";
import company13 from "./image/Logo/company13.png";
import company14 from "./image/Logo/company14.png";
import company15 from "./image/Logo/company15.png";
import company16 from "./image/Logo/company16.png";
import company17 from "./image/Logo/company17.png";
import company18 from "./image/Logo/company18.png";
import company19 from "./image/Logo/company19.png";
import company20 from "./image/Logo/company20.png";
import company21 from "./image/Logo/company21.png";
import company22 from "./image/Logo/company22.png";
import company23 from "./image/Logo/company23.png";
import company24 from "./image/Logo/company24.png";
import company25 from "./image/Logo/company25.png";
import company26 from "./image/Logo/company26.png";
import company27 from "./image/Logo/company27.png";

import { Pagination, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";

import AOS from "aos";
import "aos/dist/aos.css";

import { useContext } from "react";
import { Context } from "../../utils/context";

const Companies = () => {
  const { getData, imageUrl } = useContext(Context);
  const [getcompaniesData, setCompaniesdata] = useState();

  const getCompanies = async () => {
    const res = await getData(`/company`);

    if (res?.success) {
      let images = [];
      let temp = [];
      //console.log(images.length);

      for (const val of res.data) {
        if (temp.length === 6) {
          images.push(temp.slice());
          temp = [];
        }
        temp.push(val);
      }

      if (temp.length > 0) {
        images.push(temp);
      }

      setCompaniesdata(images);
    } else {
    }
  };

  useEffect(() => {
    AOS.init();
    AOS.refresh();

    getCompanies();
  }, []);
  return (
    <>
      <section className="companies-partner">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-6 mt-5">
              <div className="title-companies text-center mt-5">
                <h2>
                  Trusted by 230+ <br /> companies worldwide
                </h2>
              </div>
            </div>

            <div className="col-md-6 mb-5">
              <Swiper
                // install Swiper modules
                modules={[Pagination, Autoplay]}
                spaceBetween={10}
                slidesPerView={1}
                navigation={false}
                pagination={{ clickable: true }}
                onSwiper={(swiper) => console.log(swiper)}
                onSlideChange={() => console.log("slide change")}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                  loop: true,
                }}
              >
                {getcompaniesData?.map((data) => (
                  <SwiperSlide>
                    <div className="carousel-item banner-slider active">
                      <div className="row mb-5">
                        {data?.map((title) => (
                          <div className="col-md-4  text-center">
                            <img
                              key={title?.id}
                              src={imageUrl + title?.logo}
                              className="companyimg mt-3"
                              alt="..."
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              
              </Swiper>
            </div>
           
          </div>

          <div className="row " data-aos="fade-up" data-aos-duration="2000">
            <div className="col-lg-10 col-md-12 mx-auto">
              <div className="box1 mt-lg-4 mt-md-2 mt-2">
                <div className=" row me-0 ms-0">
                  <div className="col-md-8">
                    <div className="title-contact ">
                      <h2>For any queries get in touch with us</h2>
                      <p>
                        Accelerate the growth of your business with our digital
                        solution and services. Know more about us and avail of
                        our services.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="col-md-4 text-center mt-3">
                      <button
                        type="button"
                        className="contact-companies-class"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#contact_off-canvas"
                        aria-controls="offcanvasTop"
                      >
                        Contact
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Companies;
