// import React from 'react'
import React, { useEffect, useContext, useState } from 'react';
import BlogBanner from '../Blog/BlogBanner/BlogBanner.js'
import Brand from './Brand/Brand.js'
import BrandProject from './BrandProject/BrandProject.js'
import Dmarketting from './Dmarketting/Dmarketting.js'
import Popup from '../AboutUs/Popup/Popup.js'
import BrandBanner from './BrandBanner.js'
import { Context } from '../utils/context.js'

const BrandAwarness = () => {

    const { getData, imageUrl } = useContext(Context);

    
    const [getbrandbanner, setBrandbanner] = useState();
    const getbrand = async () => {

        const res = await getData(`/globalbanner?bannertype=brandawareness`);

        if (res?.success) {

            setBrandbanner(res.data.list);
        }
        else {
            console.error('Error fetching  data:', res?.error);
        }

    }
    useEffect(() => {
        window.scrollTo(0, 0);
        getbrand();
    }, []);

    return (
        <div>
            {getbrandbanner?.map((item) => (
                <BrandBanner brandtitle={item?.title} brandsubtitle={item?.subtitle} brandvideo={imageUrl + item?.video} mediatype={item?.mediatype} />))}
            <Brand />
            <Dmarketting />
            <BrandProject />
            <Popup />
        </div>
    )
}

export default BrandAwarness;