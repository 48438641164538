
import React, { useEffect } from 'react';
import DigitalMarketingBanner from './DigitalMarketingBanner/DigitalMarketingBanner';
import DigitalContent from "./DigitalContent/DigitalContent";
import Popup from '../AboutUs/Popup/Popup.js'

const DigitalMarketing = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return (
        <div>
            <DigitalMarketingBanner/>
            <DigitalContent/>
            <Popup />
        </div>
         
         )
     }
     
     export default DigitalMarketing;