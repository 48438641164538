import React, { useContext, useState } from 'react'
import '../CaseStudyBanner/CaseStudyBanner.css';


// import aboutbanner from '../video/case.mp4'
import { useEffect, useRef } from "react";
import LazyLoad from 'react-lazyload';

import { Context } from '../../utils/context';

const CaseStudyBanner = () => {


    const { getData, imageUrl } = useContext(Context);
    const [getcasedata, setCaseData] = useState();

    const getcase = async () => {

        const res = await getData(`/globalbanner?bannertype=casestudy`);

        if (res?.success) {
            console.log(res.data.list);
            setCaseData(res.data.list);
        }
        else {

        }
    }

    useEffect(() => {

        getcase();

    }, []);

    return (
        <>
            <section className="about-banner-main">
                <div className='container-fluid p-0'>
                    <div className="row ms-0 me-0">
                        <div className='col-md-12 p-0'>
                            {getcasedata?.map((data) => (
                                <div className="about-banner">
                                    {data?.mediatype === 'Video' ? (
                                        <>
                                            <LazyLoad offset={100} once>

                                                <video src={imageUrl + data?.video} muted
                                                    autoPlay
                                                    loop className="d-block video-class-about" />


                                            </LazyLoad>
                                        </>
                                    ) : (
                                        <img
                                            key={data?.id} src={imageUrl + data?.video}
                                            className="d-block w-100 video-class-grid"
                                            height={500}
                                        />
                                    )}
                                    <div class="overlay">
                                        <div class="top-left">
                                            <p className='case'>{data?.title}</p>
                                            <h1 className='design'>
                                              A Good Design<br/> Speaks For <br/>Itself  </h1>
                                                {/* {data?.subtitle}     */}
                                                {/* {data?.subtitle}     */}                                                 
                                                {/* {data?.subtitle}     */}
                                              
                                                <hr className='horizental'></hr>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CaseStudyBanner;
