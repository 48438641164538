import React from 'react'
import Lottie from 'react-lottie';
import * as animationData from '../122694-brand-identity.json';


function Sixth() {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    return (
        <>
            <div className='fourth-brand'>
                <Lottie
                    options={defaultOptions}
                />
            </div>
        </>
    )
}

export default Sixth;