import React, { useEffect, useContext, useState } from 'react';
import Customer from './Customer/Customer.js'
import BlogBanner from '../Blog/BlogBanner/BlogBanner.js';
import AppBanner from './AppBanner.js';
import Ideas from './Ideas/Ideas.js'
import DevelopProject from './DevelopProject/DevelopProject.js'
import Popup from '../AboutUs/Popup/Popup.js'
import LazyLoad from 'react-lazyload';
import { Context } from '../utils/context.js';

const AppDevelopment = () => {

    const { getData, imageUrl } = useContext(Context);
    const [appbanner, setAppbanner] = useState();


    const getappdev = async () => {

        const res = await getData(`/globalbanner?bannertype=appdevelopment`);

        if (res?.success) {
           
            setAppbanner(res.data.list);
        }
        else {
            console.error('Error fetching  data:', res?.error);
        }

    }
    useEffect(() => {
        window.scrollTo(0, 0);
        getappdev();

    }, []);

    return (
        <div>
            {appbanner?.map((item) => (
                <AppBanner key={item?.id} apptitle={item?.title} appsubtitle={item?.subtitle} appvideo={imageUrl + item?.video} mediatype={item?.mediatype} />
            ))}
            {/* <AppBanner content={"hello"}/> */}
            <Customer />
            <Ideas />
            <DevelopProject />
            <Popup />
        </div>
    )
}

export default AppDevelopment;