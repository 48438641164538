// import React from "react";
import React, { useEffect } from 'react';
import OurServiceBanner from "./OurServiceBanner/OurServiceBanner";
import OurService from "./OurService/OurService";
import Popup from '../AboutUs/Popup/Popup.js'


const OurServices = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  
  return (
    <div>
      <OurServiceBanner />
      <OurService/>
      <Popup />
    </div>
  );
};

export default OurServices;
