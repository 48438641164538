import React, { useEffect, useState } from "react";
import "./ourwork.css";
import img1 from "./image/img1.png";
import img2 from "./image/img2.png";
import img3 from "./image/img3.png";
import AOS from "aos";
import "aos/dist/aos.css";
import axios from "axios";
import { useContext } from "react";
import { Context } from "../../utils/context";
import { Pagination, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
const OurWork = () => {
  const { getData, postData, imageUrl } = useContext(Context);
  const [getworkData, setWorkdata] = useState();

  const getWork = async () => {
    const res = await getData(`/work`);

    if (res?.success) {
      setWorkdata(res.data);
    } else {
    }
  };

  useEffect(() => {
    getWork();
  }, []);

  return (
    <>
      <section className="ourwork p-0">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="text-holder-ourwork mt-3">  
                <h2>Our Work</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
              </div>
            </div>

            <Swiper
              modules={[Navigation, Pagination]}
              spaceBetween={20}
              navigation={false}

              initialSlide={1}
              centeredSlides={true}
              autoplay={{ delay: 5000 }} // Add autoplay with a delay of 5000ms (5 seconds)
              className="my-swiper mt-5"
              breakpoints={{
                280: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                // when window width is >= 320px
                320: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                // when window width is >= 480px
                480: {
                  slidesPerView: 1,
                  spaceBetween: 30,
                },
                // when window width is >= 640px
                640: {
                  slidesPerView: 1,
                  spaceBetween: 40,
                },

                768: {
                  slidesPerView: 1,
                  spaceBetween: 40,
                },

                1024: {
                  slidesPerView: 1,
                  spaceBetween: 40,
                },

                1200: {
                  slidesPerView: 3  ,
                  spaceBetween: 40,
                },

                1400: {
                  slidesPerView: 3,
                  spaceBetween: 40,
                },
              }}
            >
              <div className="main-image-class">
                <div className="row justify-content-center mx-auto  ">
                  {getworkData?.map((data) => (
                    <SwiperSlide>
                      <div className="main-img-holder mb-5">
                        <div
                          className="img-ourwork-class mar-set"
                          data-aos="fade-right"
                          data-aos-offset="300"
                          data-aos-easing="ease-in-sine"
                        >
                          <img
                            key={data.id}
                            src={`${imageUrl}${data.backimage}`}
                            className="imgwork"
                            alt="this is image"
                          />
                          <div className="text-holder-img">
                            <h2>{data.title}</h2>
                            <p>{data.description}</p>
                          </div>
                          <div className="overlay"></div>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </div>
              </div>
            </Swiper>


          </div>
        </div>
      </section>
    </>
  );
};

export default OurWork;
