import React, { useEffect, useState, useContext } from "react";
import "./OurService.css";

import group from "../images/group.png";
import bulb from "../images/bulb.png";
import iphone from "../images/iphone.png";
import laptop from "../images/laptop.png";
import study from "../images/study.png";
import html from "../images/html.png";
import api from "../images/api.png";
import img4 from "../images/img4.png";
import img3 from "../images/img3.png";
import img1 from "../images/img1.png";
import img2 from "../images/img2.png";

import EcomWeb from "../../animation/e-com-web/EcomWeb";
import Youtube from "../../animation/yotube/yotube";
import Learning from "../../animation/Learning/Learning";
import Study from "../../animation/Study/Study";
import Experts from "../../animation/Experts/Experts";
// import Audience from "../../animation/Mobile/Mobile";
import { LineAnimation } from "../../LineAinimation/LineAnimation";
import animationData1 from "../../animation/Mobile/mobile.json";
import animationData2 from "../../animation/Experts/experts.json";
import animationData3 from "../../animation/Audience/audience.json";
import AOS from "aos";
import "aos/dist/aos.css";
import { Context } from "../../utils/context.js";
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// import './styles.css';

// import required modules
import { Pagination } from 'swiper/modules';

const OurService = () => {
  const { getData, imageUrl } = useContext(Context);
  var HtmlToReactParser = require("html-to-react").Parser;
  var htmlToReactParser = new HtmlToReactParser();

  const [getwebcompany, setWebCompany] = useState();
  const getcompany = async () => {
    const res = await getData(`/webdevcompany`);

    if (res?.success) {
      setWebCompany(res?.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  const [getwebtechnology, setWebTechnology] = useState();
  const gettechnology = async () => {
    const res = await getData(`/webdevtechnology`);
    if (res?.success) {
      setWebTechnology(res?.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  const [getwebersearch, setWebResearch] = useState();
  const getresearch = async () => {
    const res = await getData(`/webdevresearch`);
    if (res?.success) {
      setWebResearch(res?.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  const [getwebwesitetype, setWebWebsiteType] = useState();
  const getwebsite = async () => {
    const res = await getData(`/webdevstaticdynamic`);
    if (res?.success) {
      setWebWebsiteType(res?.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  //const [getwebproject, setWebProject] = useState();
  // const getproject = async () => {
  //   const res = await getData(`/webdevproject`);
  //   if (res?.success) {
  //     setWebProject(res?.data);
  //   } else {
  //     console.error("Error fetching  data:", res?.error);
  //   }
  // };

  const [getwebproject, setWebProject] = useState();
  const [getwebproject1, setWebProject1] = useState();
  const getproject = async () => {
    const res = await getData(`/webdevproject`);
    if (res?.success) {

      if (res.data?.even_projects) {
        setWebProject(res.data.even_projects);
      }

      if (res.data?.odd_projects) {
        setWebProject1(res.data.odd_projects);
      }

    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };



  useEffect(() => {
    getcompany();
    gettechnology();
    getresearch();
    getwebsite();
    getproject();
  }, []);

  return (
    <>
      <section className="Our-Service">
        <LineAnimation />
        <div className="container">

          <div className="row me-0 ms-0 ">



            <div className="col-xl-4 col-lg-4  col-md-12 mt-5" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-delay="300" data-aos-offset="0">
              <Experts animationData={animationData2} />

              <h4 className="heading text-center">
                <span className="E"> E-COMMERCE EXPERTS</span>

              </h4>
              <p className="easy text-center">EASY TO NAVIGATE</p>
            </div>

            <div className="col-xl-4 col-lg-4 col-md-12 mt-5" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-delay="300" data-aos-offset="0">
              <Experts animationData={animationData2} />

              <h4 className="heading text-center">
                <span className="E">UI/UX FRIENDLY</span>
              </h4>
              <p className="easy text-center">UI/UX FRIENDLY</p>
            </div>

            <div className="col-xl-4 col-lg-4  col-md-12 mt-5" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-delay="300" data-aos-offset="0">
              <Experts animationData={animationData2} />

              <h4 className="heading text-center">ENGAGING AUDIENCES</h4>
              <p className="easy text-center">BUILD PERFECT WEBSITES</p>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-1 col-md-1 col-sm-1 mt-4">
              <div class="yellow-border mt-md-5 mt-sm-5 mt-0">

              </div>
            </div>
            <div class="col-lg-9 col-md-9 col-sm-10">
              <h1 class="web mt-md-5 mt-sm-5 mt-3 text-center">
                <span class="pune">Web Development Company In Pune</span>
              </h1>
            </div>
            <div class="col-lg-1 col-md-1 col-sm-1 mt-md-4 mt-sm-4 mt-0">
              <div class="yellow-border mt-md-5 mt-sm-5 mt-0"></div>
            </div>
          </div>

          {getwebcompany?.map((item, index) => (
            <div key={index} className="row me-0 ms-0  mt-5">
              {index % 2 === 0 ? (
                <>
                  <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-10 my-auto mt-lg-5 ">
                    <div className="content1">
                      <label>
                        <h1 className="team">
                          {htmlToReactParser.parse(item?.title)}
                        </h1>
                      </label>
                      <label>
                        <h1 className="team">
                          <span>{item?.subtitle}</span>
                        </h1>
                      </label>
                      <p className="collective ">
                        {htmlToReactParser.parse(item?.description)}
                      </p>
                    </div>

                  </div>
                  <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 my-auto ">
                    <div className="img-1">
                      <img
                        data-aos="fade-left"
                        data-aos-offset="300"
                        data-aos-easing="ease-in-sine"
                        src={imageUrl + item?.image}
                        className="group"
                        alt=""
                      />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 my-auto mt-5">
                    <div className="img-1">
                      <img
                        data-aos="fade-left"
                        data-aos-offset="300"
                        data-aos-easing="ease-in-sine"
                        src={imageUrl + item?.image}
                        className="group"
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-10 my-auto mt-5">
                    <div className="content1">
                      <label>
                        <h1 className="team">
                          {htmlToReactParser.parse(item?.title)}
                        </h1>
                      </label>
                      <label>
                        <h1 className="team">
                          <span>{item?.subtitle}</span>
                        </h1>
                      </label>
                      <p className="collective ">
                        {htmlToReactParser.parse(item?.description)}
                      </p>
                    </div>
                  </div>
                </>
              )}
            </div>
          ))}

          {/* Accordian */}
          <div class="row">
            <div class=" col-md-1 col-sm-1 mt-md-4 mt-sm-4 mt-0">
              <div class="yellow-border mt-md-5 mt-sm-5 mt-0"></div>
            </div><div class="col-lg-10 col-md-10 col-sm-10">
              <h1 class="custom mt-md-5 mt-sm-5 mt-3 text-center">Our Custom Web Development Technology <span>Services Are Secure And Flexible</span></h1>
            </div>
            <div class=" col-md-1 col-sm-1 mt-md-4 mt-sm-4 mt-0">
              <div class="yellow-border mt-md-5 mt-sm-5 mt-0"></div>
            </div>
          </div>
          <div
            className="row me-0 ms-0  mt-5"
            data-aos="fade-up"
            data-aos-duration="2000"
          >
            {getwebtechnology?.map((item) => (
              <div className="col-lg-4 col-md-6 mt-3">

                <div className="commerce">
                  <div
                    className="accordion"
                    id="accordionPanelsStayOpenExample"
                  >
                    <div className="accordion-item">
                      <h2
                        className="accordion-header"
                        id="panelsStayOpen-headingOne"
                      >
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#panelsStayOpen-collapseOne${item?.id}`}
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseOne"
                        >
                          <div className=" service-vdo">
                            <video
                              src={imageUrl + item?.video}
                              className=" video-sites"
                              autoPlay
                              muted
                              loop
                            />
                          </div>

                          <div className="service-heading">
                            <h4 className="heading-E">{item?.title}</h4>
                          </div>
                        </button>
                      </h2>
                      <div
                        id={`panelsStayOpen-collapseOne${item?.id}`}
                        className="accordion-collapse collapse"
                        aria-labelledby="panelsStayOpen-headingOne"
                      >
                        <div className="accordion-body">
                          {htmlToReactParser.parse(item?.description)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            ))}
            {/* <div className="col-md-4 mt-3">
              <div className="commerce">
                <div class="accordion" id="accordionPanelsStayOpenExample">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="panelsStayOpen-headingTwo">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseTwo"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseTwo"
                      >

                        <Youtube />
                        <h4 className="heading-E">Social Media Websites</h4>
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseTwo"
                      class="accordion-collapse collapse"
                      aria-labelledby="panelsStayOpen-headingTwo"
                    >
                      <div class="accordion-body">
                        Our team is expert in designing and creating the best
                        e-commerce websites, which adds value to a business and
                        helps in increasing sales, which makes the business
                        easily manageable, is user-friendly, and is easy to
                        navigate for the users.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 mt-3">
              <div className="commerce">
                <div class="accordion" id="accordionPanelsStayOpenExample">
                  <div class="accordion-item">
                    <h2
                      class="accordion-header"
                      id="panelsStayOpen-headingThree" >
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#panelsStayOpen-collapseThree"
                        aria-expanded="false"
                        aria-controls="panelsStayOpen-collapseThree"
                      >

                        <Learning />
                        <h4 className="heading-E">Education websites</h4>
                      </button>
                    </h2>
                    <div
                      id="panelsStayOpen-collapseThree"
                      class="accordion-collapse collapse"
                      aria-labelledby="panelsStayOpen-headingThree"
                    >
                      <div class="accordion-body">
                        Our team is expert in designing and creating the best
                        e-commerce websites, which adds value to a business and
                        helps in increasing sales, which makes the business
                        easily manageable, is user-friendly, and is easy to
                        navigate for the users.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <LineAnimation />

        {/* main Section */}
        <div className="Research-main">
          {getwebersearch?.map((item, index) => (
            <div key={index} className="container">
              {index % 2 === 0 ? (
                <>
                  <div className="main1">
                    <div className="row me-0 ms-0  mt-5">
                      <div className=" col-md-6">
                        <h4 className="research title">{item?.title}</h4>
                        <h1 className="yellow-content title">
                          {item?.subtitle}
                        </h1>
                        <p className="profcyma title">
                          {htmlToReactParser.parse(item?.description)}
                        </p>
                      </div>
                      <div className="col-xxl-6 col-xl-6 col-l-6 col-md-6 mt-5">
                        <div className="background-yellow">
                          <div className="laptop-img">
                            <img
                              src={imageUrl + item?.image}
                              className="laptop"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="main2">
                    <div className="row me-0 ms-0  mt-5">
                      <div className="col-xxl-6 col-xl-6 col-l-6 col-md-6  mt-sm-0 ">
                        <div className="background-yellow2">
                          <div className="study-img">
                            <img
                              src={imageUrl + item?.image}
                              className="study"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className=" col-md-6">
                        <div className="research-content">
                          <h4 className="research title">{item?.title}</h4>
                          <h1 className="yellow-content title">
                            {item?.subtitle}
                          </h1>
                          <p className="profcyma title">
                            {htmlToReactParser.parse(item?.description)}
                          </p>
                        </div>

                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          ))}
          {/* <div className="main2">
            <div className="row me-0 ms-0 ">
              <div className="col-xxl-6 col-xl-6 col-l-6 col-md-6">
                <div className="background-yellow2">
                  <div className="study-img">
                    <img src={study} className="study" alt="" />
                  </div>
                </div>
              </div>
              <div className="col-md-6 mt-5">
                <h4 className="research">Our Business Process For Website </h4>
                <h1 className="yellow-content">Development</h1>
                <ul>
                  <li>Idea and its conceptualization</li>
                  <li>Wireframing</li>
                  <li>Refined coding</li>
                  <li>QA of the project</li>
                  <li>Delivery</li>
                </ul>
              </div>
            </div>
          </div> */}
        </div>

        {/* card section */}
        <div className="website mt-5">
          <div className="container">
            <div className="row me-0 ms-0 ">
              <div className="col-xl-8 col-lg-10 col-md-12 col-12 mx-auto">
                <div className="row me-0 ms-0 ">
                  {getwebwesitetype?.map((item, index) => (
                    <div key={index} className="col-md-6 col-sm-6 col-12 mt-3">
                      {index % 2 === 0 ? (
                        <>
                          <div
                            className="static-content"
                            data-aos="fade-up"
                            data-aos-duration="2000"
                          >
                            <div className="html-img">
                              <img
                                src={imageUrl + item?.image}
                                className="html"
                                alt=""
                              />
                            </div>
                            <div className="dynamic">
                              <h4 className="static title">{item?.title}</h4>
                              <p className="static-p title">
                                {htmlToReactParser.parse(item?.description)}
                              </p>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div
                            className="static-content"
                            data-aos="fade-up"
                            data-aos-duration="2000"
                          >
                            <div className="dynamic">
                              <h4 className="static title">{item?.title}</h4>
                              <p className="static-p title">
                                {htmlToReactParser.parse(item?.description)}
                              </p>
                            </div>
                            <div className="html-img">
                              <img
                                src={imageUrl + item?.image}
                                className="html"
                                alt=""
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  ))}

                  {/* <div className="col-md-6 col-sm-7  col-12 mt-3 ">
                    <div className="static-content" data-aos="fade-up" data-aos-duration="2000">
                      <div className="html-img">
                        <img src={html} className="html" alt="" />
                      </div>

                      <div className="dynamic content">
                        <h4 className="static">DYNAMIC WEBSITES</h4>
                        <p className="static-p">
                          We at Profcyma Global have a team of highly dedicated
                          and enthusiastic people who are experts in designing
                          the perfect versions of websites that fulfill all the
                          requirements of clients and their businesses.
                        </p>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* last section */}


        <div className="project-section">


          <div className="row">
            <div className="col-lg-8  mx-auto">
              <Swiper pagination={true} modules={[Pagination]} className="mySwiper ">
                {getwebproject?.map((data, slideIndex) => (
                  <SwiperSlide key={slideIndex}>
                    <div className="last-section  mt-5">
                      {data?.map((item, index) => (
                        <div className="row imgggggalign me-0 ms-0 " key={index}>
                          {(index % 2 === 0) ? (
                            <>
                              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                <div className="man-bag text-center" data-aos="fade-up" data-aos-duration="2000">
                                  <img src={imageUrl + item?.backimage} className="images" alt="" />
                                  <h4 className="agro">{item?.title}</h4>
                                </div>
                              </div>
                              {(index + 1 < data.length) && ( // Ensure there's another item to show
                                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                  <div className="man-bag mb-5 text-center" data-aos="fade-up" data-aos-duration="2000">
                                    <img src={imageUrl + data[index + 1]?.backimage} className="images" alt="" />
                                    <h4 className="power">{data[index + 1]?.title}</h4>
                                  </div>
                                </div>
                              )}
                            </>
                          ) : null}
                        </div>
                      ))}
                    </div>
                  </SwiperSlide >
                ))}
              </Swiper>


              <div className="row me-0 ms-0  verlayaprsoe">
                {/* <div className="col-md-4"></div> */}
                <div className="col-md-5 mx-auto">
                  <h1 className="project text-center">
                    Our Website <br></br>Development<br></br> Projects
                  </h1>
                </div>
                {/* <div className="col-md-4"></div> */}
              </div>
              <Swiper pagination={true} modules={[Pagination]} className="mySwiper">
                {getwebproject1?.map((data, index) => (
                  <SwiperSlide key={index}>
                    <div className="last-section mt-5">
                      {data?.map((item, itemIndex) => (
                        <div className="row imgggggalign me-0 ms-0" key={itemIndex}>
                          {itemIndex % 2 === 0 ? (
                            <>
                              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                <div className="man-bag text-center" data-aos="fade-up" data-aos-duration="2000">
                                  <img src={imageUrl + item?.backimage} className="images" alt="" />
                                  <h4 className="power">{item?.title}</h4>
                                </div>
                              </div>
                              {data[itemIndex + 1] && (
                                <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                  <div className="man-bag text-center" data-aos="fade-up" data-aos-duration="2000">
                                    <img src={imageUrl + data[itemIndex + 1]?.backimage} className="images" alt="" />
                                    <h4 className="agro">{data[itemIndex + 1]?.title}</h4>
                                  </div>
                                </div>
                              )}
                            </>
                          ) : null}
                        </div>
                      ))}
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
        </div>



      </section>
    </>
  );
};

export default OurService;


