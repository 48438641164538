import React, { useEffect, useState } from "react";
import "./CaseStudyContent.css";
import Nutrition from "../images/Nutrition.png";
import Fizo from "../images/Fizo.png";
import Dudhbhate from "../images/Dudhbhate.png";
import LMS from "../images/LMS.png";
import Audio from "../images/Audio.png";
import { LineAnimation } from "../../LineAinimation/LineAnimation";

import { useContext } from "react";
import { Context } from "../../utils/context";
import { Link } from "react-router-dom";


const CaseStudyContent = () => {

  const { getData, imageUrl } = useContext(Context);
  const [getcaseData, setCasedata] = useState();

  var HtmlToReactParser = require('html-to-react').Parser;
  var htmlToReactParser = new HtmlToReactParser();

  const getcase = async () => {

    const res = await getData(`/casetitle`);

    if (res?.success) {
      console.log(res.data.lists);
      setCasedata(res.data.lists);
    } else {

    }
  }


  useEffect(() => {
    window.scrollTo(0, 0);
    getcase();
  }, []);


  return (
    <>
      <section className="CaseStudy">

        <div className="container">

          <LineAnimation />

          <div className="main-navbar">
            <ul
              class="nav nav-pills mb-3"
              id="pills-tab"
              role="tablist"
            >   {getcaseData?.map((data, index) => (
              <li class="nav-item" role="presentation">
                <button
                  class={index === 0 ? "nav-link active" : "nav-link"}
                  id="pills-home-tab"
                  data-bs-toggle="pill"
                  data-bs-target={`#pills-home${data?.id}`}
                  type="button"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected="true"
                >
                  {data?.title}
                </button>
              </li>
            ))}

            </ul>




            <div className="tab-content casestudytabinn" id="pills-tabContent">
              {getcaseData?.map((data, index) => (

                <div class={index === 0 ? "tab-pane fade show active" : "tab-pane fade"} id={`pills-home${data?.id}`}
                  aria-labelledby="pills-home-tab" tabindex="0"  >
                  <div className="row">
                    {data?.case2?.map((type, index) => (
                      <div className="col-md-4 mb-5">
                        <div className="Circle">
                          <div className="nutrition">
                            <img key={type?.id} src={imageUrl + type?.image} className="nutri" alt="image" />
                            {/* <img key={type?.id} src={process.env.PUBLIC_URL + '/assets/Images/scrollimage.jpeg'} className="nutri" alt="image" /> */}

                            {/* <button className="viewsitebtn">View Site</button> */}

                            <div className="overlaybtn">
                              <div class="center">
                                <a href={type?.web_link} target="_blank" rel="noopener noreferrer">
                                  <button className="btn">
                                    <svg viewBox="0 0 180 60" className="border">
                                      <polyline points="179,1 179,59 1,59 1,1 179,1" className="bg-line" />
                                      <polyline points="179,1 179,59 1,59 1,1 179,1" className="hl-line" />
                                    </svg>
                                    <span>View Site</span>
                                  </button>
                                </a>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>



          </div>



        </div>

      </section>
    </>
  );
};

export default CaseStudyContent;
