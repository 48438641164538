import React from 'react'
import { useState } from "react";
// import BlobCursor from './BlobCursor';
import './HeaderGlobal.css';
import { Link } from 'react-router-dom'
import { NavLink } from 'react-router-dom';

import logoimg from '../image/logoimg.png'
import logoimgb from '../image/icon/logoimgp.png'
import location from '../image/icon/maps-and-flags (2).png'
import callb from '../image/icon/Icon material-call.png'
import emailb from '../image/icon/Icon material-email.png'
import listimg from '../image/listimg.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../../Components/Contact-form/EnquiryForm.css'
import Enquiry_Form from '../../Components/Contact-form/Enquiry_form';

import useCursorTracker from './useCursorTracker';

// import { HoverAnimation } from '../../hover-animation/HoverAnimation';
const HeaderGlobal = () => {


    const navLinkStyles = ({ isActive }) => {
        return {
            borderBottom: isActive ? '3px solid white' : '0px',
            width: isActive ? 'fit-content' : '',
            // width: fit-content;
        }
    }




    return (
        <>
            <Enquiry_Form />

            <section className="header">

                {/* <HoverAnimation /> */}
                <div className="container-fluid p-0">
                    <div className="row ms-0 me-0">
                        <nav className="navbar navbar-expand-lg">
                            <div className="logo ms-md-5">
                                <Link to='/'>
                                    <img src={logoimg} className="img-fluid mt-2" alt="" />
                                </Link>
                            </div>

                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">


                                <FontAwesomeIcon className='toggle-bar' icon="fa-solid fa-bars" />

                            </button>



                            <div className="collapse navbar-collapse" id="navbarNav">
                                <ul className="navbar-nav nav  mb-2 mt-2 me-3">

                                    <li className="nav-item me-3 mt-lg-0 mt-1">
                                        <NavLink style={navLinkStyles} to="/" className="nav-link " aria-current="page"><span data-bs-toggle="collapse" data-bs-target="#navbarNav">Home</span></NavLink>
                                    </li>
                                    <li className="nav-item me-3 mt-lg-0 mt-1 ">
                                        <NavLink style={navLinkStyles} to="/about" className="nav-link"> <span data-bs-toggle="collapse" data-bs-target="#navbarNav">Who we are</span></NavLink>
                                    </li>
                                    <li className="nav-item me-3 mt-lg-0 mt-1 ">
                                        <NavLink style={navLinkStyles} to="/case-study" className="nav-link"><span data-bs-toggle="collapse" data-bs-target="#navbarNav"> Case Studies</span></NavLink>
                                    </li>

                                    <li className="nav-item me-3 mt-lg-0 mt-1">

                                        <button type="button" className="btn contact-us-button" data-bs-toggle="offcanvas" data-bs-target="#contact_off-canvas"
                                            aria-controls="offcanvasTop"><span data-bs-toggle="collapse" data-bs-target="#navbarNav">Contact Us</span></button>

                                    </li>

                                    <li className="nav-item me-5 mt-lg-0 mt-1">
                                        <div className="button-more">
                                            <button className="btn btn-more" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasTop" aria-controls="offcanvasTop">
                                                <img src={listimg} className="list-img" alt="" />
                                                <span className='head-more-text'>More...</span>
                                            </button>
                                        </div>
                                    </li>

                                </ul>
                            </div>
                        </nav>
                    </div>

                    <div className='popup-offcanvas'>
                        <div className="offcanvas offcanvas-top" tabindex="-1" id="offcanvasTop" aria-labelledby="offcanvasTopLabel">

                            <div className="offcanvas-body p-0">
                                <div className="container-fluid">
                                    <div className='row reverce-col-in-mob'>
                                        <div className='col-md-5 bg '>
                                            <div className='get-in-touch-text'>
                                                <h5 className='title-touch ms-lg-5 ms-1 mb-4'>Get in touch</h5>
                                                <div className='d-flex ms-lg-5 ms-1'>
                                                    <img src={location} className="logoimgblack me-3 p-2" alt="" />
                                                    <p className='popup-text-holder'>2nd floor, Office No 3, Opp high-class soc, M.no <br /> 734 Near Suryadutta College, Bavdhan Khurd, Pune 411021.</p>
                                                </div>
                                                <p className='popup-text-holder ms-lg-5 ms-1'><img src={callb} className="logoimgblack me-3 p-2" alt="" />+91 960 775 5557 | 940 570 90425</p>
                                                <p className='popup-text-holder ms-lg-5 ms-1'><img src={emailb} className="logoimgblack me-3 p-2" alt="" />info@profcymaglobal.com</p>
                                            </div>
                                        </div>

                                        <div className='col-md-7'>
                                            <div className="offcanvas-header">
                                                <h5 className="offcanvas-title" id="offcanvasTopLabel"></h5>
                                                <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                                            </div>
                                            <div className='ms-md-5 ms-1 second-text-popup'>
                                                <Link to='/' ><img type="button" data-bs-dismiss="offcanvas" aria-label="Close" src={logoimgb} className="logoimgblack1 mt-md-5 mt-0" alt="..." /></Link>
                                                <ul className=' mt-md-5 mt-0 p-0'>
                                                    <li type="button" data-bs-dismiss="offcanvas" aria-label="Close">
                                                        <Link to='/' className="popup-link ms-2">Home</Link>
                                                    </li>

                                                    <li type="button" data-bs-dismiss="offcanvas" aria-label="Close">
                                                        <Link to='/about' className="popup-link ms-2" href=" ">Who we are</Link>
                                                    </li>



                                                    <div className="btn-group dropend IT-sol-desk">
                                                        <li type="button" className="btn It-solutions popup-link dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                                                            IT Solutions
                                                        </li>



                                                        <ul className="dropdown-menu">
                                                            <div className='afterclickline'></div>
                                                            <li type="button" data-bs-dismiss="offcanvas" aria-label="Close"><Link to='/website-development' className="subpopup-link">Website Development</Link></li>

                                                            <li type="button" data-bs-dismiss="offcanvas" aria-label="Close"><Link to='/appdevlopment' className="subpopup-link">App Development</Link></li>

                                                            <li type="button" data-bs-dismiss="offcanvas" aria-label="Close"><Link to='/digital' className="subpopup-link">Digital Marketing</Link></li>

                                                            <li type="button" data-bs-dismiss="offcanvas" aria-label="Close"><Link to='/uiuxdesign' className="subpopup-link">UI/UX Design</Link></li>

                                                            <li type="button" data-bs-dismiss="offcanvas" aria-label="Close"><Link to='/software-development' className="subpopup-link" >Software Development</Link></li>

                                                            <li type="button" data-bs-dismiss="offcanvas" aria-label="Close"><Link to='/brand-awarness' className="subpopup-link" >Brand Awareness</Link></li>

                                                            <li type="button" data-bs-dismiss="offcanvas" aria-label="Close"><Link to='/graphicdesigning' className="subpopup-link" >Graphic Designing</Link></li>

                                                            <li type="button" data-bs-dismiss="offcanvas" aria-label="Close"><Link to='/e-commerce' className="subpopup-link">E-commerce Development</Link></li>

                                                        </ul>

                                                    </div>

                                                    <li className='It-sol-mob'>
                                                        <div className="accordion accordion-flush" id="accordionFlushExample">
                                                            <div className="accordion-item">
                                                                <h2 className="accordion-header">
                                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                                                        IT Solutions
                                                                    </button>
                                                                </h2>

                                                                <div id="flush-collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                                                    <div className="accordion-body">
                                                                        <ul className="">

                                                                            <li type="button" data-bs-dismiss="offcanvas" aria-label="Close"><Link to='/website-development' className="subpopup-link"><span data-bs-toggle="collapse" data-bs-target="#navbarNav">Website Development</span></Link></li>

                                                                            <li type="button" data-bs-dismiss="offcanvas" aria-label="Close"><Link to='/appdevlopment' className="subpopup-link"><span data-bs-toggle="collapse" data-bs-target="#navbarNav">App Development</span></Link></li>

                                                                            <li type="button" data-bs-dismiss="offcanvas" aria-label="Close"><Link to='/digital' className="subpopup-link"><span data-bs-toggle="collapse" data-bs-target="#navbarNav">Digital Marketing</span></Link></li>

                                                                            <li type="button" data-bs-dismiss="offcanvas" aria-label="Close"><Link to='/uiuxdesign' className="subpopup-link"><span data-bs-toggle="collapse" data-bs-target="#navbarNav">UI/UX Design</span></Link></li>

                                                                            <li type="button" data-bs-dismiss="offcanvas" aria-label="Close"><Link to='/software-development' className="subpopup-link" ><span data-bs-toggle="collapse" data-bs-target="#navbarNav">Software Development</span></Link></li>

                                                                            <li type="button" data-bs-dismiss="offcanvas" aria-label="Close"><Link to='/brand-awarness' className="subpopup-link" ><span data-bs-toggle="collapse" data-bs-target="#navbarNav">Brand Awareness</span></Link></li>

                                                                            <li type="button" data-bs-dismiss="offcanvas" aria-label="Close"><Link to='/graphicdesigning' className="subpopup-link" ><span data-bs-toggle="collapse" data-bs-target="#navbarNav">Graphic Designing</span></Link></li>

                                                                            <li type="button" data-bs-dismiss="offcanvas" aria-label="Close"><Link to='/e-commerce' className="subpopup-link"><span data-bs-toggle="collapse" data-bs-target="#navbarNav">E-commerce Development</span></Link></li>

                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>

                                                    <li type="button" data-bs-dismiss="offcanvas" aria-label="Close" >
                                                        <Link to='/blog' className="popup-link ms-2"><span data-bs-toggle="collapse" data-bs-target="#navbarNav">Blogs</span></Link>
                                                    </li>

                                                    <li type="button" data-bs-dismiss="offcanvas" aria-label="Close">
                                                        <Link to="/career" className="popup-link ms-2"><span data-bs-toggle="collapse" data-bs-target="#navbarNav">Careers</span></Link>
                                                    </li>

                                                    <li type="button" data-bs-dismiss="offcanvas" aria-label="Close">
                                                        <Link to="/case-study" className="popup-link ms-2"><span data-bs-toggle="collapse" data-bs-target="#navbarNav">Case Studies</span></Link>
                                                    </li>

                                                    <li type="button" data-bs-dismiss="offcanvas" aria-label="Close">
                                                        <Link to="/our-gallery" className="popup-link ms-2"><span data-bs-toggle="collapse" data-bs-target="#navbarNav">Gallery</span></Link>
                                                    </li>

                                                    <li type="button" data-bs-dismiss="offcanvas" aria-label="Close">
                                                        <Link type="button" data-bs-toggle="offcanvas"
                                                            data-bs-target="#contact_off-canvas"
                                                            aria-controls="offcanvasTop" className="popup-link ms-2" >Contact</Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default HeaderGlobal
