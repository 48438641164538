import React, { useEffect, useContext, useState } from "react";
import Banner from "./Banner/Banner";
import "../SoftwareDevelopment/style/Sd_style.css";
import Man_Robot from "./Animation/sd_js/Man_Robot";

import Male_investor from "./Animation/sd_js/Male_investor";
import Customer1 from "./Animation/sd_js/Customer1";
import Mask_grp from "./Images/Mask Group 52.png";
import standard_quality from "./Images/standard-quality-control-concept-m.png";
import customer_relation from "./Images/customer-relationship-management-system-modish-computer-crm-business.png";
import tab_mobile from "./Images/tab-and-mobile-original.png";
import automation_soft from "./Images/automation-software-technology-process-system-business-concept.png";
import Mask_grp_53 from "./Images/Mask Group 53.png";
import programming_background from "./Images/programming-background-collage.png";
import person_front from "./Images/person-front-computer-working-html.png";
import Improve_efficiency from "./Animation/sd_js/Improve_efficiency";
import Development_team from "./Animation/sd_js/Development_team";
import Profit_gains from "./Animation/sd_js/Profit_gains";
import twoMobiles from "./Images/2-mobiles-tinified.png";
import readymobile from "./Images/ready-to-make-changes-_tinified.png";
import finalhero from "./Images/final-hero-mob (1).png";

import Popup from "../AboutUs/Popup/Popup.js";
import { Context } from "../utils/context.js";

import { LineAnimation } from "../LineAinimation/LineAnimation";


import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// import './styles.css';

// import required modules
import { Pagination } from 'swiper/modules';
const SoftwareDevelopment = () => {
  const { getData, imageUrl } = useContext(Context);

  var HtmlToReactParser = require("html-to-react").Parser;
  var htmlToReactParser = new HtmlToReactParser();

  const [getdeveloperprocess, setDeveloperProcess] = useState();

  const getdevprocess = async () => {
    const res = await getData(`/softwaredevprocess`);
    if (res?.success) {
      setDeveloperProcess(res.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  const [getdevelopercompany, setDeveloperCompany] = useState();

  const getdevcompany = async () => {
    const res = await getData(`/softwaredevcompany`);
    if (res?.success) {
      setDeveloperCompany(res.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  const [getdeveloperbenefites, setDeveloperBenefits] = useState();

  const getdevbenefits = async () => {
    const res = await getData(`/softwaredevbenefit`);
    if (res?.success) {
      setDeveloperBenefits(res.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  const [getdevelopercrm, setDeveloperCRM] = useState();

  // const getdevcrm = async () => {
  //   const res = await getData(`/softwaredevcrm`);
  //   if (res?.success) {
  //     setDeveloperCRM(res.data);
  //   } else {
  //     console.error("Error fetching  data:", res?.error);
  //   }
  // };
  const getdevcrm = async () => {
    const res = await getData(`/softwaredevcrm`);
    if (res?.success) {
      let devcrm = [];
      let temp = [];
      for (const val of res.data) {
        if (temp.length === 2) {
          devcrm.push(temp.slice());
          temp = [];
        }
        temp.push(val);
      }
      if (temp.length > 0) {
        devcrm.push(temp);
      }
      setDeveloperCRM(devcrm);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };


  const [getdeveloperproject, setDeveloperProject] = useState();

  const getproject = async () => {
    const res = await getData(`/softwaredevproject`);
    if (res?.success) {
      setDeveloperProject(res.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  useEffect(() => {
    getdevprocess();
    getdevcompany();
    getdevbenefits();
    getdevcrm();
    getproject();
  }, []);

  return (
    <>
      <div className="container-fluid main_section_sd p-0">
        {/* section 1 */}

        <Banner />

        <LineAnimation />

        <div className="software-content ">
          <div className="row me-0 ms-0">
            {getdeveloperprocess?.map((data) => (
              <div className="col-md-4">
                <div className="img-holder">
                  <video
                    src={imageUrl + data?.video}
                    class="soft-img"
                    muted
                    autoPlay
                    loop
                    height={300}
                  />
                </div>
                <h5 class="card_title_sd text-center">{data?.title}</h5>
                <p class="card_text_sd text-center">{data?.subtitle}</p>
              </div>
            ))}

            {/* <div className="col-md-4">
              <Customer1 />
              <h5 class="card_title_sd text-center">
                INVENTORY AND <br /> ASSET MANAGEMENT
              </h5>
              <p class="card_text_sd text-center">
                MANAGE EVERYTHING IN ONE <br /> PLACE
              </p>
            </div>
            <div className="col-md-4">
              <Male_investor />
              <h5 class="card_title_sd text-center">
                CUSTOM SOFTWARE <br /> DEVELOPMENT
              </h5>
              <p class="card_text_sd text-center">
                CUSTOMIZE DESIGN AS PER YOUR <br /> REQUIREMENT
              </p>
            </div> */}
          </div>
        </div>

        {/* section 2 */}

        <div className="">
          <div className="sd_section_2_heading">
            Custom Software Development <br />
            Company In Pune
          </div>

          <div className="row me-0 ms-0 sd_section_2_lower_sec">
            {getdevelopercompany?.map((data, index) => (
              <div
                key={index}
                className="row ms-0 me-0 mt-lg-5 mt-md-0 mt-0  p-0 "
              >
                {index % 2 === 0 ? (
                  <>
                    <div className=" col-lg-6  ">
                      <div className="sd_section_2_left">
                        <p className="sd_section_2_leftSide_heading">
                          {data?.title}
                        </p>
                        <p className="sd_section_2_leftSide_content">
                          {htmlToReactParser.parse(data?.description)}
                        </p>
                      </div>
                    </div>
                    <div className=" col-lg-5  p-lg-0 ">

                      <img
                        src={imageUrl + data?.image}
                        alt="Mask_img"
                        className="sd_img_1"
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div className=" col-lg-5 order-lg-0 order-md-2 order-2 p-sm-0 ">
                      <img
                        src={imageUrl + data?.image}
                        alt="Mask_img"
                        className="sd_img_2"
                      />
                    </div>
                    <div className=" col-lg-6 order-lg-0  order-md-1 order-1">
                      <div className="sd_section_2_left">
                        <p className="sd_section_2_leftSide_heading">
                          {data?.title}
                        </p>
                        <p className="sd_section_2_leftSide_content">
                          {htmlToReactParser.parse(data?.description)}
                        </p>
                      </div>
                    </div>
                  </>
                )}
              </div>
            ))}
          </div>
        </div>

        {/* section 3 */}

        {/* <div className="">

          <div className="row sd_section_3_lower_sec">

            <div className="col-md-6 ">
              <img src={standard_quality} alt="Mask_img" className="sd_img_2" />

            </div>
            <div className="col-md-6 sd_section_2_left">
              <div >
                <p className="sd_section_3_leftSide_heading">What Is Custom <br />Development? </p>
                <p className="sd_section_3_leftSide_content">Software development is the process of designing a set of computer<br /> programs by a programmer using one or more than one<br /> programming language. This process involved in making the final<br /> product is known as SDLC (software development life cycle) which <br />satisfies user requirements.<br /><br /> The SDLC is used to build and improve their computer programs.<br /><br /> The development teams follow the defined structure for the design, <br />creation, and maintenance of high-quality software. The aim of the IT <br />software development process is to build effective products within the <br />client’s budget.

                </p>
              </div>
            </div>
          </div>
        </div>

        {/* section 4 */}

        {/* <div className="">

          <div className="row sd_section_4_lower_sec">
            <div className="col-md-6 sd_section_2_left">
              <div >
                <p className="sd_section_4_leftSide_heading">Custom Software Development</p>
                <p className="sd_section_4_leftSide_content">Custom software development is the process of designing, creating,<br /> delivering, and maintaining software for a specific set of users, specific <br />functions, set of needs, for organizations. Custom software is designed<br /> for a required specific set of needs.<br /> Custom software development aims mainly at a narrowly defined set of requirements.<br /><br /> It targets a large bunch of requirements according to the client,<br /> allowing them to be packaged and commercially marketed, and <br />distributed.<br /><br /> Our custom software development work is usually done by our in-house development team.</p>
              </div>
            </div>
            <div className="col-md-6">
              <img src={programming_background} alt="Mask_img" className="sd_img_3" />

            </div>
          </div>
        </div> */}

        {/* section 5 */}

        {/* <div className="">

          <div className="row sd_section_5_lower_sec">

            <div className="col-md-6">
              <img src={Mask_grp} alt="Mask_img" className="sd_img_4" />

            </div>
            <div className="col-md-6 sd_section_2_left">
              <div >
                <p className="sd_section_5_leftSide_heading">Keys To Effective Custom <br /> Software Development</p>
                <p className="sd_section_5_leftSide_content">
                  <ul className="sd_custom_list">
                    <li>Automate or support unique business processes</li>
                    <li>Handle industry-specific information and data securely</li>
                    <li>Facilitate integration with legacy applications and data.</li>
                    <li>Help consolidate existing solutions to achieve greater productivity at a lower cost.</li>
                    <li>Enhance competitive advantage by enabling new opportunities</li>
                    <li>Adaptive to the changing needs and growth.</li>
                  </ul>
                </p>
              </div>
            </div>
          </div>
        </div> */}

        {/* section 6 */}
        <div className="container">
          <div className="row sd_section_6_lower_sec me-0 ms-0">
            <div class="col-lg-1 col-md-1 col-sm-1 mt-4">
              <div class="yellow-border mt-5"></div>
            </div>
            <div class="col-lg-9 col-md-9 col-sm-10">
              <div className="text-center title-ideas">
                <p className="sd_subtitle mt-lg-5  mt-md-5 mt-3">
                  Some Benefits Of Custom Software <br />
                  Development Include
                </p>
              </div>
            </div>
            <div class="col-lg-1 col-md-1 col-sm-1 mt-lg-4  mt-md-4 mt-0">
              <div class="yellow-border mt-lg-5  mt-md-5 mt-0"></div>
            </div>

            {/* {getdeveloperbenefites?.map((data) => (
              <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 mt-5 improve_eff'>
                <div className="card">

                  <video src={imageUrl + data?.video} height={300} muted autoPlay loop></video>
                  <div className="card-body text-center">
                    <h5 className="card-title sd-card-title">{data?.title}</h5>
                    <a href="#" class="btn">Read More</a>
                  </div>
                </div>
              </div>
            ))} */}

            {getdeveloperbenefites?.map((data) => (
              <div className="col-lg-4 col-md-6 col-sm-6 mt-3">
                <div className="commerce">
                  <div class="accordion" id="accordionPanelsStayOpenExample">
                    <div class="accordion-item">
                      <h2
                        class="accordion-header"
                        id="panelsStayOpen-headingOne"
                      >
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#panelsStayOpen-collapseOne${data?.id}`}
                          aria-expanded="false"
                          aria-controls="panelsStayOpen-collapseOne"
                        >
                          <div className="service-vdo">
                            <video
                              className="video-sites"
                              src={imageUrl + data?.video}
                              height={300}
                              muted
                              autoPlay
                              loop
                            ></video>
                          </div>

                          <div className=" service-heading">
                            <h5 className="heading-E text-center">
                              {data?.title}
                            </h5>
                          </div>
                        </button>
                      </h2>
                      <div
                        id={`panelsStayOpen-collapseOne${data?.id}`}
                        class="accordion-collapse collapse"
                        aria-labelledby="panelsStayOpen-headingOne"
                      >
                        <div class="accordion-body">
                          {htmlToReactParser.parse(data?.description)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
            {/* <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 mt-5 development'>
              <div className="card">
                <Development_team />
                <div class="card-body text-center">
                  <h5 class="card-title ideas-card-title">Scalabilility</h5>
                  <a href="#" className="btn">Read More</a>
                </div>
              </div>
            </div>
            <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 mt-5 profit'>
              <div class="card">
                <Profit_gains />
                <div className="card-body text-center">
                  <h5 className="card-title">Profitablity</h5>
                  <a href="#" className="btn">Read More</a>
                </div>
              </div>
            </div> */}
          </div>
        </div>

        {/* section 7 */}
        <div className="container">
          <div className="sd_section_7_main">
            <div className="sd_section_7_heading">
              The SDLC Is Used To Build And Improve <br />
              Their Computer Programs
            </div>
            <div className="sd_section_7_content mt-4 mb-5">
              {" "}
              Profcyma’s CRM is a user-friendly software that is used for
              efficiently maintaining, updating, and managing the database of a
              company. It helps in the analysis of productivity of all
              employees and facilitates easy customer interaction so that a
              company can
              accurately manage and maintain its customers easily.
            </div>
          </div>
        </div>
        <div className="sd_section_7_lower">
          <img src={Mask_grp_53} alt="MaskImage" className="Mask53Image" />
          <div className=" sd_section7_upper">
            <div className="container">
              <Swiper pagination={true} modules={[Pagination]}   spaceBetween={30} className="mySwiper">
                {getdevelopercrm?.map((item, slideIndex) => (
                  <SwiperSlide key={slideIndex}>
                    <div className="sd_section_7_lower_sec">
                      <div className="row me-0 ms-0 justify-content-between CRM">
                        {item?.map((data, index) => (
                          <div className="row col-12" key={index}>
                            {index % 2 === 0 ? (
                              <>
                                <div className="col-md-7 col-sm-6 col-12 ">
                                  <div>
                                    <p className="sd_section_7_leftSide_heading">
                                      {data?.title}
                                    </p>
                                    <div className="sd_section_7_leftSide_content">
                                      <p className="sd_custom_list_I">
                                        {htmlToReactParser.parse(data?.description)}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-5 col-sm-6 col-12">
                                  <div className="crm-img-holder my-md-0 my-sm-4 my-4">
                                    <img
                                      src={imageUrl + data?.image}
                                      alt={`Image ${index}`}
                                      className="sd_section_7_img_2 upper-image"
                                    />
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="col-md-5 col-sm-6 col-12 mt-5">
                                  <div className="crm-img-holder">
                                    <img
                                      src={imageUrl + data?.image}
                                      alt={`Image ${index}`}
                                      className="sd_section_7_img_2 lower-image"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-7 col-sm-6 col-12 mt-5 sd_section_7_left">
                                  <div className="software">
                                    <p className="sd_section_7_leftSide_heading">
                                      {data?.title}
                                    </p>
                                    <div className="sd_section_7_leftSide_content">
                                      <p className="sd_custom_list_I">
                                        {htmlToReactParser.parse(data?.description)}
                                      </p>

                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>

              {/* lower section */}

            </div>
          </div>
        </div>

        {/* section 8 */}

        <div className="container">
          <div class="row">
            <div class="col-lg-1 col-md-1 col-sm-1 mt-4">
              <div class="yellow-border mt-5"></div>
            </div>
            <div class="col-lg-9 col-md-9 col-sm-10">
              <h1 class="web mt-lg-5  mt-md-5 mt-3 text-center"> Our Software Development <br />
                projects</h1>
            </div>
            <div class="col-lg-1 col-md-1 col-sm-1 mt-lg-4  mt-md-4 mt-0">
              <div class="yellow-border mt-lg-5  mt-md-5 mt-3"></div>
            </div>
          </div>
          <div className="row me-0 ms-0">
            {/* <div className="col-12 text-center">
              <div className="sd_section_8_heading">
                Our Software Development <br />
                projects
              </div>
            </div> */}

            {getdeveloperproject?.map((data, index) => (
              <div
                key={index}
                className={index === 0 ? "col-12 text-center" : "col-xxl-4 col-xl-4 col-lg-4 col-12"}
              >
                <div className="image_container">
                  <img
                    src={imageUrl + data?.backimage}
                    alt=""
                    className={
                      index === 0 ? "tab_mobile_image" : "section_8_img_i"
                    }
                  />
                </div>

                <div
                  className={
                    index === 0
                      ? "sd_section_8_heading2"
                      : "section_8_bottom_title"
                  }
                >
                  {data?.title}
                </div>
              </div>
            ))}
          </div>
        </div>


        <Popup />
      </div>
    </>
  );
};

export default SoftwareDevelopment;
