import React from 'react'
import './healthfirsttab.css';
import tabimg1 from '../image/Nutrition.png';
import tabimg2 from '../image/Fizo.png'
import tabimg3 from '../image/Dudhbhate Netralay.png'
import tabimg4 from '../image/LMS-Home.png'
import tabimg5 from '../image/Audio Meet.png'
import userimg from '../image/userimg.png'
import commaimg from '../image/comma.png'

const HealthFirstTab = () => {
    return (
        <>
            <section className="first-tab-health">
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12 mt-5'>
                            <ul class="nav nav-tabs justify-content-center" id="myTab" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true">Health & Fitness</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false">Educational</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact-tab-pane" type="button" role="tab" aria-controls="contact-tab-pane" aria-selected="false">Hospitality</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="disabled-tab" data-bs-toggle="tab" data-bs-target="#disabled-tab-pane" type="button" role="tab" aria-controls="disabled-tab-pane" aria-selected="false" >Astrology</button>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <button class="nav-link" id="retail-tab" data-bs-toggle="tab" data-bs-target="#retail-tab-pane" type="button" role="tab" aria-controls="Retail-tab-pane" aria-selected="false" >Retail</button>
                                </li>
                            </ul>
                            <div class="tab-content" id="myTabContent">
                                <div class="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab" tabindex="0">
                                    <div className='main-health'>
                                        <div className='row'>
                                            <div className='col-md-5' >
                                                <div className=''>
                                                    <img src={tabimg1} className="tabimg1 mt-3" alt="..." />
                                                </div>
                                            </div>
                                            <div className='col-md-7' >
                                                <div className='text-holder-health'>
                                                    <h2>Nutrition</h2>
                                                    <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam </p>
                                                </div>
                                                <div className='mt-5'>
                                                    <button class="btn btn-talk-to-us" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasTop2" aria-controls="offcanvasTop2">Talk to our experts</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='main-health'>
                                        <div className='row'>
                                            <div className='col-md-7' >
                                                <div className='text-holder-health'>
                                                    <h2 className=''>Fizo</h2>
                                                    <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam </p>
                                                    <p>est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam</p>
                                                </div>
                                                <div className='mt-5'>
                                                    <button class="btn btn-talk-to-us" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasTop2" aria-controls="offcanvasTop2">Talk to our experts</button>
                                                </div>
                                            </div>
                                            <div className='col-md-5' >
                                                <div className=''>
                                                    <img src={tabimg2} className="tabimg1 mt-3" alt="..." />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='main-health'>
                                        <div className='row'>
                                            <div className='col-md-5' >
                                                <div className=''>
                                                    <img src={tabimg3} className="tabimg1 mt-3" alt="..." />
                                                </div>
                                            </div>
                                            <div className='col-md-7' >
                                                <div className='text-holder-health'>
                                                    <h2 className=''>Nutrition</h2>
                                                    <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam </p>
                                                </div>
                                                <div className='mt-5'>
                                                    <button class="btn btn-talk-to-us" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasTop2" aria-controls="offcanvasTop2">Talk to our experts</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab" tabindex="0">
                                    <div className='main-health'>
                                        <div className='row'>
                                            <div className='col-md-5' >
                                                <div className=''>
                                                    <img src={tabimg4} className="tabimg1 mt-3" alt="..." />
                                                </div>
                                            </div>
                                            <div className='col-md-7' >
                                                <div className='text-holder-health'>
                                                    <h2>LMS</h2>
                                                    <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam </p>
                                                </div>
                                                <div className='mt-5'>
                                                    <button class="btn btn-talk-to-us" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasTop2" aria-controls="offcanvasTop2">Talk to our experts</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='main-health'>
                                        <div className='row'>
                                            <div className='col-md-7' >
                                                <div className='text-holder-health'>
                                                    <h2 className=''>Audio Meet</h2>
                                                    <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam </p>
                                                    <p>est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam</p>
                                                </div>
                                                <div className='mt-5'>
                                                    <button class="btn btn-talk-to-us" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasTop2" aria-controls="offcanvasTop2">Talk to our experts</button>
                                                </div>
                                            </div>
                                            <div className='col-md-5' >
                                                <div className=''>
                                                    <img src={tabimg5} className="tabimg1 mt-3" alt="..." />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='main-health'>
                                        <div className='row'>
                                            <div className='col-md-5' >
                                                <div className=''>
                                                    <img src={tabimg3} className="tabimg1 mt-3" alt="..." />
                                                </div>
                                            </div>
                                            <div className='col-md-7' >
                                                <div className='text-holder-health'>
                                                    <h2>Dudhbhate Netralay</h2>
                                                    <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam </p>
                                                </div>
                                                <div className='mt-5'>
                                                    <button class="btn btn-talk-to-us" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasTop2" aria-controls="offcanvasTop2">Talk to our experts</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="tab-pane fade" id="contact-tab-pane" role="tabpanel" aria-labelledby="contact-tab" tabindex="0">...</div>
                                <div class="tab-pane fade" id="disabled-tab-pane" role="tabpanel" aria-labelledby="disabled-tab" tabindex="0">...</div>
                                <div class="tab-pane fade" id="retail-tab-pane" role="tabpanel" aria-labelledby="retail-tab" tabindex="0">...</div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Talk to our experts  popup */}
                <div class="offcanvas offcanvas-top" tabindex="-1" id="offcanvasTop2" aria-labelledby="offcanvasTopLabel">
                    <div class="offcanvas-header">
                        <h5 class="offcanvas-title" id="offcanvasTopLabel"></h5>
                        <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    </div>
                    <div class="offcanvas-body">
                        <div className='row'>
                            <div className='col-md-5 bgexpert'>
                                <div>
                                    <img src={commaimg} className="commaimg mt-3" alt="..." />
                                </div>
                                <div className='popuserimg'>
                                    <img src={userimg} className="userimg mt-3" alt="..." />
                                </div>
                            </div>
                            <div className='col-md-7'>
                                <div className='container p-5'>
                                    <div className='text-holder-pop3'>
                                        <h2>Talk to an expert</h2>
                                        <p>We need a few details from you to put you in touch with the right person.</p>
                                    </div>
                                    <form className="row g-3 mt-4">
                                        <div className="col-md-6">
                                            <label for="inputEmail4" className="form-label">First name*</label>
                                            <input type="text" className="form-control" id="inputEmail4" />
                                        </div>
                                        <div className="col-md-6">
                                            <label for="inputPassword4" className="form-label">Last name*</label>
                                            <input type="text" className="form-control" id="inputPassword4" />
                                        </div>
                                        <div className="col-12">
                                            <label for="inputAddress" className="form-label">Email*</label>
                                            <input type="email" className="form-control" id="inputAddress" />
                                        </div>
                                        <div class="col-md-12">
                                            <label for="inputState" class="form-label">Industry</label>
                                            <select id="inputState" class="form-select">
                                                <option selected></option>
                                                <option>...</option>
                                            </select>
                                        </div>
                                        <div className="col-12">
                                            <label for="inputAddress" className="form-label">How can we help you?*</label>
                                            <input type="text" className="form-control" id="inputAddress" />
                                        </div>
                                        <div className="col-12 mt-5">
                                            <button type="submit" className="btn send-button ">Send</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default HealthFirstTab