// import React from 'react'
import React, { useEffect } from 'react';
import UiBanner from './ui-ux-banner/UiBanner'
import UiCustomer from './ui-customer/UiCustomer'
import DesigningCompany from './designing-company/DesigningCompany'
import BenefitsUi from './benefits-ui-ux/BenefitsUi'
import Development from './development-ui/Development'
import CreativeDesign from './creative-design/CreativeDesign'
import Marketing from './marketing-project/Marketing'
import Popup from '../AboutUs/Popup/Popup.js'

const UiuxDesign = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <UiBanner  />
      <UiCustomer />
      <DesigningCompany />
      <BenefitsUi />
      <Development />
      <CreativeDesign />
      <Marketing />
      <Popup />
    </>
  )
}

export default UiuxDesign