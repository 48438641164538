import React from 'react';
import './LineAnimation.css';
export const LineAnimation = () => {
  return (
    <>
        {/* <section className='animated-lines-sec'>
            <div className="line-amimation">
            <div class="animation-main">
              <div class="mark-main">
                <hr></hr>
                <marquee class="mark-scroll">__________</marquee>
              </div>

              <div class="mark-main2">
                <hr></hr>
                <marquee class="mark-scroll2">__________</marquee>
              </div>

              <div class="mark-main3">
                <hr></hr>
                <marquee class="mark-scroll3">__________</marquee>
              </div>
            </div>
            </div>
        </section> */}
    </>
  )
}
