import React, { useEffect } from 'react';
import "./Terms.css";
import { Link } from 'react-router-dom';

const Terms = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return (
        <>
            <section className='terms-section'>
                <div className='container'>
                    <div className='content-sec'>
                    <h2 className='text-center'>Terms & Condition</h2>
                        <h6>Terms of Use:</h6>
                        <p>For the purpose of the Terms of Use (“Terms”) wherever the context may require, the terminology “We”/”Us”/”Our”/”Company” individually and collectively refer to as “Profcyma” and the terms “Visitor” User” “You” refer to the users on any natural/ legal person who has agreed to avail the Website products/services.
                            “Services” shall include any Profcyma services offering certification courses of top universities, full-time degree courses in collaboration with the university, corporate workshops.</p>

                        <p>These are the Terms which you may visit or access on <Link to="https://Profcyma.com">https://Profcyma.com</Link> (“Website”). Please read the following Terms carefully as updated on the Website from time to time. In case you wish to visit our Website, the Terms shall govern you for such visits regulating the relationship between Parties. In case you do not agree to the Terms stated here, we would request you to exit this Website. Profcyma reserve the respective rights to revise these Terms and Conditions at any time by updating this posting from time to time. You agree that they are binding obligations on all users of this Website. You agree that they are binding obligations on all users of this Website. Hence, We request You to kindly visit this page periodically to familiarize and update yourself with the Terms from time to time.</p>

                        <h6>About Profcyma:</h6>
                        <p>About Profcyma:

                            At Profcyma, we take pride in being a dedicated skill-building and education consulting firm that aims to provide excellence in the field of distance education as well as regular education. Right since the inception of our company in 2016, we have single-mindedly focused on meeting the dynamic industry demands of creating highly educated, well-trained, and skilled professionals, at all times. We deliver various choices in management and executive programs from the topmost universities and institutes. We aim to train aspiring students about different Undergraduate & Postgraduate courses, and short-term courses as well. Our team consists of young enthusiasts guided by industry veterans & intellectuals. This helps us deliver to you the best there is in education.</p>

                        <p>Educational Partners and Accreditation</p>
                        <p>Profcyma is NOT a university, but an education service provider, offering Programs in consultation with Indian and International universities, educational institutions, governmental authorities, NGOs, corporates, etc. (each an “Educational Partner”).</p>

                        <p>Admission to the degree/diploma programs of an Educational Partner is separate from being accepted into and/or taking one or more Programs. You agree and acknowledge that nothing in these Terms or otherwise with respect to the use of the Platform or any Program</p>

                        <p> (a) enrolls or registers you in an Educational Partner, or any other school, division, institute or program of or associated with an Educational Partner, and</p>

                        <p>(b) allows you to access or use the resources or receive any benefits or privileges of any Educational Partner, beyond the Programs.</p>

                        <p>may offer a credential or other acknowledgment for participants who have satisfactorily demonstrated mastery of the Program material. The decision to award any such credential acknowledgment to a given participant will be solely at the discretion of.  may choose not to offer any credential or other acknowledgment for some Programs.  may decide at its sole discretion whether to provide a record concerning a participant’s performance in a Program. The format of any credential or other acknowledgment, and of any performance, provided by relating to Programs will be determined at its sole discretion and may vary from Program to Program.</p>

                        <p>You will not receive academic credit from or any other Educational Partner for taking a Program. If you are taking a Program for academic credit or certification as a student of, or otherwise through, an Educational Partner, any such credit or certification may only be awarded directly by the Educational Partner based on its own policies and procedures, and you may be required by that Educational Partner to be registered or enrolled with the Educational Partner in order to receive credit or certification; and, in any event,  will not have any authority or responsibility with respect to any award of academic credit or certification provided by an Educational Partner for a Program.</p>

                        <p>You acknowledge and agree that any Program affiliated with an Educational Partner may be subject to the terms, policies, and procedures of the applicable Educational Partner in addition to  Terms. Without limiting the foregoing, if you are a student registered or enrolled at, or are otherwise attending, an Educational Partner and are taking a Program for credit or certification through that Educational Partner, you acknowledge and agree that-</p>

                        <p>(a) the Educational Partner may have its own terms, policies, or procedures regarding your eligibility to participate in the Program, your participation in the Program, the requirements or prerequisites for receiving credit or certification for the Program, and/or your educational or student records as they may relate to your participation and performance in the Program, and</p>

                        <p>(b) your educational or student records are maintained by the Educational Partner, including for purposes of assigning credit or certification, and not.</p>

                        <p>Payment Terms and Refund Policy</p>

                        <p>To make payment for any Program or to purchase any services or products offered by Profcyma through the Platform, you must have internet access and a current valid accepted payment method as indicated during sign-up (“Payment Method”). Profcyma does not store any of your credit card information or such other information restricted by the Reserve Bank of India (RBI) for processing payment and has partnered with payment gateways for the payment towards the services. By using a third-party payment provider, you agree to abide by the terms of such a payment provider. You agree that in case Profcyma’s third-party payment provider stores any such information, Profcyma will not be responsible for such storage, and it will be solely at your discretion to allow the third party to store such information. Any loss of such information or any loss incurred by you due to the usage of such information will be solely a loss incurred by you, and Profcyma is in no way liable for any such losses and is neither responsible to reimburse/making good such losses in any manner whatsoever. You also agree to pay the applicable fees for the payments made through the Platform.</p>

                        <p>Failure to pay the applicable Program fee may result in withdrawal of your access to a Program. Depending on where you transact with us, the type of payment method used, and where your payment was issued, your transaction with us may be subject to foreign exchange fees or exchange rates. Profcyma does not support all payment methods, currencies, or locations for payment. All applicable taxes are calculated based on the billing information you provide to us at the time of enrolment/purchase.</p>

                        <p>You may cancel your enrolment at any time. Each Program has specific refund terms which shall be applicable in case of refund on account of such cancellation requests.</p>

                        <p>If you have subscribed for a free trial, the free trial period will last for the period of time specified during your sign-up. Free trials may not be combined with certain other offers. If you have subscribed to a particular paid service or product within the last six (6) months,</p>

                        <p>USE OF CONTENT ON THE WEBSITE</p>

                        <p>All logos, marks, brands, headings, labels, names, signatures, numerals, shapes, illustrations, or any combinations thereof, appearing in this Website and its Products/ Services, except as otherwise noted, are properties exclusively owned by Profcyma. You agree that the use of the exclusive Profcyma properties or any other content on this Website, except as provided in these Terms, is strictly prohibited.</p>

                        <p>You agree that the content of this Website cannot be modified or reproduced, display, distributed, or otherwise used in any way for any public or commercial purpose without our written consent.</p>

                        <p>ACCEPTABLE WEBSITE USE</p>

                        <p>(A) Security Rules</p>

                        <p>We will protect your data/ information per our Privacy Policy. You are forbidden from violating or attempting to breach the security of the Website, including, without limitation:</p>

                        <ol>
                            <li>You should not access data that is not intended for You or log into the server or account without authorization.</li>
                            <li>You must restrict yourself from attempting to probe, scan or test the vulnerability of the Company’s system or network as this can lead to a breach of security or authentication measures.</li>
                            <li>You must not attempt to interfere and interact with Website Services provided to any other user, host, or network, including, without limitation, via means of submitting a virus or “Trojan horse” to the Website, overloading, “flooding”, “mailbombing” or “crashing”.</li>

                            <li>You should send unsolicited electronic mail, including promotions and/or advertising of products or services.</li>

                            <li>You agree that the violations of system or network security of our Website may result in civil or criminal liability.</li>

                            <li>If We have a reason to believe about a likely breach of security or misuse of the Website. In that case, We will have the right to investigate occurrences that we have suspected involving such violations. We will have the right to involve, and cooperate with, law enforcement authorities in prosecuting you who may or may not involve in such violations.</li>


                        </ol>


                        <p>GENERAL RULES:</p>
                        <p>You agree that the information provided on the Website is true, accurate, complete, and updated information on the Website as and when required. You may not use the Website to transmit, distribute, store or destroy the material or any data/ information.</p>

                        <p> (a) That could constitute or promote conduct that would be considered a criminal offense or violate any applicable law or regulation enforced by the government.</p>

                        <p> (b) in a manner that will infringe the copyright, trademark, trade secret, or other intellectual property rights of others or violate the privacy or publicity of other personal rights of others, or (c) transmitting information or data on the Website that is libelous, defamatory, pornographic, profane, obscene, threatening, abusive or hateful.</p>

                        <p>INTELLECTUAL PROPERTY RIGHTS</p>

                        <p>Unless otherwise indicated or anything contained to the contrary or any proprietary material owned by a third party and so expressly mentioned, Profcyma owns all Intellectual Property Rights to and into the Website, including, without limitation, any and all rights, title, and interest in and to copyright, related rights, patents, utility models, trademarks, trade names, service marks, designs, know-how, trade secrets and inventions (whether patentable or not ), goodwill, source code, meta tags, databases, text, content, graphics, icons, and hyperlinks. You acknowledge and agree that you shall not use, reproduce or distribute any content from the Website belonging to Profcyma without obtaining authorization from it.</p>

                        <p>You shall not upload post or otherwise make available on the Website any material protected by copyright, trademark, or other proprietary rights without the express permission of the owner of the copyright, trademark, or other proprietary rights. Profcyma does not have any express burden or responsibility to provide the Users with indications, markings, or anything else that may aid You in determining whether the material in question is copyrighted or trademarked. You shall be solely liable for any damage resulting from any infringement of copyrights, trademarks, proprietary rights, or any other harm resulting from such a submission.</p>

                        <p>TRADEMARKS</p>

                        <p>The Website contains copyrighted material, trademarks, and other proprietary information, including, but not limited to, text, software, photos, video, graphics, music, sound, and the entire contents of Profcyma protected by copyright as a collective work under the applicable copyright laws. Profcyma confirms that We own a copyright in the selection, coordination, arrangement, and enhancement of such content, as well as in the content original to it. You may not modify, publish, transmit, participate in the transfer or sale, create derivative works, or in any way exploit, any of the content, in whole or in part. Users may download / print / save copyrighted material for Their personal use only. Except as otherwise expressly stated under copyright law, no copying, redistribution, retransmission, publication, or commercial exploitation of downloaded material without the express permission of Profcyma and the copyright owner is permitted.</p>

                        <p>We assure you that if copying, redistribution, or publication of copyrighted material is permitted, no changes in or deletion of author attribution, trademark legend, or copyright notice shall be made while publishing on the Website. You acknowledge that he/she/it does not acquire any ownership rights by downloading copyrighted material. Trademarks located within or on the Website or third party website which are otherwise owned or operated in conjunction with Profcyma, shall not be deemed to be in the public domain but rather the exclusive property of Profcyma. In such case, the website is under license from the trademark owner thereof such license is for the exclusive benefit and use of Profcyma unless otherwise stated.</p>

                        <p>INTELLECTUAL PROPERTY RIGHTS COMPLAINTS</p>

                        <p>We respect the intellectual property of others and in case you believe that any copied work has been published on the Website has been copied in any manner that constitutes any violation of Intellectual Property Right, please write to us at Profcyma101@gmail.com by way of an email.</p>

                        <p>INDEMNITY</p>

                        <p>You unilaterally agree to defend, indemnify and hold harmless, without objection Profcyma, its officers, directors, employees, successors, permitted assigns, heirs, and agents from and against any costs, claims, actions, liabilities, demands, losses, and/or damages whatsoever arising directly from or resulting from their use of our Website or their breach of the Terms. This clause shall survive the termination of these Terms.z</p>

                        <p> TERMINATION</p>

                        <p>The Terms is effective unless and until terminated by either you or Profcyma. You may terminate this Terms at any time, provided that you discontinue any further use of this Website. We reserve the right to terminate the Terms at any time and may do so immediately without written notice and accordingly and accordingly deny you access to the Website, such termination will be without any liability to Profcyma or its affiliates, directors, officers, or employee. Upon termination, you must promptly destroy all materials downloaded or otherwise obtained from this Website whether written or oral, as well as all copies of such materials, whether made under the Terms or otherwise. Profcyma right to any Comments shall survive any termination of this Terms. Any such termination of the Terms shall not cancel your obligation to pay or our obligation to provide the Website services/ products already availed/ ordered from the Website or affect any liability that may have arisen under the Terms.</p>

                        <p>LIMITATION OF LIABILITY</p>

                        <p>You agree that neither Profcyma nor its affiliates, directors, officers, or employee shall be liable for any direct or/and indirect or/and incidental or/and special or/and consequential or/and exemplary damages, resulting directly from the use or/and the inability to use the service or/and for the cost of procurement of substitute products or/and services or resulting from any products or/and data or/and information or/and services purchased or/and obtained or/and messages received or/and transactions entered into through or/and from the service or/and resulting from unauthorized access to or/and alteration of user’s transmissions or/and data or/and arising from any other matter relating to the service, including but not limited to, damages for loss of profits or/and use or/and data or other intangible, even if Profcyma has been advised of the possibility of such damages. The Website is provided without any representations or warranties and on an “As Is” basis without warranties whether express or implied.</p>

                        <p>Furthermore, You agree that the Company shall not be liable for any damages resulting from interruption, suspension, or termination of service, including but not limited to direct or/and indirect or/and incidental or/and special consequential or/and exemplary damages, whether such interruption or/and suspension or/and termination was justified or not, negligent or intentional, inadvertent or advertent.</p>

                        <p>You accept that We shall not be responsible or liable to You, or anyone, for the statements or conduct of any third party service. In sum, in no event shall Profcyma total liability to You for overall damages or/and losses or/and causes of action exceed the amount paid by you to us, if any, that is related to the cause of action.</p>

                        <p>DISCLAIMER OF CONSEQUENTIAL DAMAGES</p>

                        <p>In no event shall Company or any parties, organizations or entities associated with the corporate brand name us or otherwise, mentioned at this Website be liable for any damages whatsoever (including, without limitations, incidental and consequential damages, lost profits, or damage to computer hardware or loss of data information or business interruption) resulting from the use or inability to use the Website and the Website Material, whether based on warranty, contract, tort, or any other legal theory, and whether or not, such organization or entities were advised of the possibility of such damages.</p>

                        <p>REVIEW, FEEDBACK, SUBMISSIONS FOR COMMUNICATION:</p>
                        <p>All developed content concerning reviews, comments, feedback, postcards, suggestions, ideas, and other submissions disclosed, submitted, or offered by students/ faculty, customers, third-party vendors to Profcyma on the Website or otherwise disclosed, submitted, or offered in connection with your access or usage of this Website (collectively, referred as “Comments”) shall be and remain the exclusive property of Profcyma. Any such disclosure, submission, or offer of any Comments shall constitute an assignment to Profcyma with all rights, titles, and interests in all copyrights and other intellectual properties in the Website Comments. Thus, We own exclusively all such rights, titles, and interests and shall not be limited in any way in its use, commercial or otherwise, of any Comments unless authorized.</p>

                        <p>GOVERNING LAW AND JURISDICTION:</p>

                        <p>These Terms shall be construed and governed under applicable laws of India. The courts at Pune, India shall have exclusive jurisdiction in any proceedings arising out of this agreement.</p>
                        <p>FORCE MAJEURE</p>

                        <p>If any delay or failure is caused by incidents or circumstances beyond Company’s control, we shall not be liable for any delay or failure to perform its obligations. This includes, but is not limited to, acts of God, war, fire, strikes, floods, political events, performing Website services due to technical failure of communications or computer systems, shortage or unavailability of Website products, or extreme weather.</p>

                        <p>In such circumstances, Profcyma shall use reasonable endeavours and methods to perform its obligations as soon as is reasonably practicable but accept no liability to compensate you in case of any loss or inconvenience arising from such delay or failures described above.</p>

                        <p>SUPERSEDE:</p>

                        <p>Except where additional terms and conditions are provided which are Website product/ service specific, these Terms supersede all previous representations, understandings, or agreements whether written or oral, and shall prevail notwithstanding any variance with any other terms of any order submitted. By using the products/services of the Website, you agree to be bound by the Terms as notified from time to time.</p>

                        <p>NOTICES:</p>

                        <p>Unless expressly stated, all notices from you must be sent in writing and sent to the registered address of our Company as mentioned under the “Contact Us” section.</p>

                        <p>INVALIDITY:</p>

                        <p>If any of these Terms, or any part of these Terms, is found unenforceable or illegal the same shall be deemed to be invalid from the rest of the Terms unless a contrary intention and the enforceability of any other of these Terms will not be affected.</p>

                        <p>ENTIRE AGREEMENT</p>

                        <p>These Terms set out the whole forms an entire agreement between You and Profcyma relating to accessing Website, its Services, and Products. You shall have no remedy or redressal in respect of any breach of representation or warranty that is not laid down in these Terms. You agree that nothing in these Terms excludes or limits our liability for fraud or fraudulent misrepresentation.</p>

                        <p>SEVERABILITY:</p>

                        <p>If any provision of these Terms appears invalid, unlawful, void, ineffective, or for any other reason unenforceable, then that provision shall be deemed severable from these Terms and shall not affect the validity and enforceability of any of the remaining provisions.</p>


                    </div>
                </div>
            </section>
        </>
    )
}

export default Terms;