import React, { useContext, useState, useEffect } from 'react'
import './Creativedesign.css';
import Concept from '../../animation/concept-lottey/Concept';
import DesignLottey from '../../animation/Design-lottey/DesignLottey';
import { Context } from '../../utils/context.js';

const CreativeDesign = () => {

    const { getData, imageUrl } = useContext(Context);
    var HtmlToReactParser = require('html-to-react').Parser;
    var htmlToReactParser = new HtmlToReactParser();

    const [getdesigntype, setDesignType] = useState();
    const getdesign = async () => {
        const res = await getData(`/uiuxdesigndesign`);
        if (res?.success) {
            setDesignType(res?.data);
        }
        else {
            console.error('Error fetching  data:', res?.error);
        }
    }

    useEffect(() => {
        getdesign();
    }, [])

    return (
        <>
            <section className='creative-design'>
                <div className='container'>
                    <div className='row justify-content-center'>
                        {getdesigntype?.map((item) => (
                            <div className=' col-lg-4 col-md-6 col-sm-12'>
                                <div className='card mb-3'>
                                    <video className='design-vdo' src={imageUrl + item?.video} autoPlay muted loop />
                                    <div class="card-body">
                                        <h5>{item?.title}</h5>
                                        <p>{htmlToReactParser.parse(item?.description)}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                        
                    </div>
                </div>
            </section>
        </>
    )
}

export default CreativeDesign