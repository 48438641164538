import React from 'react'
import './Designingcompany.css';
// import arrowimg from '../image/arrow-dropright.png';
const ModalNew = ({ modalId, modalTitle, children, modalclassName, modelImage}) => {
    return (
        <>
            <div className='development'>
                <div className="modal fade development-modal"  id={`id${modalId}`} tabIndex='-1' aria-labelledby='exampleModalLabel' aria-hidden='true' >
                    <div className='modal-dialog modal-fullscreen'>
                        <div className={`modal-content ${modalclassName}`}>
                            <div className="designing-img-holder">
                                <img
                                src={modelImage}
                                className="designing-img"
                                alt=""
                                />
                            </div>
                            <div className='modal-header'>
                                <button type='button' className='btn-close mt-4' data-bs-dismiss='modal' aria-label='Close'></button>
                            </div>
                            <div className='modal-body'>
                                <div className='container'>
                                    <div className='row'>
                                        <div className='col-lg-4 col-md-6'>
                                            <div className='content-holder'>
                                                <h4>{modalTitle}</h4>
                                                {children}                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ModalNew