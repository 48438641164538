import React from 'react'
import Crousel from './Crousel/Crousel';

const Slider = () => {
    return (
        <div>
          <Crousel/>
        </div>
    )
}

export default Slider;