import React, { useEffect, useState } from 'react';
// import successful from "./Images/successful-businesspeople-with-arms-crossed-office.png"
import afro from "./Images/afro-mixed-race-man-casual-wear-sitting-chill-zone.png"
import web_design from "./Images/web-design-website-coding-concept.png"
import marketing from "./Images/marketing-plan-strategy-calendar-planner-concept.png"
import indian from "./Images/indian-man-using-laptop-while-drinking-cup-coffee-outdoor-street-cafe.png"
import cheerful from "./Images/cheerful-business-colleagues-meeting.png"
import office from "./Images/office-business-meeting-team-is-sitting-table-luminous-white-open-space.png"
import buissness from "./Images/business-man-accounting-calculating-cost-economic-budget-investment-saving.png"
import "./style/career.css"
import CarrerBanner1 from './CarrerBanner1'

import { LineAnimation } from "../LineAinimation/LineAnimation";

import { useContext } from "react";
import { Context } from "../utils/context";

const Career = () => {


  const { getData, imageUrl } = useContext(Context);
  const [getcarrerData, setCarrerdata] = useState();

  var HtmlToReactParser = require('html-to-react').Parser;
  var htmlToReactParser = new HtmlToReactParser();

  const getcarrer = async () => {

    const res = await getData(`/carrer`);

    if (res?.success) {
      //console.log(res.data.carrer);
      setCarrerdata(res.data.carrer);
    } else {

    }
  }


  useEffect(() => {
    window.scrollTo(0, 0);
    getcarrer();
  }, []);



  return (
    <>
      <CarrerBanner1 />
      <div className='container-fluid section_2 career-main'>
        <LineAnimation />
        {/* section 1 */}
        <div className='container-fluid '>

        </div>

        <div className='col-md-12 text-center mt-4 section_2_heading'>
          <h1 className='pt-lg-0 pt-md-5  pt-5'>Nice to Meet You</h1>
        </div>


        {/* section 2 */}

        <div className='container '>

          <div className='row '>
            <div className='col-md-3 section_2_image'>
              <img src={afro} alt='marketing_image' className='img_2_career ' />
              <div className='row section_2_lowerImage'>
                <div className='  col-lg-6 col-md-12'>
                  <img src={marketing} alt='web_design' className='img_3_career' />

                </div>
                <div className='col-lg-6 col-md-12'>
                  <img src={web_design} alt='web_design' className='img_4_career' />
                </div>

              </div>
            </div>
            <div className='col-md-6 section_2_middle_content'>
              MadAbout Slots is the amount one destination for all your favourite mobile internet casino video games, mobile slot machine games and significantly considerably more. Vega77 is basically particular Online Gambling establishment inside Malaysia providing the most profound encounter inside taking part in sports activities betting, which will get crystal clear to http://shekhai.com/free-slots-on-the-internet-online-casino-lobstermania-slots-real-money-games-zero-subscription-no-deposit-for-fun/ you when you enter their web site. Our assortment of no cost video clip texas hold’em video games is one of the best around. Another ordinary for these fast developing activities are the fun sub-games, the activities on the inside the concept activities that are set the primary game.
            </div>
            <div className='col-md-3 section_2_right_sec'>
              <div className='row section_2_right_sec_lower'>
                <div className='col-lg-6 col-md-12 col-md-6 '>
                  <img src={office} alt='' className='img_5_career'></img>
                </div>
                <div className='col-lg-6 col-md-12 col-md-6'>
                  <img src={cheerful} alt='' className='img_6_career'></img>
                </div>
              </div>
              <img src={indian} alt='' className='img_7_career'></img>
            </div>
          </div>
        </div>

        <div className='container'>
          <div className='row section_2_bottom_sec'>
            <div className='col-md-4'>
              <img src={office} className='img_8_career'></img>
            </div>
            <div className='col-md-4'>
              <img src={cheerful} className='img_9_career'></img>
            </div>
            <div className='col-md-4 col-'>
              <img src={buissness} className='img_10_career'></img>
            </div>
          </div>
        </div>

        {/* section 3 */}

        <div className='row'>
          <div className='col-12 mt-5 section_3_heading'>
            <h1 className=''>Career Opportunities</h1>
          </div>
        </div>
        {/* -------------------------------------------------------- */}

        <div className="container main_section_4">
          {getcarrerData?.map((data, index) => (
            <div className="row">
              <div className="col-md-12">
                <div class="accordion accordion-flush" id="accordionFlushExample">
                  <div class="accordion-item">
                    <h2 class="accordion-header" id="flush-headingOne">
                      <button
                        class="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#flush-collapseOne${index}`}
                        aria-expanded="false"
                        aria-controls="flush-collapseOne"
                      >
                        {data.profile}
                      </button>
                    </h2>
                    <div
                      id={`flush-collapseOne${index}`}
                      class="accordion-collapse collapse"
                      aria-labelledby="flush-headingOne"
                      data-bs-parent="#accordionFlushExample"
                    >
                      <div class="accordion-body">
                        <div className="container">
                          <div className="row">
                            <div> <span className="content">Location:</span> {data?.carrerinfo?.location} </div>
                            <div> <span className="content">Positions:</span> {data?.carrerinfo?.positions}</div>
                            <div>
                              {" "}
                              <span className="content">About the job</span>
                              <p>
                                {data?.carrerinfo?.about}
                              </p>
                            </div>
                            <div>
                              {" "}
                              <p>
                                <span className="content">   Job responsibilities include:  </span>
                                {htmlToReactParser.parse(data?.carrerinfo?.description)}
                              </p>
                            </div>
                            <div><span className="content">Company Info:</span>
                              <p>

                                {htmlToReactParser.parse(data?.carrerinfo?.company_info)}

                              </p>
                            </div>
                            <div>Immediate Joiners (Max 15 days notice, candidates Having more than 15 days notice must not apply) </div>
                            <div> <span className="content">visit us  </span>{data?.carrerinfo?.url}</div>
                            <div><span className="content">for inquiry drop mail at </span> {data?.carrerinfo?.email} </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          ))}
        </div>


      </div>

    </>
  )
}

export default Career