import React, { useEffect, useState } from "react";
import '../AboutCompany/aboutcompany.css';
import aboutimg1 from '../AboutCompany/image/aboutimg1.png'
import bharatsir from '../AboutCompany/image/bharat-sir.png'
import amarsir from '../AboutCompany/image/Amar-Sir.jpg'
import guddusir from '../AboutCompany/image/guddu-Sir.png'
import swapnilsir from '../AboutCompany/image/Swapnil.png'
import disha from '../AboutCompany/image/disha.png'
import siddhimadam from '../AboutCompany/image/siddhi.png'
import sanjivani from '../AboutCompany/image/sanjivani.png'
import team1 from '../AboutCompany/image/team1.png'
import { LineAnimation } from "../../LineAinimation/LineAnimation";


import { useContext } from "react";
import { Context } from "../../utils/context";

import AOS from "aos";
import "aos/dist/aos.css";
const AboutCompany = () => {

    const { getData, imageUrl } = useContext(Context);
    const [getteamData, setTeamdata] = useState();
    const [getaboutData, setAboutdata] = useState();
    const [getfounderData, setFounderdata] = useState();

    var HtmlToReactParser = require('html-to-react').Parser;
    var htmlToReactParser = new HtmlToReactParser();

    const getTeam = async () => {

        const res = await getData(`/team`);

        if (res?.success) {

            setTeamdata(res.data);
        } else {

        }
    }

    const getAbout = async () => {

        const res = await getData(`/aboutcompany`);

        if (res?.success) {

            setAboutdata(res.data);
        } else {

        }
    }


    const getFounder = async () => {

        const res = await getData(`/founder`);

        if (res?.success) {

            setFounderdata(res.data);
        } else {

        }
    }


    useEffect(() => {
        getTeam();
        getAbout();
        getFounder();


    }, []);
    return (
        <>
            <section className='About-Company'>

                <div className='container'>


                    {getaboutData?.map((data) => (
                        <div className='row'>
                            <LineAnimation />
                            <div className='col-lg-6 col-md-6 mt-5 mb-md-5'>
                                <div className='user-img-about' data-aos="fade-right" data-aos-offset="500" data-aos-easing="ease-in-sine">
                                    <img src={imageUrl + data?.backimage} className="aboutimg1 img-fluid" alt="" />
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6 mt-md-5 mb-5'>
                                <div className='text-holder-about' data-aos="fade-left" data-aos-offset="500" data-aos-easing="ease-in-sine">
                                    <h1>{data?.title}<br></br><span className='special-font'>{data?.subtitle}</span></h1>
                                    <p>{htmlToReactParser.parse(data?.description)}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                    {getfounderData?.map((data) => (
                        <div className='row bharat-sir-div-rotate'>
                            <div className='col-lg-6 col-md-6 mt-md-5 mb-md-5 mb-0'>
                                <div className='text-holder-about' data-aos="fade-right" data-aos-offset="500" data-aos-easing="ease-in-sine">
                                    <h1>{data?.title}</h1>
                                    <h1 className='sir-name'>{data?.name}</h1>
                                    <p className='position'>{data?.position}</p>
                                    <p>{htmlToReactParser.parse(data?.description)}</p>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6 mt-md-5 mt-0 mb-md-5'>
                                <div className='user-img-about' data-aos="fade-left" data-aos-offset="500" data-aos-easing="ease-in-sine">
                                    <img src={imageUrl + data?.backimage} className="aboutimg1 img-fluid" alt="" />
                                </div>
                            </div>
                        </div>))}
                    <div className='our-team-div'>
                        <p className='small-title'>Our team</p>
                        <h1 className='big-title'>Awesome team members</h1>
                        <p className='content'>Our team of experienced technical professionals at Profcyma who are proficient in using modern technologies for different IT processes that deliver excellent and unique experiences for our clients.</p>
                        <div className='row justify-content-center' data-aos="fade-up" data-aos-duration="2000">
                            {getteamData?.map((data) => (
                                <div className='col-lg-4 col-sm-6 col-10'>
                                    <div className='team-div'>
                                        <img className='team-img' key={data?.id} src={imageUrl + data?.photo} />

                                        <p className='name'>{data?.name}</p>
                                        <p className='content'>{data?.profile}</p>
                                        <p className='content'>{data?.email}</p>
                                    </div>
                                </div>
                            ))}

                        </div>
                    </div>
                </div>
            </section >
        </>
    )
}

export default AboutCompany