import React, { useState,useEffect } from 'react';
import CountUp from 'react-countup';
import ScrollTrigger from 'react-scroll-trigger';
import '../Partner/partner.css';
// import itpartner from '../image/it-partner.mp4'
import { useContext } from "react";
import { Context } from "../../../utils/context";
import AOS from "aos";
import "aos/dist/aos.css";
import LazyLoad from 'react-lazyload';

const Partner = () => {

    const [counterOn, setCounterOn] = useState(false)
    const { getData, imageUrl } = useContext(Context);
    const  [getpartnerData, setPartnerdata]  = useState();

    var HtmlToReactParser = require('html-to-react').Parser;
    var htmlToReactParser = new HtmlToReactParser();

    const getPartner = async () => {

        const res = await getData(`/projectcom`);
    
        if(res?.success){
           
            console.log(res.data);
            setPartnerdata(res.data);
        }else{ 
    
        }
       }
    
       useEffect(() => {
        AOS.init();
        AOS.refresh();
        getPartner();
      }, []);
   
    return (
        <>
            <section className='ItPartner'>
                <div className='container'>
                {getpartnerData?.map((data) => ( 
                    <div className='row'>
                        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12'>
                            <div className='title-it'>
                                <h2>{data?.title}</h2>
                                <p> {htmlToReactParser.parse(data?.description)}<br /></p>
                            </div>
                            <div className='row'>
                                <div className='col-xxl-4 col-xl-5 col-lg-6 col-md-6 col-sm-6  col-12 text-center mt-md-4'>
                                    <div className='counter-class'>
                                        <div className='justify-content-center'>
                                            <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
                                                <h1 className='number-class'>
                                                    {counterOn && <CountUp start={0} end={data?.yrexperience} duration={5} delay={0} />}
                                                    +
                                                </h1>
                                            </ScrollTrigger>
                                        </div>
                                        <p>Years of Experiences</p>
                                    </div>
                                </div>
                                <div className='col-xxl-4 col-xl-5 col-lg-6 col-md-6  col-sm-6 col-12  text-center mt-md-4'>
                                    <div className='counter-class'>
                                        <div className='justify-content-center'>
                                            <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
                                                <h1 className='number-class'>
                                                    {counterOn && <CountUp start={0} end={data?.projectcount} duration={5} delay={0} />}
                                                    +
                                                </h1>
                                            </ScrollTrigger>
                                        </div>
                                        <p>Project Completed</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-12'>
                            
                        <LazyLoad offset={100} once>
                            <video src={imageUrl + data?.video} autoPlay="true" controls className="d-block w-100 video-class-grid" />
                        </LazyLoad>
                        </div>
                    </div>
                ))}
                </div>
            </section>

        </>
    )
}

export default Partner