import React, { useEffect, useState } from "react";

import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";

import "../Services/Services.css";
import service1 from "./image/service1.png";
import service2 from "./image/service2.png";
import service3 from "./image/service3.png";
import service4 from "./image/service4.png";
import service5 from "./image/service5.png";
import AOS from "aos";
import "aos/dist/aos.css";

import { useContext } from "react";
import { Context } from "../../utils/context";

const Services = () => {
  const { getData, imageUrl } = useContext(Context);
  const [getservicesData, setServicesdata] = useState();

  const getServices = async () => {
    const res = await getData(`/service`);

    if (res?.success) {
      setServicesdata(res.data);
    } else {
    }
  };

  useEffect(() => {
    AOS.init();
    AOS.refresh();
    getServices();
  }, []);
  return (
    <>
      <section className="Services p-0">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-5">
              <div className="services-text-holder">
                <p
                  data-aos="fade-right"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                >
                  Industries We Serve
                </p>
                <h2
                  data-aos="fade-right"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                >
                  Customized IT <span>services to fit your</span> business
                  needs
                </h2>
              </div>
            </div>
            <div className="col-md-5">
              <div className="services-text-holder2">
                <p
                  data-aos="fade-left"
                  data-aos-offset="300"
                  data-aos-easing="ease-in-sine"
                >
                  Our vertical solutions expertise can streamline workflow and
                  increase  productivity for your business. Whether your
                  business is large or small,
                  Profcyma has industry-standard solutions to fit your
                  needs.
                  
                </p>
              </div>
            </div>
            <div className="services-type">
              <div
                className="row justify-content-center me-0 ms-0"
                data-aos="fade-up"
                data-aos-duration="2000"
              >
                <Swiper
                  // install Swiper modules
                  modules={[Navigation, Pagination, Autoplay]}
                  spaceBetween={20}
                  slidesPerView={5}
                  navigation={false}
                  Pagination={false}
                  onSwiper={(swiper) => console.log(swiper)}
                  onSlideChange={() => console.log("slide change")}
                  //   autoplay={{
                  //     delay: 2500,
                  //     disableOnInteraction: false,
                  //     loop: true,
                  //   }}

                  breakpoints={{
                    280: {
                      slidesPerView: 1,
                      spaceBetween: 20,
                    },
                    // when window width is >= 320px
                    320: {
                      slidesPerView: 1,
                      spaceBetween: 20,
                    },
                    // when window width is >= 480px
                    480: {
                      slidesPerView: 1,
                      spaceBetween: 30,
                    },
                    // when window width is >= 640px
                    640: {
                      slidesPerView: 2,
                      spaceBetween: 40,
                    },

                    768: {
                      slidesPerView: 2,
                      spaceBetween: 40,
                    },

                    1024: {
                      slidesPerView: 3,
                      spaceBetween: 40,
                    },

                    1200: {
                      slidesPerView: 4,
                      spaceBetween: 40,
                    },

                    1400: {
                      slidesPerView: 5,
                      spaceBetween: 40,
                    },
                  }}
                >
                  {getservicesData?.map((data) => (
                    <SwiperSlide key={data?.id}>
                      <div
                        className="card green-card"
                        style={{ backgroundColor: data?.color_code }}
                      >
                        <img
                          key={data?.id}
                          src={imageUrl + data?.icon}
                          className="card-img-top mt-5"
                          alt="..."
                        />
                        <div className="card-body">
                          <div className="card-title text-center">
                            <h2>{data?.title}</h2>
                            <p>{data?.discription}</p>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
             
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Services;
