import React from 'react'
import Lottie from 'react-lottie';
import * as animationData from '../79109-motion-graphics.json';


function Eight() {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    return (
        <>
            <div className='fourth-brand'>
                <Lottie
                    options={defaultOptions}
                   
                />
            </div>
        </>
    )
}

export default Eight;