import React, { useContext, useState, useEffect } from "react";
import "./ECommerceContent.css";
import lap from "../images/lap.png";
import ligth from "../images/ligth.png";
import Girl from "../../animation/Girl/Girl";
import Grow from "../../animation/Grow/Grow";
import Development from "../../animation/Develop/Development";
import Fast from "../../animation/Fast/Fast";
import Online from "../../animation/Online/Online";
import Impact from "../../animation/Impact/Impact";
import Click from "../../animation/Click/Click";
import group from "../images/group.png";
import animationData1 from "../../animation/Fast/fast.json";
import animationData2 from "../../animation/Toggle/toggle.json";
import animationData3 from "../../animation/Success/success.json";
import { Pagination, Autoplay } from "swiper/modules";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import { LineAnimation } from "../../LineAinimation/LineAnimation";
import { Context } from "../../utils/context.js";

const ECommerceContent = () => {
  const { getData, imageUrl } = useContext(Context);
  var HtmlToReactParser = require("html-to-react").Parser;
  var htmlToReactParser = new HtmlToReactParser();

  const [getecomercecompany, setEcomerceCompany] = useState();
  const getcompany = async () => {
    const res = await getData(`/ecommercedevcompany`);
    if (res?.success) {
      setEcomerceCompany(res.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  const [getecommercesite, setEcommerceSite] = useState();
  const getsite = async () => {
    const res = await getData(`/ecommercedevsite`);
    if (res?.success) {
      setEcommerceSite(res.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  const [getecommerceperformance, setEcommercePerformance] = useState();
  const getperformance = async () => {
    const res = await getData(`/ecommercedevperformance`);
    if (res?.success) {
      setEcommercePerformance(res.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  const [getecommerceproducttype, setEcommerceProductType] = useState();
  const getproducttype = async () => {
    const res = await getData(`/ecommercedevtype`);
    if (res?.success) {
      setEcommerceProductType(res.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  const [getecommerceproject, setEcommerceProject] = useState();
  const getproject = async () => {
    const res = await getData(`/ecommercedevproject`);
    if (res?.success) {
      setEcommerceProject(res.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  const [isActive, setIsActive] = useState(false);
  const handleClick = () => {
    setIsActive(!isActive);
  };



  useEffect(() => {
    getcompany();
    getsite();
    getperformance();
    getproducttype();
    getproject();
  }, []);

  return (
    <>
      <section className="ECommerceContent">
        <LineAnimation />
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-md-6 mt-5">
              <div className="black1">
                <Girl />
                <h4 className="friend text-center">SEO FRIENDLY</h4>
                <p className="SEO text-center">
                  CONTENT AND ITS <br></br>DEVELOPMENT IS SEO OPTIMIZED
                </p>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 mt-5">
              <div className="black1">
                <Development />
                <h4 className="friend text-center mt-2">
                  STANDARDS BASED<br></br> DEVELOPMENT
                </h4>
                <p className="SEO text-center">
                  QUALITY BASED ON<br></br> HIGH STANDARDS
                </p>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 mt-5">
              <div className="black1">
                <Grow />
                <h4 className="friend text-center mt-4">NAVIGATION</h4>
                <p className="SEO text-center">
                  DESPITE HIGH LOADS, WORKS<br></br> SMOOTHLY
                </p>
              </div>
            </div>
            <h1 className="Heading-Commerce text-center ">
              E-Commerce Development Company In Pune
            </h1>
          </div>
        </div>
        {/* slider */}

        <div className="container-fluid">
          <div className="pune ">
            <Swiper
              slidesPerView={"auto"}
              centeredSlides={true}
              spaceBetween={30}
              pagination={{
                clickable: true,
              }}
              modules={[Pagination]}
              className="mySwiper "
            >
              {getecomercecompany?.map((data, index) => (
                <SwiperSlide key={index}>
                  <div className="grey-background">
                    <div className="row">
                      <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-7">
                        <div className="content1">
                          <h1 className="team text-start">
                            {htmlToReactParser.parse(data?.title)}
                          </h1>
                          <h1 className="simple text-start">
                            {data?.subtitle}
                          </h1>
                          <p className="rich">
                            {htmlToReactParser.parse(data?.description)}
                          </p>
                          {/* {slide.content.map((paragraph, i) => (
                            <p key={i} className="rich">
                              {paragraph}
                            </p>
                          ))} */}
                        </div>
                      </div>
                      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-5 ">

                        <img
                          src={imageUrl + data?.image}

                          className="asset"
                          alt=""
                        />

                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>

        <div className="container">
          <div class="row justify-content-center types-heading ms-0 me-0">
            <div class=" col-md-1 col-sm-1  mt-md-4 mt-sm-0 mt-0">
              <div class="yellow-border mt-md-5 mt-sm-4 mt-0"></div>
            </div>
            <div class=" col-md-10 col-sm-10">
              <h1 className="Heading-Commerce text-center  mt-md-5 mt-sm-3 mt-3">
                Types Of E-Commerce Sites
              </h1>
            </div>
            <div class=" col-md-1 col-sm-1 mt-md-4 mt-sm-0 mt-0">
              <div class="yellow-border  mt-md-5 mt-sm-4 mt-0"></div>
            </div>
          </div>
        </div>
        {/* accord cards */}
        <div className="container">
          <div className="row me-0 ms-0  mt-5">
            {getecommercesite?.map((data) => (
              <>
                <div className="col-lg-4 col-md-6 mt-3">
                  <div className="commerce">
                    <div class="accordion" id="accordionPanelsStayOpenExample">
                      <div class="accordion-item">
                        <h2
                          class="accordion-header"
                          id="panelsStayOpen-headingOne"
                        >
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#panelsStayOpen-collapseOne${data?.id}`}
                            aria-expanded="false"
                            aria-controls="panelsStayOpen-collapseOne"
                          >
                            <div className=" service-vdo">
                              <video
                                src={imageUrl + data?.video}
                                className=" video-sites"
                                autoPlay
                                muted
                                loop
                              />
                            </div>

                            <div className=" service-heading">
                              <h4 className="heading-E text-center">
                                {data?.title}
                              </h4>
                            </div>
                          </button>
                        </h2>
                        <div
                          id={`panelsStayOpen-collapseOne${data?.id}`}
                          class="accordion-collapse collapse"
                          aria-labelledby="panelsStayOpen-headingOne"
                        >
                          <div class="accordion-body">
                            {htmlToReactParser.parse(data?.description)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </>
            ))}
          </div>
        </div>
        <h1 className="Heading-Commerce text-center ">
          Types Of Products Sold VIA E-Commerce
        </h1>

        {/* types of product */}
        <div className="container">
          <div className="row">
            <div className=" col-lg-6 col-md-8 mt-lg-5 mt-md-5 mt-0">
              <p className="being">
                Being a digitally operated medium an e-commerce platform offers
                the seller a wide range of products, that are not possible in a
                physical location.{" "}
              </p>
              {getecommerceproducttype?.map((data) => (
                <>
                  <h4 className="friend">{data?.title} -</h4>
                  <p className="being">
                    {htmlToReactParser.parse(data?.description)}
                  </p>
                </>
              ))}

            </div>
            <div className="col-md-4 mt-5">
              <Online />
            </div>
          </div>
        </div>

        {/* slider */}

        <div className="container-fluid">
          <div className="row">
            <div className="teamwork mt-5">
              <Swiper
                modules={[Pagination, Autoplay]}
                spaceBetween={10}
                slidesPerView={1}
                navigation={false}
                pagination={{ clickable: true }}
                onSwiper={(swiper) => console.log(swiper)}
                onSlideChange={() => console.log("slide change")}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                  loop: true,
                }}
              >
                {getecommerceperformance?.map((data) => (
                  <>
                    <SwiperSlide>
                      <div className="background">
                        <div className="row">
                          <div className="col-md-6 my-auto text-end">
                            <h2 className="friend  text-center">
                              {data?.title}
                            </h2>
                            <div className="">
                              <p className="global ">
                                {htmlToReactParser.parse(data?.description)}
                              </p>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="text-center mb-5">
                              <video
                                src={imageUrl + data?.video}
                                height={300}
                                width={300}
                                className="second-video"
                                autoPlay
                                muted
                                loop
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  </>
                ))}

              </Swiper>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row mt-5">
            {getecommerceproject?.map((data, index) => (
              <>
                {index === 0  || index === 1 || index === 2? (
              <div key={index} className="col-xxl-4 col-xl-4 col-md-4 col-12 text-center mt-lg-5 mt-md-3 mt-2">
                  <div
                    className={isActive ? "show-text-content" : "text-content"}
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target={`#agroModal${data?.id}`}
                  >
                    <h1 className="agro text-center  mx-5 my-5 mt-lg-5 mt-md-3 mt-2">
                      {htmlToReactParser.parse(data?.title)}
                    </h1>
                  </div>
                </div>
                ) : (
                  ""
                )}
                </>
            ))}

            <div className="clickdispla" onClick={handleClick}>
              <Click />
            </div>

            {getecommerceproject?.map((data, index) => (
              <>
              {index === 3 || index === 4 || index === 5? (
              <div  key={index}  className="col-xxl-4 col-xl-4 col-md-4 col-12  text-center  my-5 mt-lg-5 mt-md-3 mt-2"
              >
                
                  <div
                    className={isActive ? "show-text-content" : "text-content"}
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target={`#agroModal${data?.id}`}
                  >
                    <h1 className="agro text-center mt-lg-5 mt-md-3 mt-2">
                      {htmlToReactParser.parse(data?.title)}
                    </h1>
                  </div>
                </div>
                ) : (
                  ""
                )}
                </>
            ))}
          </div>
        </div>
      </section>

      {/**--astrologyModal Modal--**/}

      <div className="development">
        {getecommerceproject?.map((data, index) => (
          <div
            class="modal fade development-modal"
            id={`agroModal${data?.id}`}
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog  modal-fullscreen">
              <div class="modal-content hotal-content">
                <div className="designing-img-holder">
                  <img
                    src={imageUrl + data?.image}
                    className="designing-img"
                    alt=""
                  />
                </div>
                <div class="modal-header">
                  <button
                    type="button"
                    class="btn-close mt-4"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body">
                  <div className="container">
                    <div className="col-lg-4 col-md-6">
                      <div className="content-holder ">
                        <h4>{data?.subtitle}</h4>
                        <div className="border-line my-3"></div>
                        <p>{htmlToReactParser.parse(data?.description)}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
export default ECommerceContent;
