import React from 'react'
import './Pay.css';
import Lottie from 'react-lottie';



function Sales({ animationData }) {


  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (


    <section className='main'>
      <div className='pay'>
        <Lottie className="me-auto"
          options={defaultOptions}

        />
      </div>
    </section>
  )
}

export default Sales;
