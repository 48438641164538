
import React, { useEffect, useState, useContext } from 'react';
import BlogInnerText from '../BlogInnerPage/BlogInnerText/BlogInnerText.js'
import BlogBanner from '../Blog/BlogBanner/BlogBanner.js'
import { Context } from './../utils/context.js'

const BlogInnerPage = () => {


    const { getData, imageUrl } = useContext(Context);

    const [getblogbanner, setBlogBanner] = useState();

    const getbloginnerbanner = async () => {


        const res = await getData(`/globalbanner?bannertype=blogs`);

        if (res?.success) {
            setBlogBanner(res.data.list);
            console.log(res.data.list);
        }

        else {
            console.error('Error fetching  data:', res?.error);
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0);

        getbloginnerbanner();

    }, []);

    return (
        <div>
            {getblogbanner?.map((data) => (
                <BlogBanner homebanner={imageUrl + data?.video} />
            ))}
            <BlogInnerText />
        </div>
    )
}

export default BlogInnerPage;