import React from 'react'
import Lottie from 'react-lottie';
import * as animationData from '../144762-team-working-on-a-project.json';

function TeamWork() {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    return (
        <>
            <div className='teamwork-lotti'>
                <Lottie
                    options={defaultOptions}
                    // height={250}
                    // width={300}
                />
            </div>
        </>
    )
}

export default TeamWork;