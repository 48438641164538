import React, { useEffect, useRef } from "react";
import '../Popup/Popup.css';
// import itpartner2 from '../../AboutUs/Popup/image/partner.mp4'

import itpartner2 from '../../AboutUs/Popup/Popup.js'
import Plus from '../Popup/animation/Plus.js';
import LazyLoad from "react-lazyload";

const Popup = () => {
    const vidRef = useRef();

    // useEffect(() => { vidRef.current.play(); }, []);
    return (
        <>
            <section className="services-popup-main">
                <button type="button" className="btn popup-button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasBottom" data-bs-whatever="@mdo"><Plus /></button>
                <div className="offcanvas offcanvas-bottom" tabindex="-1" id="offcanvasBottom" aria-labelledby="offcanvasBottomLabel">

                    <div class="offcanvas-body small p-0">
                        <div className='services-popup'>
                            <div className='row ms-0 me-0'>
                                <div className='col-md-1 bg-bottom-class'></div>

                                <div className='col-md-4 p-0'>
                                    <div className="second-sec">
                                        <h1 className='services-title-name'>Services</h1>
                                        <p className='subtitle-services'>that we provide</p>
                                        <div className='border-line-popup'></div>

                                        <div className='list-services'>
                                            <div class="d-flex align-items-start">
                                                <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                                    <button class="nav-link active" id="v-pills-Directory-tab" data-bs-toggle="pill" data-bs-target="#v-pills-Directory" type="button" role="tab" aria-controls="v-pills-Directory" aria-selected="true">Directory <span className="bd-right"></span></button>


                                                    <button class="nav-link" id="v-pills-Marketplace-tab" data-bs-toggle="pill" data-bs-target="#v-pills-Marketplace" type="button" role="tab" aria-controls="v-pills-Marketplace" aria-selected="false">Marketplace<span className="bd-right"></span></button>



                                                    <button class="nav-link" id="v-pills-Community-tab" data-bs-toggle="pill" data-bs-target="#v-pills-Community" type="button" role="tab" aria-controls="v-pills-Community" aria-selected="false">Community<span className="bd-right"></span></button>


                                                    <button class="nav-link" id="v-pills-Jobs-tab" data-bs-toggle="pill" data-bs-target="#v-pills-Jobs" type="button" role="tab" aria-controls="v-pills-Jobs" aria-selected="false">Jobs<span className="bd-right"></span></button>

                                                    <button class="nav-link" id="v-pills-Events-tab" data-bs-toggle="pill" data-bs-target="#v-pills-Events" type="button" role="tab" aria-controls="v-pills-Events" aria-selected="false">Events<span className="bd-right"></span></button>

                                                    <button class="nav-link" id="v-pills-Learning-tab" data-bs-toggle="pill" data-bs-target="#v-pills-Learning" type="button" role="tab" aria-controls="v-pills-Learning" aria-selected="false">E-Learning<span className="bd-right"></span></button>

                                                    <button class="nav-link" id="v-pills-Contribute-tab" data-bs-toggle="pill" data-bs-target="#v-pills-Contribute" type="button" role="tab" aria-controls="v-pills-Contribute" aria-selected="false">Contribute<span className="bd-right"></span></button>

                                                    <button class="nav-link" id="v-pills-Delivery-tab" data-bs-toggle="pill" data-bs-target="#v-pills-Delivery" type="button" role="tab" aria-controls="v-pills-Delivery" aria-selected="false">Delivery<span className="bd-right"></span></button>

                                                    <button class="nav-link" id="v-pills-Marketing-tab" data-bs-toggle="pill" data-bs-target="#v-pills-Marketing" type="button" role="tab" aria-controls="v-pills-Marketing" aria-selected="false">Marketing<span className="bd-right"></span></button>

                                                    <button class="nav-link" id="v-pills-Government-tab" data-bs-toggle="pill" data-bs-target="#v-pills-Government" type="button" role="tab" aria-controls="v-pills-Government" aria-selected="false">Government<span className="bd-right"></span></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-md-6 mt-5 p-0'>
                                    <div className="offcanvas-header">
                                        <h5 className="offcanvas-title" id="offcanvasBottomLabel"></h5>
                                        <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                                    </div>
                                    <div className='dfjsh mt-md-5'>

                                        <div class="tab-content" id="v-pills-tabContent">
                                            <div class="tab-pane fade show active" id="v-pills-Directory" role="tabpanel" aria-labelledby="v-pills-Directory-tab" tabindex="0">
                                                <LazyLoad offset={100} once>
                                                    <video
                                                        src={itpartner2}
                                                        ref={vidRef}
                                                        muted
                                                        autoPlay
                                                        loop
                                                        className="d-block w-100 video-class-popup"
                                                    />
                                                </LazyLoad>
                                            </div>

                                            <div class="tab-pane fade" id="v-pills-Marketplace" role="tabpanel" aria-labelledby="v-pills-Marketplace-tab" tabindex="0">
                                                <LazyLoad offset={100} once>
                                                    <video
                                                        src={itpartner2}
                                                        ref={vidRef}
                                                        muted
                                                        autoPlay
                                                        loop
                                                        className="d-block w-100 video-class-popup"
                                                    />
                                                </LazyLoad>
                                            </div>


                                            <div class="tab-pane fade" id="v-pills-Community" role="tabpanel" aria-labelledby="v-pills-Community-tab" tabindex="0">
                                                <LazyLoad offset={100} once>
                                                    <video
                                                        src={itpartner2}
                                                        ref={vidRef}
                                                        muted
                                                        autoPlay
                                                        loop
                                                        className="d-block w-100 video-class-popup"
                                                    />
                                                </LazyLoad>
                                            </div>

                                            <div class="tab-pane fade" id="v-pills-Jobs" role="tabpanel" aria-labelledby="v-pills-Jobs-tab" tabindex="0">
                                                <LazyLoad offset={100} once>
                                                    <video
                                                        src={itpartner2}
                                                        ref={vidRef}
                                                        muted
                                                        autoPlay
                                                        loop
                                                        className="d-block w-100 video-class-popup"
                                                    />
                                                </LazyLoad>
                                            </div>


                                            <div class="tab-pane fade" id="v-pills-Events" role="tabpanel" aria-labelledby="v-pills-Events-tab" tabindex="0">
                                                <LazyLoad offset={100} once>
                                                    <video
                                                        src={itpartner2}
                                                        ref={vidRef}
                                                        muted
                                                        autoPlay
                                                        loop
                                                        className="d-block w-100 video-class-popup"
                                                    />
                                                </LazyLoad>
                                            </div>

                                            <div class="tab-pane fade" id="v-pills-Learning" role="tabpanel" aria-labelledby="v-pills-Learning-tab" tabindex="0">
                                                <LazyLoad offset={100} once>
                                                    <video
                                                        src={itpartner2}
                                                        ref={vidRef}
                                                        muted
                                                        autoPlay
                                                        loop
                                                        className="d-block w-100 video-class-popup"
                                                    />
                                                </LazyLoad>
                                            </div>

                                            <div class="tab-pane fade" id="v-pills-Contribute" role="tabpanel" aria-labelledby="v-pills-Contribute-tab" tabindex="0">
                                                <LazyLoad offset={100} once>
                                                    <video
                                                        src={itpartner2}
                                                        ref={vidRef}
                                                        muted
                                                        autoPlay
                                                        loop
                                                        className="d-block w-100 video-class-popup"
                                                    />
                                                </LazyLoad>
                                            </div>

                                            <div class="tab-pane fade" id="v-pills-Delivery" role="tabpanel" aria-labelledby="v-pills-Delivery-tab" tabindex="0">
                                                <LazyLoad offset={100} once>
                                                    <video
                                                        src={itpartner2}
                                                        ref={vidRef}
                                                        muted
                                                        autoPlay
                                                        loop
                                                        className="d-block w-100 video-class-popup"
                                                    />
                                                </LazyLoad>
                                            </div>

                                            <div class="tab-pane fade" id="v-pills-Marketing" role="tabpanel" aria-labelledby="v-pills-Marketing-tab" tabindex="0">
                                                <LazyLoad offset={100} once>
                                                    <video
                                                        src={itpartner2}
                                                        ref={vidRef}
                                                        muted
                                                        autoPlay
                                                        loop
                                                        className="d-block w-100 video-class-popup"
                                                    />
                                                </LazyLoad>
                                            </div>

                                            <div class="tab-pane fade" id="v-pills-Government" role="tabpanel" aria-labelledby="v-pills-Government-tab" tabindex="0">
                                                <LazyLoad offset={100} once>
                                                    <video
                                                        src={itpartner2}
                                                        ref={vidRef}
                                                        muted
                                                        autoPlay
                                                        loop
                                                        className="d-block w-100 video-class-popup"
                                                    />
                                                </LazyLoad>
                                            </div>

                                        </div>
                                    </div>
                                </div>



                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Popup;



