import React from 'react'
import Lottie from 'react-lottie';
import * as animationData from '../84786-measure.json';


function Second() {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    return (
        <>
            <div className='second-project'>
                <Lottie
                    options={defaultOptions}
                />
            </div>
        </>
    )
}

export default Second;