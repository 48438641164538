// import React from 'react'
import React, { useEffect } from 'react';
import AboutBanner from '../AboutUs/AboutBanner/AboutBanner'
import HealthFirstTab from '../HealthAndFitness/HealthFirstTab/HealthFirstTab.js'
import Popup from '../AboutUs/Popup/Popup.js'
import '../HealthAndFitness/HealthFirstTab/HealthFirstTab.js'


const HealthAndFitness = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return (
        <div>
            <AboutBanner bannertitle='Case Studies' bannersubtitle='A Good Design Speaks For Itself '  />
            <HealthFirstTab />           
            <Popup />

        </div>
    )
}

export default HealthAndFitness;