import React, { useContext, useState, useEffect } from "react";
import "../Brand/Brand.css";
import img1 from "../image/img1.png";
import img2 from "../image/img2.png";
import img3 from "../image/img3.png";
import img4 from "../image/img4.png";
import First from "../animation/js/First";
import Second from "../animation/js/Second";
import Third from "../animation/js/Third";
import Fourth from "../animation/js/Fourth";
import Fifth from "../animation/js/Fifth";
import Sixth from "../animation/js/Sixth";

import { LineAnimation } from "../../LineAinimation/LineAnimation";
import { Context } from "../../utils/context.js";

const Brand = () => {
  const { getData, imageUrl } = useContext(Context);
  var HtmlToReactParser = require("html-to-react").Parser;
  var htmlToReactParser = new HtmlToReactParser();

  const [getbrandproduct, setBrandProduct] = useState();
  const getproduct = async () => {
    const res = await getData(`/brandproduct`);
    if (res?.success) {
      setBrandProduct(res.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  const [getbranddevelopment, setBrandDevelopment] = useState();
  const getdevelopment = async () => {
    const res = await getData(`/branddevelopment`);
    if (res?.success) {
      setBrandDevelopment(res.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  const [getbrandproject, setBrandProject] = useState();
  const getproject = async () => {
    const res = await getData(`/brandproject`);
    console.log(res.data);
    if (res?.success) {
      setBrandProject(res?.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  useEffect(() => {
    getproduct();
    getdevelopment();
    getproject();
  }, []);
  return (
    <>
      <section className="Brand ">
        <LineAnimation />
        <div className="container">
          <div className="row">
            {getbrandproduct?.map((data) => (
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 mt-xxl-5 mt-xl-5 mt-lg-5 mt-md-4 mt-3">
                <div class="card">
                  <div class="card-body first-card-body text-center">
                    <video
                      src={imageUrl + data?.video}
                      height={400}
                      width={400}
                      muted
                      autoPlay
                      loop
                      className="advantage-video"
                    />
                    <h5 class="card-title text-center mt-3">{data?.title}</h5>
                    <p class="card-text text-center">{data?.subtitle}</p>
                  </div>
                </div>
              </div>
            ))}

            {/* 2nd section */}
            <div className="col-md-12 text-center mt-md-5 mt-4">
              <h2 className="title-name-brand">
                Custom Software Development <br /> Company In Pune
              </h2>
            </div>
            {getbranddevelopment?.map((data, index) => (
              <div
                key={index}
                className="row mt-xxl-5 mt-xl-5 mt-lg-5 mt-md-4 mt-2"
              >
                {index % 2 === 0 ? (
                  <>
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mt-xxl-5 mt-xl-5 mt-lg-5 mt-md-4 mt-0">
                      <div className="text-holder-brand mt-xxl-5 mt-xl-5 mt-lg-5 mt-md-4 mt-0">
                        <h3>
                          {data?.title}
                          <span>{data?.subtitle}</span>
                        </h3>
                        <p className="mt-xxl-5 mt-xl-5 mt-lg-5 mt-md-4 mt-2">
                          {htmlToReactParser.parse(data?.description)}
                        </p>
                      </div>
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 mt-xxl-5 mt-xl-5 mt-lg-5 mt-md-4 mt-2">
                      <img
                        src={imageUrl + data?.image}
                        className="brand-img"
                        alt="..."
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <div className=" col-lg-6 col-md-6 order-md-0 order-lg-0 order-sm-2 order-2 mt-5">
                      <img
                        src={imageUrl + data?.image}
                        className="brand-img"
                        alt="..."
                      />
                    </div>
                    <div className=" col-lg-6 col-md-6 order-lg-0 order-md-0  order-sm-1 order-1 mt-xxl-5 mt-xl-5 mt-lg-5 mt-md-4 mt-2">
                      <div className="text-holder-brand mt-xxl-5 mt-xl-5 mt-lg-5 mt-md-4 mt-2">
                        <h3>
                          {data?.title}
                          <span>{data?.subtitle}</span>
                        </h3>
                        <p className="mt-xxl-5 mt-xl-5 mt-lg-5 mt-md-4 mt-2">
                          {htmlToReactParser.parse(data?.description)}
                        </p>
                      </div>
                    </div>
                  </>
                )}
              </div>
            ))}



            {/* 3rd section */}
            <div className="advantage mt-5">
              <div className="row mt-5">
                <div className="col-md-1 text-center">
                  <div className="yellow-line-title mt-lg-4 mt-md-4 mt-3"></div>
                </div>
                <div className="col-md-10 text-center title-project mt-lg-0 mt-md-0 mt-2 mb-md-5 mb-0">
                  <h3>Advantages Of Brand Awareness</h3>
                </div>
                <div className="col-md-1 text-center">
                  <div className="yellow-line-title mt-lg-4 mt-md-4 mt-3"></div>
                </div>


                {getbrandproject?.map((data, index) => (
                  <div className=" col-md-4 mt-3">
                    <div className="commerce">
                      <div
                        className="accordion"
                        id="accordionPanelsStayOpenExample"
                      >
                        <div className="accordion-item">
                          <h2
                            className="accordion-header"
                            id="panelsStayOpen-headingOne"
                          >
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={`#panelsStayOpen-collapseOne${data?.id}`}
                              aria-expanded="false"
                              aria-controls="panelsStayOpen-collapseOne"
                            >
                              <div className=" service-vdo">
                                <video
                                  className="service-img"
                                  src={imageUrl + data?.video}
                                  muted
                                  autoPlay
                                  loop
                                  //  height={300}
                                  width={300}
                                //   alt={data?.title}
                                ></video>
                              </div>
                              <div className="service-heading">
                                <h4 className="heading-E">{data?.title}</h4>
                              </div>
                            </button>
                          </h2>
                          <div
                            id={`panelsStayOpen-collapseOne${data?.id}`}
                            className="accordion-collapse collapse"
                            aria-labelledby="panelsStayOpen-headingOne"
                          >
                            <div className="accordion-body">
                              {htmlToReactParser.parse(data?.description)}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}


              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Brand;
