import React, { useEffect, useContext, useState } from "react";
import '../Customer/Customer.css'
import CustomerService from '../Animation/CustomerService.js';
import WorkingMan from '../Animation/js/WorkingMan.js';
import CustRating from '../Animation/js/CustRating.js';
import Branding from '../Animation/js/Branding.js';
import TeamWork from '../Animation/js/TeamWork.js';
import Mobile from '../Animation/js/mobile';
import Plan from '../Animation/js/Plan';
import { LineAnimation } from "../../LineAinimation/LineAnimation";

import AOS from "aos";
import "aos/dist/aos.css";
import { Context } from '../../utils/context.js';

const Customer = () => {

    const { getData, imageUrl } = useContext(Context);
    var HtmlToReactParser = require('html-to-react').Parser;
    var htmlToReactParser = new HtmlToReactParser();

    
    const [appengegment, setAppbanner] = useState();
      const getappengement = async () => {

        const res = await getData(`/appengement`);
        if (res?.success) {
            setAppbanner(res.data);
        }
        else {
            console.error('Error fetching  data:', res?.error);
        }
    }

    
    const [getappdevelopment, setAppDevelopment] = useState();
      const getappdeve = async () => {

        const res = await getData(`/appdevelopment`);
        if (res?.success) {
            setAppDevelopment(res.data);
        }
        else {
            console.error('Error fetching  data:', res?.error);
        }
    }

    useEffect(() => {
        AOS.init();
        AOS.refresh();

        getappengement();
        getappdeve();

    }, []);
    return (
        <>
            <section className='Customer'>
                <LineAnimation />
                <div className='container'>
                    <div className='row'>
                        {appengegment?.map((data) => (
                            <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 mt-5' data-aos="fade-up" data-aos-duration="2000">
                                <div class="card">
                                    <div className="customer-img-holder"><img key={data?.id} src={imageUrl + data?.backimage} height={100} width={100} /></div>
                                    <div class="card-body">
                                        <h5 class="card-title text-center">{data?.title}</h5>
                                        <p class="card-text text-center">{data?.subtitle}</p>
                                    </div>
                                </div>
                            </div>
                        ))};
                       


                        {/* App development */}
                        <div className='col-md-12'>
                            <h5 className='text-center company-branch-name'>APP Development Company In Pune</h5>
                        </div>
                        <div className='col-md-4'></div>
                        <div className='col-md-4'>
                            <div class="card branding-card mt-5">
                                <div class="card-body card-body-branding">
                                    <Branding />
                                </div>
                            </div>
                        </div>

                        <div className='col-md-3'></div>
                        {/* Developement section */}
                        <div className='col-md-12'>
                            
                            <div className='text-center'>
                                <h2 className='section-title-name mt-5'>Development</h2>
                            </div>
                            <p className='text-center app-dev-text mt-5'>Every app-building process follows the same steps- identifying requirements, designing prototypes, testing, implementation, and integration.</p>
                            <p className='text-center app-dev-text mb-5'>The development process is completed according to the development standards and releases bug-free guaranteed apps.</p>
                        </div>
                        <div className='col-xxl-6 col-xl-6 col-lg-5 col-md-5  mt-md-0 mt-3 mx-auto ' data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine">
                            <div class="card">
                                <div class="card-body text-center card-body-branding">
                                    <TeamWork />
                                </div>
                                <h5 class="second-card-text text-center mt-5">Our Teamwork To Engineer </h5>
                                <h5 className='second-card-text text-center'>The Illusion Of <span>Simplicity</span></h5>
                                <p class="card-text text-center mt-3">Profcyma Global is the best ” Mobile application development “ company based in Pune, with over 5+ years of experience. We have a team of experts in making custom-designed applications in both ” iOS app development and Android app development ” . Our team is dedicated to making our clients successful by providing them, with great products, services, and experiences. Our primary aim is to completely utilize the power of the web and increase customer engagement and successfully transform your business.</p>
                            </div>
                        </div>
                        <div className='col-xxl-6 col-xl-6 col-lg-5 col-md-5 mt-md-0 mt-3 mx-auto' data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine">
                            <div class="card" >
                                <div class="card-body text-center card-body-branding">
                                    <Mobile />
                                </div>
                                <h5 class="second-card-text text-center mt-5">What Is Application</h5>
                                <h3 className='dev-title text-center'>Development</h3>
                                <p class="card-text text-center mt-3">In Application, development is the process of creating a computer program or creating a set of programs that perform different tasks which a business requires like calculating monthly expenses to scheduling sales reports, different applications help businesses automate processes and then increase efficiency. Our team members take pride in being highly skilled experts who can complete their jobs in the best possible way and deliver the most reliable and quality product which is completed based on development standards and releases bug-free guaranteed apps.</p>
                            </div>
                        </div>
                        <div className='col-xxl-6 col-xl-6 col-lg-5 col-md-5 mt-md-0 mt-5 mx-auto'>
                            <div class="card mt-5" >
                                <div class="card-body text-center card-body-branding">
                                    <Plan />
                                </div>
                                <p class="card-title second-card-text text-center mt-3">We Plan And Develop Your</p>
                                <h3 className='dev-title mt-3' >Unique Ideas</h3>
                            </div>
                        </div>



                        {/* Unique Ideas */}
                        <div className='col-md-12'>
                            <div className='text-center mt-3'>
                                <p className='app-dev-text'>We constantly serve various industries like e-commerce, social media apps, educational apps, etc. We aim primarily to provide the best app which fulfills clients’ requirements and helps in the growth and transformation of your business by harnessing the power of technology and the web to increase customer engagement.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </>
    )
}

export default Customer;