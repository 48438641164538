import React from 'react'
import Lottie from 'react-lottie';
import * as animationData from '../93940-branding.json';

function Branding() {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    return (
        <>
            <div className='branding-lotti'>
                <Lottie
                    options={defaultOptions}
                // height={300}
                // width={300}
                />
            </div>
        </>
    )
}

export default Branding;