import React, { useEffect, useRef } from "react";
// import homebanner from '../BlogBanner/video/home.mp4';
import '../Blog/BlogBanner/BlogBanner.css'
import LazyLoad from "react-lazyload";

const AppBanner = (props) => {
    const appvideo = props.appvideo;
    const apptitle = props.apptitle;
    const appsubtitle = props.appsubtitle;
    const mediatype = props.mediatype;

    return (
        <>
            <section className="blog-banner-main about-banner-main">
                <div className="row ms-0 me-0">
                    <div className='col-md-12 p-0'>
                        <div className="blog-banner about-banner ">
                        {mediatype === 'Video' ? (
                            <LazyLoad offset={100} once>
                                <video
                                    src={appvideo}
                                    muted
                                    autoPlay
                                    loop
                                    className="d-block video-class-about"
                                />
                            </LazyLoad>
                            ) : (
                                <img
                                    src={appvideo}
                                    className="d-block video-class-about"
                                    height={500}
                                />
                            )}
                        </div>
                        <div className="img-overlay col-lg-6 col-md-6 col-sm-12">
                            <div className="text-holder-banner">
                                <h2>{apptitle}</h2>
                                <p>{appsubtitle}</p>
                            </div>
                        </div>

                        <div className="overlay"></div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AppBanner
