import React, { useState, useEffect, useContext } from 'react'
import '../Dmarketting/Dmarketting.css';
import special from '../image/special.png'
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Context } from '../../utils/context.js'
library.add(fas)
const Brand = () => {

    const { getData, imageUrl } = useContext(Context);
    var HtmlToReactParser = require('html-to-react').Parser;
    var htmlToReactParser = new HtmlToReactParser();


    const [getbrandmarket, setBrandMarket] = useState();
    const getmarket = async () => {
        const res = await getData(`/brandstrategy`);
        console.log(res.data);
        if (res?.success) {
            setBrandMarket(res.data);
        }
        else {
            console.error('Error fetching  data:', res?.error);
        }
    }

    const [currentIndex, setCurrentIndex] = useState(0);
    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % getbrandmarket.length);
    };

    const handlePrev = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? getbrandmarket.length - 1 : prevIndex - 1
        );
    };


    useEffect(() => {

        getmarket();

    }, []);
    return (
        <>
            <section className='Brands-special'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12 text-center mt-5'>
                            <div className='title-special'>
                                <h3>How Do We Choose The Best Digital <br /> Marketing Strategy For Brand Awareness</h3>
                            </div>
                        </div>
                        {/* <div className='title-special-2'>
                            <p>Choosing the right digital marketing channel is more about selecting the right mix of multiple channels for successful brand awareness. Profcyma global helps strategize the correct composition of brand awareness techniques for your company. A few of these steps include</p>
                        </div> */}

                    </div>




                    <div className='row'>
                        <div className='col-md-8 order-md-1  order-2 mt-md-5 mt-3  mb-5'>
                            <div className='title-special-2 mt-md-5 mt-3'>
                                <p>Choosing the right digital marketing channel is more about selecting the right mix of multiple channels for successful brand awareness. Profcyma global helps strategize the correct composition of brand awareness techniques for your company. A few of these steps include</p>
                                <ul>
                                    <li className='mt-5'>{getbrandmarket?.[currentIndex]?.title}</li>
                                    <p className='mt-3'>{htmlToReactParser.parse(getbrandmarket?.[currentIndex]?.description)}</p>
                                    <div className='text-center'>
                                        <button type='button' className='btn yellow-button me-2 '  onClick={handlePrev}></button>
                                        <button type='button' className='btn  yellow-button1' onClick={handleNext}></button>
                                    </div>
                                </ul>
                            </div>
                        </div>

                        <div className='col-md-4 order-md-1 order-1 mb-5'>
                            <div className='image-holder'>
                            <img src={special} className='special-img' alt='...' />
                            </div>
                        </div>
                    </div>


                </div>
            </section >
        </>
    )
}

export default Brand