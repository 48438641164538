import React, { useState, useEffect } from "react";
import "../../Slider/Crousel/Crousel.css";
import { Autoplay, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';
import 'react-phone-number-input/style.css'
import { Link, useNavigate } from "react-router-dom";
import { useContext } from "react";
import { Context } from "../../../utils/context";

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";

const Crousel = () => {
  const [value, setValue] = useState();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const { getData, imageUrl } = useContext(Context);
  const { postData } = useContext(Context);
  const [errors, setErrors] = useState({});
  const [getcrouselData, setCrouseldata] = useState();
  const [formData, setFormData] = useState({
    full_name: "",
    email: "",
    mobile_number: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });
  const handleSubmit = async () => {
    const validationErrors = validateForm();

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      try {
        const finalData = new FormData();
        finalData.append(`full_name`, formData.full_name);
        finalData.append(`mobile_number`, formData.mobile_number);
        finalData.append(`email`, formData.email);

        const res = await postData("/requestform", formData);
        if (res?.success) {
          console.log(res);
          handleClose();
          setErrors("");
          await setFormData({
            full_name: "",
            mobile_number: "",
            email: "",
          });
        } else {

          console.error("Form submission failed:", res?.error);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  const validateForm = () => {
    let errors = {};
    const regexAlfaNumric = /^[A-Za-z0-9]+(\s[A-Za-z0-9]+)*$/;
    const regexAlfa = /^[A-Za-z]+(\s[A-Za-z]+)*$/;
    const emailval =
      /^([A-Za-z0-9_\-\.])+\@(?!(?:[A-Za-z0-9_\-\.]+\.)?([A-Za-z]{2,4})\.\2)([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    const mobval = /^\d{10}$/;

    if (!formData.full_name) {
      errors.full_name = "Full Name is required";
    } else if (!regexAlfaNumric.test(formData.title?.trim())) {
      errors.full_name = "Enter a valid Full Name";
    }
    if (!formData.email) {
      errors.email = "Email is required";
    } else if (!emailval.test(formData.email?.trim())) {
      errors.email = "Enter valid email id";
    }

    if (!formData.mobile_number) {
      errors.mobile_number = "Mobile Number is required";
    } else if (!mobval.test(formData.mobile_number?.trim())) {
      errors.mobile_number = "Enter a 10-digit number";
    }

    return errors;
  };

  var HtmlToReactParser = require('html-to-react').Parser;
  var htmlToReactParser = new HtmlToReactParser();

  const [phone, setPhone] = useState("");

  const getcrousel = async () => {

    const res = await getData(`/youtube`);

    if (res?.success) {
      console.log(res.data);
      setCrouseldata(res.data);
    } else {

    }
  }
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const errorStyle = {
    color: "red",
    marginLeft: "5px",
    // fontSize: "11px",
  };

  useEffect(() => {
    getcrousel();
  }, []);

  return (
    <>
      <section className="addtieslider">
        <div className="container-fluid">

          <div className="row">


            <Swiper
              slidesPerView={"auto"}
              centeredSlides={true}
              spaceBetween={30}
              modules={[Navigation, Autoplay]}
              navigation
              initialSlide={1}
              // centeredSlides={true}
              className="mySwiper"
            >
              {getcrouselData?.map((data, index) => (
                <SwiperSlide key={index}>
                  <div className="yellow" style={{ backgroundColor: data?.color_code }}>

                    <div className="row justify-content-center main">
                      <div className="col-lg-6 col-sm-10 col-8">
                        <div className="video">
                          <iframe src={data?.url}></iframe>
                        </div>
                      </div>
                      <div className="col-lg-6 col-sm-10 col-9">
                        <div className="content mt-lg-4 mt-md-0 ms-lg-4 ms-0">
                          <h4>{htmlToReactParser.parse(data?.title)}</h4>
                          <p>{htmlToReactParser.parse(data?.description)}</p>
                          <div className="black-button">
                            <button type="button" className="requested-button" onClick={handleShow} class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
                              Request A Demo
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </section>
      {/* modal  */}
      <div className="Request-demo">
        <div class={show ? "modal fade show" : "modal fade"} style={{ display: show ? "block" : "none" }} id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel" >Request A Demo</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div className="row">
                  <div className="col-md-12">
                    <div class="mb-3">
                      <label for="exampleFormControlInput1" class="form-label">Full Name</label>
                      <input type="text" class="form-control" id="exampleFormControlInput1" name="full_name" value={formData.full_name} onChange={handleChange} placeholder="Enter Full Name" />
                      {errors?.full_name && (
                        <span style={errorStyle}>
                          {errors?.full_name}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div class="mb-3">
                      <label for="exampleFormControlInput1" class="form-label">Email address</label>
                      <input type="email" class="form-control" id="exampleFormControlInput1" name="email" value={formData.email} onChange={handleChange} placeholder="name@example.com" />
                      {errors?.email && (
                        <span style={errorStyle}>
                          {errors?.email}
                        </span>
                      )}
                    </div>
                  </div>

                  <div className="col-md-12">
                    <label for="exampleFormControlInput1" class="form-label">Phone Number</label>
                    <input type="mobile_number" class="form-control" id="exampleFormControlInput1" maxLength="10" name="mobile_number" value={formData.mobile_number} onChange={handleChange} placeholder="Enter mobile number " />
                    {errors?.mobile_number && (
                      <span style={errorStyle}>
                        {errors?.mobile_number}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <div className="submit">
                  <button type="button" class="btn btn-primary" onClick={handleSubmit}>Submit</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </>
  );
};

export default Crousel;
