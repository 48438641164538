import React from "react";
import { useState, useContext, useEffect } from "react";
import "./DigitalContent.css";
import banner1 from "../images/banner1.png";
import { Pagination, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import Smily from "../../animation/Smily/Smily";
import Team from "../../animation/Team/Team";
import Branding from "../../animation/Branding/Branding";
// import Sales from "../../animation/Sales/Sales";
import Pay from "../../animation/Pay/Pay";
import Email from "../../animation/Email/Email";
import tab from "../images/tab.png";
import tab1 from "../images/tab1.png";
import tab2 from "../images/tab2.png";
import tab3 from "../images/tab3.png";
import asset1 from "../images/asset1.png";
import rigth from "../images/rigth.png";
import left from "../images/left.png";
import { LineAnimation } from "../../LineAinimation/LineAnimation";
import animationData1 from "../../animation/Pay/pay.json";
import animationData2 from "../../animation/Email/email.json";
import animationData4 from "../../animation/Team/team.json";
import animationData5 from "../../animation/Branding/branding.json";
import animationData3 from "../../animation/Smily/smily.json";
import { Context } from "../../utils/context.js";

const DigitalContent = () => {


  const { getData, imageUrl } = useContext(Context);

  var HtmlToReactParser = require("html-to-react").Parser;
  var htmlToReactParser = new HtmlToReactParser();

  const [getdigitalcust, setDigiCust] = useState();

  const getdigicust = async () => {
    const res = await getData(`/digitalcustomer`);

    if (res?.success) {
      setDigiCust(res.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  const [getdigimarket, setDigiMarket] = useState();

  const getdigital = async () => {
    const res = await getData(`/digitalmarketing`);

    if (res?.success) {
      setDigiMarket(res.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  const [getdigitaltech, setDigitalTech] = useState();

  const getdigitech = async () => {
    const res = await getData(`/digitaltech`);

    if (res?.success) {
      setDigitalTech(res.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  const [getdigitaleng, setDigitalEng] = useState();

  const getdigieng = async () => {
    const res = await getData(`/digitaleng`);

    if (res?.success) {
      setDigitalEng(res.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  // const [getdigitalcontent, setDigitalContent] = useState();

  // const getdigicontent = async () => {
  //   const res = await getData(`/digitalcontent`);

  //   if (res?.success) {
  //     setDigitalContent(res.data);
  //   } else {
  //     console.error("Error fetching  data:", res?.error);
  //   }
  // };
  const [getdigitalcontent, setDigitalContent] = useState();

  const getdigicontent = async () => {
    const res = await getData(`/digitalcontent`);
    if (res?.success) {
      let devdigital = [];
      let temp = [];
      for (const val of res.data) {
        if (temp.length === 2) {
          devdigital.push(temp.slice());
          temp = [];
        }
        temp.push(val);
      }
      if (temp.length > 0) {
        devdigital.push(temp);
      }
      console.log(devdigital);
      setDigitalContent(devdigital);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };


  const [getdigitalproject, setDigitalProject] = useState();

  const getdigiproject = async () => {
    const res = await getData(`/digitalproject`);

    if (res?.success) {
      setDigitalProject(res.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  useEffect(() => {
    getdigicust();
    getdigital();
    getdigitech();
    getdigieng();
    getdigicontent();
    getdigiproject();
  }, []);

  return (
    <>
      <section className="Digital-Marketing">
        <LineAnimation />
        <div className="container">
          <div className="row   me-0 ms-0">
            <div className="customer mt-5">
              <div className="row  ">
                <Swiper
                  // install Swiper modules
                  modules={[Pagination, Autoplay]}
                  spaceBetween={20}
                  navigation={false}
                  pagination={{ clickable: true }}
                  onSwiper={(swiper) => console.log(swiper)}
                  onSlideChange={() => console.log("slide change")}
                  className="my-swiper mt-5"
                  //   autoplay={{
                  //     delay: 2500,
                  //     disableOnInteraction: false,
                  //     loop: true,
                  //   }}

                  breakpoints={{
                    280: {
                      slidesPerView: 1,
                      spaceBetween: 20,
                    },
                    // when window width is >= 320px
                    320: {
                      slidesPerView: 1,
                      spaceBetween: 20,
                    },
                    // when window width is >= 480px
                    480: {
                      slidesPerView: 1,
                      spaceBetween: 30,
                    },
                    // when window width is >= 640px
                    640: {
                      slidesPerView: 2,
                      spaceBetween: 40,
                    },

                    768: {
                      slidesPerView: 2,
                      spaceBetween: 40,
                    },

                    1024: {
                      slidesPerView: 3,
                      spaceBetween: 40,
                    },

                    1200: {
                      slidesPerView: 3,
                      spaceBetween: 40,
                    },

                    1400: {
                      slidesPerView: 3,
                      spaceBetween: 40,
                    },
                  }}
                >
                  {getdigitalcust?.map((data) => (
                    <SwiperSlide>
                      <div className="col-md-12 mb-5">
                        <div className="justify-content-center">
                          <div class="text-center">
                            <img
                              key={data?.id}
                              src={imageUrl + data?.backimage}
                              height={200}
                              width={200}
                              class="digital-img"
                            />
                          </div>
                          <h4 className="loyalty text-center">{data?.title}</h4>
                          <p className="post text-center">{data?.subtitle}</p>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>


              </div>
            </div>
          </div>
        </div>


        <h1 class="Digital text-center ">
          <span class="pune pb-lg-0  pb-md-5"> Digital Marketing Company In Pune</span>
        </h1>

        {/* slider */}
        <LineAnimation />
        <div className="">
          <div className="row ms-0 me-0  mt-5">
            <div className="teamwork">
              <Swiper
                modules={[Pagination, Autoplay]}
                spaceBetween={10}
                slidesPerView={1}
                navigation={false}
                pagination={{ clickable: true }}
                onSwiper={(swiper) => console.log(swiper)}
                onSlideChange={() => console.log("slide change")}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                  loop: true,
                  // pauseOnMouseEnter: true,
                }}
              >
                <>
                  {getdigimarket?.map((data) => (
                    <SwiperSlide>
                      <div className=" banner-slider active">
                        <img
                          src={imageUrl + data?.backimage}
                          className="d-block w-100 banner-img"
                          alt="..."
                        />

                        <div className="img-overlay col-lg-4 col-md-6 col-sm-12">
                          <div className="text-holder ">
                            <h2>{data?.title}</h2>
                            <p>
                              <p className="global">
                                {htmlToReactParser.parse(data?.description)}
                              </p>
                            </p>
                          </div>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </>
              </Swiper>
            </div>
          </div>
        </div>

        <div class="row justify-content-center">
          <div class="col-lg-1 col-md-1 col-sm-1 mt-5">
            <div class="yellow-border mt-lg-5 mt-md-5 mt-3"></div>
          </div>
          <div class="col-lg-6 col-md-10 col-sm-10">
            <h1 class="Digital text-center  mt-lg-5 mt-md-5 mt-3">Technology Is A Must For Designing And Creating The Best Digital Marketing Campaign</h1>
          </div>
          <div class="col-lg-1 col-md-1 col-sm-1  mt-lg-5 mt-md-5 mt-sm-5 mt-0">
            <div class="yellow-border mt-lg-5 mt-md-5 mt-3"></div>
          </div>
        </div>

        {/* Accordian */}
        <div className="digital-cards">
          <div className="container">
            <div className="row ms-0 me-0   mt-5">
              {getdigitaltech?.map((data, index) => (
                <div className="col-md-4 mt-3">
                  <div className="commerce">
                    <div className="accordion"
                      id="accordionPanelsStayOpenExample"
                    >
                      <div className="accordion-item">
                        <h2
                          className="accordion-header"
                          id="panelsStayOpen-headingOne"
                        >
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#panelsStayOpen-collapseOne${data?.id}`}
                            aria-expanded="false"
                            aria-controls="panelsStayOpen-collapseOne"
                          >
                            <div className=" service-vdo">
                              <img
                                className="service-img"
                                src={imageUrl + data?.backimage}
                                alt={data?.title}
                              />
                            </div>

                            <div className="service-heading">
                              <h4 className="heading-E">{data?.title}</h4>
                            </div>
                          </button>
                        </h2>
                        <div
                          id={`panelsStayOpen-collapseOne${data?.id}`}
                          className="accordion-collapse collapse"
                          aria-labelledby="panelsStayOpen-headingOne"
                        >
                          <div className="accordion-body">
                            {htmlToReactParser.parse(data?.description)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}

            </div>
          </div>
        </div>

        {/* digital marketer step */}

        <div className="marketer mt-5">
          <div className="row  ">
            <div className="col-xxl-6 col-xl-6 col-lg-7 ">
              <h4 className="attain text-center mt-5">
                Digital Marketer's Steps To Attain Maximum
              </h4>
              <h1 className="yellow-content text-center">
                Customer Engagement
              </h1>


              <div className="row justify-content-center">
                <div className="col-xxl-6 col-xl-6 col-lg-7">
                  <div className="main-class">
                    <div class="accordion" id="accordionExample">
                      {getdigitaleng?.map((data) => (
                        <div class="accordion-item">
                          <h2 class="accordion-header">
                            <button
                              class="accordion-button"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={`#collapseOne${data?.id}`}
                              aria-expanded="true"
                              aria-controls="collapseOne"
                            >
                              {data?.title}
                            </button>
                          </h2>
                          <div
                            id={`collapseOne${data?.id}`}
                            class="accordion-collapse collapse "
                            data-bs-parent="#accordionExample"
                          >
                            <div class="accordion-body">
                              {htmlToReactParser.parse(data?.description)}
                            </div>
                          </div>
                        </div>
                      ))}

                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xxl-6 col-xl-6 col-lg-5">
              <div className="tab">
                <img src={tab} className="tab" alt="" />
              </div>
            </div>
          </div>
        </div>

        {/* Content Creation */}
        <Swiper
          spaceBetween={30}
          pagination={{
            clickable: true,
          }}
          autoplay={{
            delay: 3000,
          }}

          modules={[Pagination]}
          className="mySwiper"
        >
          {/* {getdigitalcontent?.slice(0, 3).map((data) => ( */}
          {getdigitalcontent?.map((item, slideIndex) => (
            <SwiperSlide key={slideIndex}>
              <div className="content-creation mt-5">
                {item?.map((data, index) => (
                  <>
                    <div className="row " key={index}>
                      {index % 2 === 0 ? (
                        <>
                          <div className="col-lg-3 col-md-1 mt-5">
                            <div className="right text-end">
                              <img src={rigth} className="rigth" alt="" />
                            </div>
                          </div>

                          <div className=" col-lg-6 col-md-11 mt-5">
                            {/* <h3>{data?.id}</h3> */}

                            <div className="creation">
                              <h4 className="heading-content">{data?.title}</h4>

                              <label>
                                <p className="paragraph-content">
                                  {htmlToReactParser.parse(data?.description)}
                                </p>
                              </label>
                            </div>

                          </div>
                        </>
                      ) : (null)}
                    </div >
                  </>
                ))}
                {item?.map((data, index) => (
                  <>
                    <div className="row justify-content-end" key={index}>
                      {index % 2 !== 0 ? (
                        <>
                          <div className=" col-lg-6 col-md-8 mt-5">
                            {/* <h3>{data?.id}</h3> */}

                            <div className="creation">
                              <h4 className="heading-content">{data?.title}</h4>

                              <label>
                                <p className="paragraph-content">
                                  {htmlToReactParser.parse(data?.description)}
                                </p>
                              </label>
                            </div>

                          </div>
                          <div className="col-md-3 mt-5">
                            <div className="right text-start right-img">
                              <img src={left} className="rigth " alt="" />
                            </div>
                          </div>
                        </>
                      ) : (null)}
                    </div >
                  </>
                ))}
              </div>
            </SwiperSlide>
          ))}



        </Swiper>

        <LineAnimation />
       
          <div className="row justify-content-center">
            <div class="col-lg-1 col-md-1 col-sm-1 mt-5">
              <div class="yellow-border mt-lg-5 mt-md-5 mt-3"></div>
            </div>

            <div class="col-lg-6 col-md-10 col-sm-10">
              <h1 class="Digital text-center  mt-lg-5 mt-md-5 mt-3">Our Digital Marketing Projects</h1>
            </div>
                

            <div class="col-lg-1 col-md-1 col-sm-1  mt-lg-5 mt-md-5 mt-sm-5 mt-0">
                <div class="yellow-border mt-lg-5 mt-md-5 mt-3"></div>
            </div>
          </div>



        {/* last section */}

        {getdigitalproject?.map((data, index) => (
          <div
            className={
              index % 2 === 0 ? "row ms-0 me-0  mt-5" : "row ms-0 me-0"
            }
          >
            {index % 2 === 0 ? (
              <>
                <div className=" col-md-6 text-center order-md-1 order-2">
                  <div className="Circle">
                    <div className="nutrition">
                      <img
                        src={imageUrl + data?.backimage}
                        className="nutri"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6 text-center order-md-2 order-1">
                  <h1 className="profcyma mt-5">{data?.title}</h1>
                </div>
              </>
            ) : (
              <>
                <div className="col-md-6 text-center">
                  <h1 className="profcyma mt-5">{data?.title}</h1>
                </div>
                <div className="col-md-6 text-center">
                  <div className="Circle">
                    <div className="nutrition">
                      <img
                        src={imageUrl + data?.backimage}
                        className="nutri"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        ))}


      </section >
    </>
  );
};
export default DigitalContent;
