import React, { useContext } from "react";
import { useState } from "react";
import "./ECommerceBanner.css";
// import pc from '../video/ecommerce.mp4';
import { useEffect, useRef } from "react";
import LazyLoad from "react-lazyload";
import { Context } from '../../utils/context.js'

const ECommerceBanner = () => {

  const { getData, imageUrl } = useContext(Context);
  const [getecommercebanner, setEcommerceBanner] = useState();
  const [isActive, setIsActive] = useState(false);

  const getbanner = async () => {
    const res = await getData(`/globalbanner?bannertype=e-commercedevelopment`);

    if (res?.success) {
      setEcommerceBanner(res.data.list)
    }
    else {
      console.error('Error fetching  data:', res?.error);
    }
  }

  useEffect(() => {
    getbanner();
  }, []);

  return (

    <>
      <section className="ServiceBanner  about-banner-main">
        <div className="container-fluid ">
          <div className="row ">
          <div className="about-banner p-0">
            {getecommercebanner?.map((data) => (
              <div className="col-md-12 p-0">
                {data?.mediatype === 'Video' ? (
                  <LazyLoad offset={100} once>
                    <video
                      src={imageUrl + data?.video}
                      muted
                      autoPlay
                      loop
                      className="d-block video-class-about"
                    />
                  </LazyLoad>
                ) : (
                  <img
                    key={data?.id} src={imageUrl + data?.video}
                    className="d-block video-class-about"
                    style={{ width: '100%', height: 'auto' }}
                  />
                )}
                
                <div className="content">
                  <h1 className="website">
                    {data?.title}
                  </h1>
                  <p className="buisness">
                    {data?.subtitle}
                  </p>
                </div>

                <div class="overlay"></div>
              </div>
            ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ECommerceBanner;
