import React, { useEffect } from 'react';
import CaseStudyContent from './CaseStudyContent/CaseStudyContent';
import CaseStudyBanner from './CaseStudyBanner/CaseStudyBanner';
import Popup from './Popup/Popup.js'
const CaseStudies = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
    return (
        <div>
        <CaseStudyBanner/>
        <CaseStudyContent/>
        <Popup />
        </div>
         
         )
     }
     
     export default CaseStudies;