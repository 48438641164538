import React, { useEffect, useRef, useState } from "react";
import '../HomeBanner/HomeBanner.css';
import Arrow from '../../AboutUs/Popup/animation/Arrow.js'
import { Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import Typewriter from "typewriter-effect";
import "swiper/css";
// import home from './image/home.mp4'
import "swiper/css/pagination";
import 'swiper/css/autoplay';

import { useContext } from "react";
import { Context } from '../../utils/context.js';


const HomeBanner = () => {
    //const vidRef = useRef();

    const { getData, postData, imageUrl } = useContext(Context);
    const [getbannerData, setBannerdata] = useState();

    const getBanner = async () => {

        const res = await getData(`/globalbanner?bannertype=home`);

        if (res?.success) {

            setBannerdata(res.data.list);

        }
        else {
            console.error('Error fetching  data:', res?.error);
        }
    }
    useEffect(() => {

        getBanner();

    }, []);



    const scrollToTop = () => window.scrollTo({ top: 1500, behavior: "smooth" });

    return (
        <>
            <section className="banner-1">
                <div className='container-fluid p-0 '>
                    <div className="row me-0 ms-0">
                        {getbannerData?.map((data) => (
                            <div className="carousel-item banner-slider active p-0">
                                <div>

                                    {data?.mediatype === 'Video' ? (
                                        <video
                                            key={data?.id} src={imageUrl + data?.video}
                                            muted
                                            autoPlay
                                            loop
                                            className="d-block w-100 video-class-grid"
                                            height={500}
                                        />
                                    ) : (
                                        <img
                                            key={data?.id} src={imageUrl + data?.video}
                                            className="d-block w-100 video-class-grid"

                                        />
                                    )}
                                    <div className="img-overlay col-lg-6 col-md-6 col-sm-12 col-12">
                                        <div className="text-holder-home">
                                            <h2 className='mb-3'>{data?.title}</h2>
                                            <div className="overlay-text">
                                                <p className='profcyma-is p-2 me-2'>Profcyma is</p>
                                                <Typewriter
                                                    onInit={(typewriter) => {
                                                        typewriter
                                                            .typeString(data?.subtitle)
                                                            .pauseFor(1000)
                                                            .deleteAll()
                                                            .typeString(data?.subtitle)
                                                            .start();
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="slide-down-div">
                        <button onClick={scrollToTop} type="button" className="btn topdown">
                            <div className="row mt-1">
                                <div className="col-md-12">
                                    <Arrow />
                                </div>
                                <div className="col-md-12">
                                    <p className="slide">Slide Down</p>
                                </div>
                            </div>
                        </button>
                    </div>
                </div>
            </section>
        </>
    )
}

export default HomeBanner;