import React from 'react'
import './developmentjson.css';
import Lottie from 'react-lottie';
import * as animationData from './ux-designer.json';
const Developmentjson = () => {

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    return (
        <>
            <section className='main'>
                <div className='isometric-development mx-auto'>
                    <Lottie className=""
                        options={defaultOptions}
                    // height={60}
                    // width={60}     
                    />
                </div>
            </section>
        </>
    )
}

export default Developmentjson