import React from 'react'
import Lottie from 'react-lottie';
import * as animationData from '../animation/bg.json';
const Bg = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    return (
        <>
            <Lottie
                options={defaultOptions}
                height={700}
                width={1200}
            />
        </>
    )
}

export default Bg