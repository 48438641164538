// import React from 'react'
import React, { useEffect,useContext,useState } from 'react';
import { LineAnimation } from "../LineAinimation/LineAnimation";
import './Gallery.css';
import BlogBanner from '../Blog/BlogBanner/BlogBanner';
import { Context } from '../utils/context.js';



import AOS from "aos";
import "aos/dist/aos.css";

export const Gallery = () => {
   //
   const { getData, imageUrl } = useContext(Context);
   const [getblogdata, setBlogData] = useState();

   const getblog = async () => {

       const res = await getData(`/globalbanner?bannertype=gallery`);

       if (res?.success) {
           setBlogData(res.data.list);
       }
       else {
           console.error('Error fetching  data:', res?.error);
       }
   }

  

//

    useEffect(() => {
        window.scrollTo(0, 0);
        getblog();
    }, []);



    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);


    AOS.init({ disable: 'mobile' });



    return (
        <>
            <div className='container-fluid p-0'>
                
                {getblogdata?.map((data) => (
                    
                <BlogBanner homebanner={imageUrl + data?.video} mediatype ={data?.mediatype} blogbannertitle={data?.title} blogbannersubtitle={data?.subtitle} />
                ))}
               

                <section className='our-gallery-main'>
                <LineAnimation />
                    <div className='container'>
                        <div class="title-div">
                            <h1 className= 'title'>Our Gallery</h1>
                            <div className='before-bd'>
                            </div>
                            <div className='after-bd'>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-md-9 mx-auto'>
                                <div className='first-sec'>
                                    <div className='row'>
                                        <div className='first-img col-6'>
                                            <img data-aos="fade-right" data-aos-offset="300" data-aos-easing="ease-in-sine" className='img1' src={process.env.PUBLIC_URL + "assets/Images/gal1.png"} alt="gallery" />
                                        </div>
                                        <div className='second-img col-6'>
                                            <img data-aos="fade-left" data-aos-offset="300" data-aos-easing="ease-in-sine" className='img2' src={process.env.PUBLIC_URL + "assets/Images/gal2.png"} alt="gallery" />
                                            <h2 className='the-art'>THE PUBLIC ART</h2>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='second-sec'>
                            <div className='title-div'>
                                <h1 className='title'>Title</h1>
                                <p className='subtext'>We are experts in custom e-commerce development services to accelerate the e-commerce platform’s sales. Our e-commerce development team uses in-depth research and a data-driven approach to cover each and every aspect of the e-commerce website, with the use of modern techniques. which results in the creation of a customer-centric application that accelerates sales of a business and gives maximum ROI.</p>
                            </div>
                        </div>


                        <div className='third-sec'>
                            <div className='title-div'>
                                <h1 className='title'>Images</h1>
                            </div>

                            <div className='row mt-5'>
                                <div className='col-md-3'>
                                    <div className='first-div'>
                                        <img data-aos="fade-up" data-aos-duration="3000" className='gal-img' src={process.env.PUBLIC_URL + "assets/Images/gal3.png"} alt="images" />
                                    </div>
                                </div>
                                <div className='col-md-3'>
                                    <div className='second-div'>
                                        <img data-aos="fade-up" data-aos-duration="3000" className='gal-img' src={process.env.PUBLIC_URL + "assets/Images/gal4.png"} alt="images" />
                                    </div>
                                </div>
                                <div className='col-md-3'>
                                    <div className='first-div'>
                                        <img data-aos="fade-up" data-aos-duration="3000" className='gal-img' src={process.env.PUBLIC_URL + "assets/Images/gal5.png"} alt="images" />
                                    </div>
                                </div>
                                <div className='col-md-3'>
                                    <div className='second-div'>
                                        <img data-aos="fade-up" data-aos-duration="3000" className='gal-img' src={process.env.PUBLIC_URL + "assets/Images/gal6.png"} alt="images" />
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className='fourth-sec'>
                            <div className='row'>
                                <div className='col-sm-8 ms-auto'>
                                    <div className=' bag-box'></div>
                                </div>
                            </div>
                            <div className='overlay-rows-images'>
                                <div className='row'>
                                    <div className='col-md-6 p-0'>
                                        <div className='row'>
                                            <div className='col-6 p-0' data-aos="fade-down-right" data-aos-duration="3000">
                                                <img className='flex-imgs' src={process.env.PUBLIC_URL + "assets/Images/gal17.png"} alt="gallery" />
                                            </div>
                                            <div className='col-6 p-0' data-aos="fade-down-left" data-aos-duration="3000">
                                                <img className='flex-imgs' src={process.env.PUBLIC_URL + "assets/Images/gal8.png"} alt="gallery" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-6 p-0'>
                                        <div className='row'>
                                            <div className='col-6 p-0' data-aos="fade-down-right" data-aos-duration="3000">
                                                <img className='flex-imgs' src={process.env.PUBLIC_URL + "assets/Images/gal9.png"} alt="gallery" />
                                            </div>
                                            <div className='col-6 p-0' data-aos="fade-down-left" data-aos-duration="3000">
                                                <img className='flex-imgs' src={process.env.PUBLIC_URL + "assets/Images/gal10.png"} alt="gallery" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className='fifth-sec'>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <div className='sec-1'>
                                        <div className='row'>
                                            <div className='col-sm-8'>
                                                <div className='title-div'>
                                                    <h1 className='title'>Title</h1>
                                                    <p className='content'>We are experts in custom e-commerce development services to accelerate the e-commerce platform’s sales. Our e-commerce development team uses in-depth research and a data-driven approach to cover each and every aspect of the e-commerce website, with the use of modern techniques. which results in the creation of a customer-centric application that accelerates sales of a business and gives maximum ROI.</p>
                                                </div>
                                            </div>
                                            <div className='col-sm-4'>
                                                <div className='img-div' data-aos="fade-down-left" data-aos-duration="3000">
                                                    <img className='abc' src={process.env.PUBLIC_URL + "assets/Images/gal11.png"} alt="gallery" />
                                                </div>
                                                <div className='img-div' data-aos="fade-down-right" data-aos-duration="3000">
                                                    <img className='abc' src={process.env.PUBLIC_URL + "assets/Images/gal12.png"} alt="gallery" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-6 sec-2'>
                                    <div className='row'>
                                        <div className='col-md-6'>
                                            <div className='img-div' data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-delay="1000" data-aos-duration="2000">
                                                <img className='imag' src={process.env.PUBLIC_URL + "assets/Images/gal13.png"} alt="gallery" />
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className='img-div' data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-delay="1200" data-aos-duration="2000">
                                                <img className='imag' src={process.env.PUBLIC_URL + "assets/Images/gal14.png"} alt="gallery" />
                                            </div>
                                        </div>
                                        <div className='col-md-6' data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-delay="1400" data-aos-duration="2000">
                                            <div className='img-div'>
                                                <img className='imag' src={process.env.PUBLIC_URL + "assets/Images/gal15.png"} alt="gallery" />
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className='img-div' data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-delay="1600" data-aos-duration="2000">
                                                <img className='imag' src={process.env.PUBLIC_URL + "assets/Images/gal16.png"} alt="gallery" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className='sixth-sec'>
                        <img className='ban-img' src={process.env.PUBLIC_URL + "assets/Images/gal-banner1.png"} alt="banner" />
                        <div className='overlay-black'>
                        </div>
                    </div>

                    <div className='container'>
                        <div className='seventh-sec'>
                            <div className='title-siv'>
                                <h1 className='title'>Images</h1>
                            </div>
                            <div className='row mt-5'>
                                <div className='col-md-3 p-0'>
                                    <div className='img-div' data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-delay="1400" data-aos-duration="2500">
                                        <img className='iamgse' src={process.env.PUBLIC_URL + "assets/Images/gal17.png"} alt="images" />
                                    </div>
                                </div>
                                <div className='col-md-3 p-0'>
                                    <div className='img-div' data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-delay="1600" data-aos-duration="2500">
                                        <img className='iamgse' src={process.env.PUBLIC_URL + "assets/Images/gal19.png"} alt="images" />
                                    </div>
                                </div>
                                <div className='col-md-3 p-0'>
                                    <div className='img-div' data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-delay="1800" data-aos-duration="2500">
                                        <img className='iamgse' src={process.env.PUBLIC_URL + "assets/Images/gal18.png"} alt="images" />
                                    </div>
                                </div>
                                <div className='col-md-3 p-0'>
                                    <div className='img-div' data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-delay="2000" data-aos-duration="2500">
                                        <img className='iamgse' src={process.env.PUBLIC_URL + "assets/Images/gal8.png"} alt="images" />
                                    </div>
                                </div>

                            </div>

                        </div>


                        <div className='eighth-sec'>
                            <div className='row'>
                                <div className='col-md-7'>
                                    <div className='sec-1'>
                                        <div className='back-bg-box'>
                                            <img className='over-img' src={process.env.PUBLIC_URL + "assets/Images/gal19.png"} alt="images" />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-5 my-auto'>
                                    <div className='sec-2'>
                                        <div className='title-div'>
                                            <h1 className='title'>Title</h1>
                                            <p className='subtext'>We are experts in custom e-commerce development services to accelerate the e-commerce platform’s sales. Our e-commerce development team uses in-depth research and a data-driven approach to cover each and every aspect of the e-commerce website, with the use of modern techniques. which results in the creation of a customer-centric application that accelerates sales of a business and gives maximum ROI.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='row topspace'>
                                <div className='col-md-6'>
                                    <div className='sec1-div'>
                                        <img src={process.env.PUBLIC_URL + "assets/Images/gal20.png"} className='imggg' />
                                        <p className='content'>We are experts in custom e-commerce development services to accelerate the e-commerce platform’s sales. Our e-commerce development team uses in-depth research and a data-driven approach to cover each and every aspect of the e-commerce website, with the use of modern techniques. which results in the creation of a customer-centric application that accelerates sales of a business and gives maximum ROI.</p>
                                    </div>
                                </div>
                                <div className='col-md-6'>
                                    <div className='sec2-div'>
                                        <img src={process.env.PUBLIC_URL + "assets/Images/gal21.png"} className='imggg' />
                                        <p className='content'>We are experts in custom e-commerce development services to accelerate the e-commerce platform’s sales. Our e-commerce development team uses in-depth research and a data-driven approach to cover each and every aspect of the e-commerce website, with the use of modern techniques. which results in the creation of a customer-centric application that accelerates sales of a business and gives maximum ROI.</p>


                                        <img src={process.env.PUBLIC_URL + "assets/Images/gal22.png"} className='imggg' />
                                        <p className='content'>We are experts in custom e-commerce development services to accelerate the e-commerce platform’s sales. Our e-commerce development team uses in-depth research and a data-driven approach to cover each and every aspect of the e-commerce website, with the use of modern techniques. which results in the creation of a customer-centric application that accelerates sales of a business and gives maximum ROI.</p>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </section>


            </div>


        </>
    )
}

export default Gallery;