import React from "react";
import "../BrandProject/BrandProject.css";
import pimg1 from "../image/pimg1.png";
import pimg2 from "../image/pimg2.png";
import pimg3 from "../image/pimg3.png";
import scale from "../image/scales.png";
import bulb from "../image/g7032.png";
// import {  } from 'swiper';
// import 'swiper.css';
import "swiper/swiper-bundle.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
const BrandProject = () => {
  return (
    <>
      <section className="Brand-Project">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12   mt-lg-5 mt-md-5">
              <div className="text-holder-project-brand ">
                <h3 className="mt-5">
                  BRAND AWARENESS CREATES AN ASSOCIATION.
                </h3>
                <p className="ms-3">
                  Speaking in brand-less terms, It associates actions and
                  products with particular brands, subconsciously encouraging us
                  to replace common words with branded terms. And before you
                  know it, simple paper cuts or picnics are doing the marketing
                  for us.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-12  mt-lg-5 mt-md-5 mt-5">
              <div className="card mt-md-5">
                <div className="card-img-top">
                  <img
                    src={bulb}
                    className="project-img-1 mt-5 text-center"
                    alt="..."
                  />
                </div>
              </div>
            </div>
            <div className=" col-lg-4 col-md-12 order-lg-0 order-md-2 order-2 mt-lg-5 mt-md-5">
              <div className="card mt-5">
                <div className="card-img-top">
                  <img
                    src={scale}
                    className="project-img-1 mt-5 text-center"
                    alt="..."
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-8 col-md-12 order-lg-0 order-md-1 order-1 mt-lg-5 mt-md-5">
              <div className="text-holder-project-brand">
                <h3 className="mt-5">BRAND AWARENESS BUILDS BRAND EQUITY.</h3>
                <p className="ms-3">
                  Brand equity describes a brand’s value, which is determined by
                  consumer experiences Once a consumer is aware of a brand, they
                  start to recognize it without assistance, seek it out to make
                  a purchase, begin to prefer it over other similar brands, and
                  establish a loyalty that not only spurs on other purchases but
                  also inspires recommendations to family and friends.
                </p>
              </div>
            </div>
          </div>
        </div>
        <section className="awareness-project">
          <div className="container-md">
            <Swiper
              slidesPerView={"auto"}
              centeredSlides={true}
              spaceBetween={30}
              pagination={{
                clickable: false,
              }}
              modules={[Pagination, Autoplay]} // Include Pagination module here
              autoplay={{ delay: 3000, disableOnInteraction: false, loop: true }}
              className="mySwiper"
            >
              <SwiperSlide>
                <div className="row mt-5">
                  <div className="col-md-1 text-center ">
                    <div className="yellow-line-title mt-lg-4 mt-md-4 mt-3"></div>
                  </div>
                  <div className="col-md-10 text-center title-project mt-lg-0 mt-md-0 mt-2">
                    <h3>Our Brand Awareness Projects</h3>
                  </div>
                  <div className="col-md-1  ">
                    <div className="yellow-line-title text-center mt-lg-4 mt-md-4 mt-3"></div>
                  </div>
                  <div className=" col-lg-4 col-md-4 mt-5 text-center  mb-5">
                    <div className="circle">
                      <img src={pimg1} className="project-img-2npm" alt="..." />
                    </div>

                    <div className="type-name1">
                      <h2>Vidarbha Chilli Powder</h2>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 text-center top-spacing  mb-5 mt-5">
                    <div className="circle1 mt-5">
                      <img src={pimg2} className="project-img-2" alt="..." />
                    </div>
                    <div className="type-name">
                      <h2 className="line">KMC Oil</h2>
                    </div>
                  </div>

                  <div className=" col-lg-4 col-md-4 text-center  mb-5 mt-5">
                    <div className="circle2">
                      <img src={pimg3} className="project-img-2" alt="..." />
                    </div>
                    <div className="type-name2">
                      <h2>KMC Special Oil</h2>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="row mt-5">
                  <div className="col-md-1 text-center ">
                    <div className="yellow-line-title mt-lg-4 mt-md-4 mt-3"></div>
                  </div>
                  <div className="col-md-10 text-center title-project mt-lg-0 mt-md-0 mt-2">
                    <h3>Our Brand Awareness Projects</h3>
                  </div>
                  <div className="col-md-1  ">
                    <div className="yellow-line-title text-center mt-lg-4 mt-md-4 mt-3"></div>
                  </div>
                  <div className=" col-lg-4 col-md-4 mt-5 text-center  mb-5">
                    <div className="circle">
                      <img src={pimg1} className="project-img-2npm" alt="..." />
                    </div>

                    <div className="type-name1">
                      <h2>Vidarbha Chilli Powder</h2>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 text-center top-spacing  mb-5 mt-5">
                    <div className="circle1 mt-5">
                      <img src={pimg2} className="project-img-2" alt="..." />
                    </div>
                    <div className="type-name">
                      <h2 className="line">KMC Oil</h2>
                    </div>
                  </div>

                  <div className=" col-lg-4 col-md-4 text-center  mb-5 mt-5">
                    <div className="circle2">
                      <img src={pimg3} className="project-img-2" alt="..." />
                    </div>
                    <div className="type-name2">
                      <h2>KMC Special Oil</h2>
                    </div>
                  </div>
                </div>
              </SwiperSlide>

            </Swiper>
          </div>
        </section>
      </section>
    </>
  );
};

export default BrandProject;
