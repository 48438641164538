import React, { useContext, useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import '../BlogText/BlogText.css';
import img1 from '../Image/img1.png'
import img2 from '../Image/img2.png'
import img3 from '../Image/img3.png'
import img4 from '../Image/img4.png'
import img5 from '../Image/img5.png'
import img6 from '../Image/img6.png'
import img7 from '../Image/img7.png'
import img8 from '../Image/img8.png'
import img9 from '../Image/img9.png'
import { LineAnimation } from "../../LineAinimation/LineAnimation";


import { Context } from "../../utils/context";


import AOS from "aos";
import "aos/dist/aos.css";


const BlogText = () => {

    const { getData, imageUrl } = useContext(Context);
    const [getblogdata, setBlogData] = useState();

    var HtmlToReactParser = require('html-to-react').Parser;
    var htmlToReactParser = new HtmlToReactParser();

    const getblog = async () => {

        const res = await getData(`/blog`);

        if (res?.success) {
            setBlogData(res.data)
        }
    }

    useEffect(() => {
        AOS.init();
        AOS.refresh();
        getblog();
    }, []);

    return (
        <>
            <section className='Blog '>
                <LineAnimation />

                <div className='container'>
                    <div className='row'>
                        {getblogdata?.map((data) => (
                            <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 mt-5' >
                                <Link to={`/bloginner/${data?.id}`}>
                                    <div className="card" >
                                        <div className='main-img-class'>
                                            <img src={imageUrl + data?.contentimage} class="card-img-top" alt="..." />
                                        </div>
                                        <div className="card-body">
                                            <h5 className="card-title mt-2">{data?.contenttitle}</h5>
                                            <p className="card-subtitle mt-2">by profcymaglobal | Jan 21, 2023 | Blog</p>
                                            <p className="card-text mt-2"> {htmlToReactParser.parse(data?.contentdescription)}</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>

                        ))}

                       
                    </div>
                    {/* Lets get started section */}

                    <div className='col-md-12 pb-5' >
                        <div className='box-blog mt-5 text-center'>
                            <div className='title-blog '>
                                <h3>Let’s get started</h3>
                                <h2>Are you ready for a better, more productive business?</h2>
                                <h5>Stop worrying about technology problems. Focus on your business.</h5>
                                <h5>Let us provide the support you deserve.</h5>
                                <div className='mt-3'>
                                    {/* <button type='button' className='contact-companies-class ' >Contact Us Now</button> */}
                                    <button type="button" className="contact-companies-class" data-bs-toggle="offcanvas" data-bs-target="#contact_off-canvas"
                                            aria-controls="offcanvasTop"><span data-bs-toggle="collapse" data-bs-target="#navbarNav">Contact Us Now</span></button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </section >
        </>
    )
}

export default BlogText