import React from 'react';
import './App.css'
import { Route, Routes } from "react-router-dom";
import Header from './Header/Header.js';
import CaseStudies from './CaseStudies/CaseStudies';
import OurServices from './OurServices/OurServices';
import DigitalMarketing from './DigitalMarketing/DigitalMarketing';
import HomePage from './HomePage/HomePage.js';
import AboutUs from './AboutUs/AboutUs.js';
import HealthAndFitness from './HealthAndFitness/HealthAndFitness.js';
import Blog from './Blog/Blog.js'
import BlogInnerPage from './BlogInnerPage/BlogInnerPage.js';
import AppDevelopment from './AppDevelopment/AppDevelopment.js';
import BrandAwarness from './BrandAwarness/BrandAwarness.js'
import GraphicDesigning from './GraphicDesigning/GraphicDesigning.js'
import Footer from './Footer/Footer.js';
import UiuxDesign from './ui-ux-designing/UiuxDesign'
import Gallery from './Gallery/Gallery';
import ECommerce from './ECommerce/ECommerce';

import Career from './career/Career';
import SoftwareDevelopment from './SoftwareDevelopment/SoftwareDevelopment';
import AppContext from "./utils/context.js";
import Terms from './terms-condition/Terms';
import Privacy from './privacy-policy/Privacy';
import Astrology from './Astrology/Astrology.js';

// import EnquiryForm from './Contact-form/EnquiryForm.js';

// import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';


function App() {
  return (
    <>
      <AppContext>
        <Header />
        <Routes>

          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/HealthAndFitness" element={<HealthAndFitness />} />
          <Route path="/case-study" element={<CaseStudies />} />
          <Route path="/website-development" element={<OurServices />} />
          <Route path="/digital" element={<DigitalMarketing />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/bloginner/:id" element={<BlogInnerPage />} />
          <Route path="/appdevlopment" element={<AppDevelopment />} />
          <Route path="/uiuxdesign" element={<UiuxDesign />} />
          <Route path="/brand-awarness" element={<BrandAwarness />} />
          <Route path="/our-gallery" element={<Gallery />} />
          <Route path="/graphicdesigning" element={<GraphicDesigning />} />
          <Route path="/e-commerce" element={<ECommerce />} />
          <Route path="/career" element={<Career />} />
          <Route path="/software-development" element={<SoftwareDevelopment />} />
          <Route path="/terms&condition" element={<Terms />} />
          <Route path="/privacy-policy" element={<Privacy />} />
          <Route path="/astrology" element={<Astrology />} />
        </Routes>
        <Footer />

      </AppContext>


    </>
  );
}

export default App;






