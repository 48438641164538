import React from 'react'
import {useState} from "react";
import './Click.css';
import Lottie from 'react-lottie';
import * as animationData from './click.json';


function Click() {

  const [isActive, setIsActive] = useState(false);

  const handleClick = () => {

  setIsActive(!isActive);

}; 
    const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: animationData,
            rendererSettings: {
             preserveAspectRatio: "xMidYMid slice"
            }
           };
        

  return (


    <section className='main1'>
        <div className='click text-center'>
        
            <Lottie  className="me-auto"
            options={defaultOptions}
            onclick="myFunction()"
            />
              <h1 className="Heading-Commerce1 text-center">Our<br></br> E-Commerce <br></br>Projects</h1>    
        </div>
    </section>
  )
}

export default Click;
