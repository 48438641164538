import React from 'react'
import './isometric.css';
import Lottie from 'react-lottie';
import * as animationData from './isometric-ui-design.json';
const Isometric = () => {

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    return (
        <>
            <section className='main'>
                <div className='isometric mx-auto'>
                    <Lottie className=""
                        options={defaultOptions}
                    // height={60}
                    // width={60}     
                    />
                </div>
            </section>
        </>
    )
}

export default Isometric