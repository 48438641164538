import React, { useState, useEffect } from 'react';

import CountUp from 'react-countup';
import ScrollTrigger from 'react-scroll-trigger';
import '../OurProject/ourproject.css';
import { LineAnimation } from "../../LineAinimation/LineAnimation";

import AOS from "aos";
import "aos/dist/aos.css";

import { useContext } from "react";
import  {Context}  from "../../utils/context";

const OurProject = () => {

    const { getData, imageUrl } = useContext(Context);
    const[getProjectData ,setProjectdata] = useState();
    const [counterOn, setCounterOn] = useState(false);

    const getproject = async ()=>{

        const res = await getData(`/welove`);
        
        if(res?.success){
          
            setProjectdata(res.data);

        }else{ 
    
        }
    }
    useEffect(() => {
        AOS.init();
        AOS.refresh();

        getproject();


    }, []);


    


    return (
        <>
            <section className='Our-Project'>
                <div className='container'>
                {getProjectData?.map((data) => ( 
                    <div className='row '>
                        <div className='col-lg-5 col-md-6 mt-md-5 mb-md-5'>
                            <div className='text-holder-project'>
                                <h1 data-aos="fade-up" data-aos-duration="3000">{data?.title}<br /> <br /><span className='special-font'>{data?.subtitle}</span></h1>
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-6 mt-mb-5 mb-5'>
                            <div className='row justify-content-center ' data-aos="fade-up" data-aos-duration="3000">
                                <div className=' col-lg-4 col-md-6 col-sm-12 mt-3 '>
                                    <div className='counter-class-green '>
                                        <div className='justify-content-center '>
                                            <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
                                                <h1 className='number-class'>
                                                    {counterOn && <CountUp start={0} end={data?.projects} duration={5} delay={0} />}
                                                    +
                                                </h1>
                                            </ScrollTrigger>
                                        </div>
                                        <p>Projects</p>
                                    </div>
                                </div>
                                <div className=' col-lg-4 col-md-6 col-sm-12 mt-3'>
                                    <div className='counter-class-blue'>
                                        <div className='justify-content-center'>
                                            <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
                                                <h1 className='number-class-blue'>
                                                    {counterOn && <CountUp start={0} end={data?.satisfied} duration={5} delay={0} />}
                                                    +
                                                </h1>
                                            </ScrollTrigger>
                                        </div>
                                        <p>Satisfied Clients</p>
                                    </div>
                                </div>
                                <div className=' col-lg-4 col-md-6 col-sm-12 mt-3'>
                                    <div className='counter-class-yellow'>
                                        <div className='justify-content-center'>
                                            <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
                                                <h1 className='number-class-yellow'>
                                                    {counterOn && <CountUp start={0} end={data?.ongoing} duration={5} delay={0} />}
                                                    +
                                                </h1>
                                            </ScrollTrigger>
                                        </div>
                                        <p>Ongoing Projects</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
                </div>
            </section >
        </>
    )
  
}
export default OurProject