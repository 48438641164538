import { createContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

import {
  getData,
  postData,
  Select2Data,
  // getEditData,
  // editData,
  editStatusData,
  getJobSeekerData,
  // postFormData,
  deleteData,
} from "./api";



export const Context = createContext();

const AppContext = ({ children }) => {
  const navigate = useNavigate();

  // const [signin, setSignin] = useState(false);
  // const [usertype, setUsertype] = useState("");
  // const [userdata, setUserData] = useState({});
  // const [freeKundli, setFreeKundli] = useState(true);
  const [ReactLoader, setReactLoader] = useState(true);
  // const [filterData, setFilterData] = useState();
  // const [astrologers, setAstrologer] = useState();
  // const [talkFilterURL, settalkFilterURL] = useState("/astrologerDetails");
  // const [chatFilterURL, setchatFilterURL] = useState("/astrologerDetails");
  const [recruiterData, setRecruiterData] = useState({
    first_name: null,
    last_name: null,
    email: null,
    emailVerify: false,
    gender: null,
    dob: null,
    contact_no: null,
    mobileVerify: false,
    alternate: null,
    current_address: null,
    living_city_id: null,
    nationality_id: null,
    policy: false,
  });
  const [jobSeekerData, setJobSeekerData] = useState();
  const [seekerGender, setSeekerGender] = useState(null);
  // {
  //   first_name : null,
  //   last_name : null,
  //   email : null,
  //   emailVerify : false,
  //   gender : null,
  //   dob : null,
  //   contact_no : null,
  //   mobileVerify : false,
  //   alternative_no : null,
  //   current_address : null,
  //   living_city_id : null,
  //   nationality_id : null,
  //   policy : false,
  //   seeker_id:"",
  // }
  const fetchSeekerData = () => {
    getJobSeekerData()
      .then((data) => {
        console.log(data, "Job Seeker Data:");
        setSeekerGender(data?.users_detail?.gender);
        setJobSeekerData(data);
        return data;
      })
      .catch((error) => {
        // Handle the error
        console.error("Error fetching job seeker data:", error);

        // Optionally, you can perform additional actions here, such as redirecting the user
        // Cookies.remove("jwtToken", { path: "/" });
        // window.location.href = "/";
      });
  };

    //  const imageUrl = "http://192.168.14.133/globalwebsite_admin/"; 
  // const imageUrl = "http://127.0.0.1:1212";
  // const imageUrl = "http://profcymaglobaladmin.profcyma.org/";
  // const imageUrl = "https://profcymaglobaladmin.profcymadev.in/";
  const imageUrl = "https://admin.profcymaglobal.com/";
  return (
    <Context.Provider
      value={{
        // minLength,
        // maxLength,
        getData,
        postData,
        recruiterData,
        setRecruiterData,
        jobSeekerData,
        setJobSeekerData,
        getJobSeekerData,
        fetchSeekerData,
        // postFormData,
        // getEditData,
        // editData,
        editStatusData,
        seekerGender,
        setSeekerGender,
        deleteData,
        // signin,
        // setSignin,
        // usertype,
        // setUsertype,
        // userdata,
        // setUserData,
        // // Kundli Data
        ReactLoader,
        setReactLoader,
        imageUrl,
        Select2Data,
        // freeKundli,
        // setFreeKundli,
        // filterData,
        // setFilterData,
        // astrologers,
        // setAstrologer,
        // talkFilterURL,
        // settalkFilterURL,
        // chatFilterURL,
        // setchatFilterURL,
        // ErrorNotify,
      }}
    >
      {children}
    </Context.Provider>
  );
};


export default AppContext 