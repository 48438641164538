import React from 'react'
import './Fast.css';
import Lottie from 'react-lottie';



function Fast({ animationData }) {


  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (


    <section className='main'>
      <div className='success text-center'>
        <Lottie className="me-auto"
          options={defaultOptions}
        // height={60}
        // width={60}     
        />
      </div>
    </section>
  )
}

export default Fast;
