import React, { useContext, useState, useEffect } from 'react'
import '../Graphic/Graphic.css'
import img1 from '../image/img1.png'
import img2 from '../image/img2.png'
import graphic from '../image/graphic.png'
import tringle from '../image/Icon material-play-arrow.png'
import First from '../animation/js/First'
import Second from '../animation/js/Second'
import Third from '../animation/js/Third'
import { LineAnimation } from "../../LineAinimation/LineAnimation";
import { Context } from '../../utils/context.js'
import illusion from '../image/illusion.png'
const Graphic = () => {

    const { getData, imageUrl } = useContext(Context);
    var HtmlToReactParser = require('html-to-react').Parser;
    var htmlToReactParser = new HtmlToReactParser();


    const [getgraphiccompany, setGraphicCompany] = useState();
    const getcompany = async () => {
        const res = await getData(`/graphicdesigningcompany`);

        if (res?.success) {
            setGraphicCompany(res.data);
        }
        else {
            console.error('Error fetching  data:', res?.error);
        }
    }

    const [getgraphiciilustration, setGraphicIilustration] = useState();
    const getiilustration = async () => {
        const res = await getData(`/graphicdesignillustration`);

        if (res?.success) {
            setGraphicIilustration(res.data);
        }
        else {
            console.error('Error fetching  data:', res?.error);
        }
    }

    const [getgraphicmarketing, setGraphicMarketing] = useState();
    const getmarketing = async () => {
        const res = await getData(`/graphicdesignmarketing`);

        if (res?.success) {
            setGraphicMarketing(res?.data);
        }
        else {
            console.error('Error fetching  data:', res?.error);
        }
    }


    const [getgraphicdiffrenttype, setGraphicDiffrentType] = useState();
    const gettype = async () => {
        const res = await getData(`/graphicdesigndifferenttype`);

        if (res?.success) {
            setGraphicDiffrentType(res?.data);
        }
        else {
            console.error('Error fetching  data:', res?.error);
        }
    }

    useEffect(() => {

        getcompany();
        getiilustration();
        getmarketing();
        gettype();

    }, []);


    return (
        <>
            <section className='Brand '>
                <LineAnimation />
                <div className='container'>
                    <div className='row'>
                        <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 mt-5'>
                            <div class="card graphic-card mt-md-5">
                                <div className='card-img-top'>
                                    <First />
                                </div>
                                <div class="card-body first-card-body">
                                    <h5 class="card-title text-center ">CUSTOMIZATION</h5>
                                    <p class="card-text text-center">DESIGN A/C TO USER <br />REQUIREMENTS</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 mt-5'>
                            <div class=" card graphic-card mt-md-5">
                                <div className='card-img-top'>
                                    <Second />
                                </div>
                                <div class="card-body first-card-body">
                                    <h5 class="card-title text-center">PERFORMANCE</h5>
                                    <p class="card-text text-center">BETTER PERFORMANCE <br /> FOR THE USER</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-xxl-4 col-xl-4 col-lg-4 col-md-6 mt-5'>
                            <div class="card graphic-card mt-md-5">
                                <div className='card-img-top'>
                                    <Third />
                                </div>
                                <div class="card-body first-card-body">
                                    <h5 class="card-title text-center">FLEXIBILITY</h5>
                                    <p class="card-text text-center">USER GETS FLEXIBILITY IN <br /> DESIGN</p>
                                </div>
                            </div>
                        </div>
                        {/* 2nd section */}
                        <div className='col-md-12 text-center mt-5'>
                            <h2 className='title-name-brand mt-md-5'>Graphic Designing Company In Pune</h2>
                        </div>

                        {getgraphiccompany?.map((data) => (
                            <>
                                <div className=' col-md-6 mt-5 mx-auto'>
                                    <div className='text-holder-brand mt-md-5  mt-0'>
                                        <h3>{data?.title}<span>  {data?.subtitle}</span></h3>
                                        <p className='mt-5'>{htmlToReactParser.parse(data?.description)}</p>
                                    </div>
                                </div>
                                <div className='  col-md-6 mt-5 mb-md-5'>
                                    <div className='text-center'>
                                        <img src={imageUrl + data?.image} className='brand-img1' alt='...' />
                                    </div>
                                </div>
                            </>
                        ))}

                        {getgraphiciilustration?.map((item) => (
                            <div className='illusion mt-5 pt-5'>
                                <div className='main-background-img'>
                                    <img src={imageUrl + item?.image} className='illusion-img1 mb-md-5' alt='...' />
                                    <div className='text-illusion'>
                                        <div className='col-xxl-8 col-xl-8 col-lg-8 col-md-6 mt-md-5 ms-5 pt-md-5'>
                                            <div className=' mt-md-5'>
                                                <h3 className='illustration-title mt-md-5'>{item?.title}<br /><span className='simple'>{item?.subtitle}</span></h3>
                                                <p className='content mt-5 '>{htmlToReactParser.parse(item?.description)}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                     
                            {getgraphicmarketing?.map((item) => (
                                <>
                                    <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 mt-5'>
                                        <div className='text-holder-brand mt-5'>
                                            <h3 className=''>{item?.title} <br /><span className='mt-3'>{item?.subtitle}</span></h3>
                                            <p className='mt-4'>{htmlToReactParser.parse(item?.description)}</p>
                                        </div>
                                    </div>
                                    <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 mt-5'>
                                        <div className='text-center'>
                                            <img src={imageUrl + item?.image} className='brand-img1 mb-md-5' alt='...' />
                                        </div>
                                    </div>
                                </>
                            ))}
                      
                    </div>
                    {/* 3rd section */}
                    <div className='graphic-type '>
                        {getgraphicdiffrenttype?.map((item) => (
                            <div className='row'>
                                <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 mt-5'>
                                    <img src={imageUrl + item?.image} className='graphic-img' alt='...' />
                                </div>
                                <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 mt-5'>
                                    <div className='text-holder-graphic-design'>
                                        <h3>{item?.title}</h3>
                                        <h2>{item?.subtitle}</h2>
                                        <ul className='mt-3'>{htmlToReactParser.parse(item?.description)}</ul>

                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section >
        </>
    )
}

export default Graphic