import React, { useState, useEffect } from "react";
import "./FooterGlobal.css";
import { Link } from "react-router-dom";
import logoimg from "../image/logoimg.png";
import call from "../image/call.png";
import email from "../image/email.png";
import facebook from "../image/facebook.png";
import instagram from "../image/instagram.png";
import linkdin from "../image/Linkdin.png";
import twitter from "../image/Twitter.png";
import Enquiry_Form from "../../Components/Contact-form/Enquiry_form";
import '../../Components/Contact-form/EnquiryForm.css'
import { LineAnimation } from "../../LineAinimation/LineAnimation";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// import Enquiry_Form from "../../Components/Contact-form/Enquiry_form";



const FooterGlobal = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <section className="Footer">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-11 mx-auto">
                            <div className="row mt-5">
                                <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <div className="main-text-class">
                                        <div className="">
                                            <img src={logoimg} className="logo-img-footer" />
                                        </div>
                                        <div className="text-holder-profycma">
                                            <p className="mt-3 ">
                                                Profcyma Global is a Pune-based IT <br />
                                                company with enthusiastic and skilled <br /> team
                                                members. Our goals are result-
                                                <br />
                                                oriented and client satisfaction is our top- <br />
                                                most priority.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <div className="main-list-class">
                                        <p className="footer-title">Quick Links</p>
                                        <div className="d-flex">
                                            <div className="social-list-class">
                                                <ul className="p-0">
                                                    <li>
                                                        <Link to="/" className="footer-link">
                                                            Home
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/about" className="footer-link">
                                                            About Company
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/website-development" className="footer-link">IT Solutions</Link>
                                                        
                                                    </li>
                                                    <li>
                                                        <Link to="/Blog" className="footer-link">
                                                            Blogs
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="career" className="footer-link">Careers</Link>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="social-list-class">
                                                <ul>
                                                    <li>
                                                        <Link to="/case-study" className="footer-link"> Case Studies</Link>
                                                    </li>
                                                    <li>                                                        
                                                        <Link to="/our-gallery" className="footer-link">Gallery</Link>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="footer-link"
                                                            href=" "
                                                            data-bs-toggle="offcanvas"
                                                            data-bs-target="#contact_off-canvas"
                                                            aria-controls="offcanvasTop"
                                                        >
                                                            {" "}
                                                            Contact
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <Link to="/terms&condition" class="footer-link" href=" ">
                                                            Terms & Condition
                                                        </Link>
                                                    </li>

                                                    <li>
                                                        <Link to="/privacy-policy" class="footer-link">
                                                            
                                                                Privacy Policy
                                                          
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <div className="main-contact-class">
                                        <p className="footer-title">Our Main Office</p>
                                        <div className="text-holder-profycma">
                                            <p><strong>किरण दगडे पाटील / Kiran Dattatray Dagade </strong>, <br></br>
                                                2nd Floor, Office No 3, M No. 734, Opp High Class Society, Pashan Rd, Near Suryautta College, Patil Nagar, Bavdhan, Pune, Maharashtra - 411 021
                                            </p>
                                            <p>
                                                {" "}
                                                <img
                                                    src={call}
                                                    className="icon-img-footer me-3 p-2"
                                                />
                                                +91 960 775 5557 / 940 570 9042
                                            </p>
                                            <p>
                                                {" "}
                                                <img
                                                    src={email}
                                                    className="icon-img-footer me-3 p-2"
                                                />
                                                info@profcymaglobal.com
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xxl-3 col-xl-3 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <div className="two-add-section">
                                        <p className="footer-title">Our Global Presence</p>
                                        <div className="row">
                                            <div className="col-12">
                                                <p className="country-name"><FontAwesomeIcon className="cont-ffafa" icon="fa-solid fa-earth-americas"/>Kampala, Uganda</p>
                                                <p className="person-name"><FontAwesomeIcon className="cont-ffafa" icon="fa-solid fa-user"/>Sanjivani Gaikwad</p>
                                                <p className="person-name"><FontAwesomeIcon className="cont-ffafa" icon="fa-solid fa-phone"/>+256 755 792587</p>
                                                <p className="person-name"><FontAwesomeIcon className="cont-ffafa" icon="fa-solid fa-envelope"/>sanjivani.gaikwad@profcyma.com</p>
                                            </div>
                                          
                                        </div>
                                    </div>
                                </div>


                                <div className="footer-line mt-4 mb-5">
                                    <hr />
                                </div>
                            </div>
                            <div className="row justify-content-between">
                                <div className="col-md-8">
                                    <p className="last-line-footer">
                                        © 2024 All Rights Reserved Terms Of Use | Privacy Policy
                                        Powered by Profcyma
                                    </p>
                                </div>
                                <div className="col-md-4">
                                    <div className="d-flex mb-5">
                                        <Link to="https://www.facebook.com/profcymaglobal"><img src={facebook} className="logo-img-social me-2" /></Link>

                                        <Link to="https://www.instagram.com/profcymaglobal/"><img src={instagram} className="logo-img-social me-2" /></Link>

                                        <Link to="https://www.linkedin.com/company/profcyma-global-solutions/"><img src={linkdin} className="logo-img-social me-2" /></Link>

                                        <Link to="https://twitter.com/Profcymaglobal"><img src={twitter} className="logo-img-social me-2" /></Link>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>





            </section>


            {/* <Enquiry_Form /> */}



        </>
    );
};

export default FooterGlobal;
