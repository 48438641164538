// import React from 'react'
import React, { useEffect, useState } from 'react';

import AboutBanner from './AboutBanner/AboutBanner.js'
import AboutCompany from './AboutCompany/AboutCompany.js'
import OurProject from './OurProject/OurProject.js'
import Investor from './Investor/Investor.js'
import Popup from './Popup/Popup.js'
import AOS from "aos";
import "aos/dist/aos.css";


import { useContext } from "react";
import { Context } from '../utils/context.js';


const AboutUs = () => {

    const { getData, imageUrl } = useContext(Context);
    const [getwhoweData, setWhoWedata] = useState();

    const getWhowe = async () => {

        const res = await getData(`/globalbanner?bannertype=whoweare`);

        if (res?.success) {
            setWhoWedata(res.data.list);
        } else {
            console.error('Error fetching  data:', res?.error);
        }
    }


    useEffect(() => {
        window.scrollTo(0, 0);

        getWhowe();

    }, []);
    AOS.init({ disable: 'mobile' });



    return (
        <div> {getwhoweData?.map((data) => (
            <AboutBanner bannertitle={data?.title} bannersubtitle={data?.subtitle} aboutbanner={imageUrl + data?.video} mediatype={data?.mediatype} />))}
            <AboutCompany />
            <OurProject />
            <Investor />
            <Popup />
        </div>
    )
}

export default AboutUs;