// import React from 'react'
import React, { useEffect, useContext, useState } from 'react';
import BlogBanner from '../Blog/BlogBanner/BlogBanner.js'
import Graphic from './Graphic/Graphic.js'
import GraphicAdvantage from './GraphicAdvantage/GraphicAdvantage.js'
import GraphicProject from './GraphicProject/GraphicProject.js'
import GraphicSubProject from './GraphicSubProject/GraphicSubProject.js'
import Popup from '../AboutUs/Popup/Popup.js'
import GraphicDesigningBanner from './GraphicDesigningBanner.js'
import { Context } from '../utils/context.js'


const GraphicDesigning = () => {

    const { getData, imageUrl } = useContext(Context);
    const [getgraohicbanner, setGrapgicbanner] = useState();


    const getgraphic = async () => {

        const res = await getData(`/globalbanner?bannertype=graphicdesigning`);

        if (res?.success) {

            setGrapgicbanner(res.data.list);
        }
        else {
            console.error('Error fetching  data:', res?.error);
        }

    }

    useEffect(() => {
        window.scrollTo(0, 0);
        getgraphic();
    }, []);

    return (
        <div>{getgraohicbanner?.map((item) => (
            <GraphicDesigningBanner graphictitle={item?.title} graphicsubtitle={item?.subtitle} graphicvideo={imageUrl + item?.video} mediatype={item?.mediatype} />))}
            <Graphic />
            <GraphicAdvantage />
            <GraphicProject />
            <GraphicSubProject />
            <Popup />
        </div>
    )
}

export default GraphicDesigning;