import React, { useContext, useEffect, useRef, useState } from 'react'
import './uibanner.css';
// import aboutbanner from '../image/ui-video.mp4'
import LazyLoad from 'react-lazyload';
import { Context } from '../../utils/context.js'

const UiBanner = (props) => {

    const { getData, imageUrl } = useContext(Context);
    const [getuibanner, setUiBanner] = useState();

    const getbanner = async () => {

        const res = await getData(`/globalbanner?bannertype=ui/uxdesign`);

        if (res?.success) {
            setUiBanner(res.data.list);
        }
        else {
            console.error('Error fetching  data:', res?.error);
        }
    }

    useEffect(() => {
        getbanner();
    }, [])


    return (
        <>
            <section className="about-banner-main">
                <div className="row ms-0 me-0">

                    <div className='col-md-12 p-0'>
                        {getuibanner?.map((data) => (
                            <div className="about-banner">
                                {data?.mediatype === 'Video' ? (
                                    <LazyLoad offset={100} once>
                                        <video
                                            src={imageUrl + data?.video}
                                            muted
                                            autoPlay
                                            loop
                                            className="d-block video-class-about"
                                        />
                                    </LazyLoad>
                                ) : (
                                    <img
                                        key={data?.id} src={imageUrl + data?.video}
                                        className="d-block video-class-about"
                                        style={{ width: '100%', height: 'auto' }}
                                    />
                                )}
                                <div className="img-overlay col-lg-6 col-md-12 ">
                                    <div className="text-holder-banner">
                                        <h2>{data?.title}</h2>
                                        <p>{data?.subtitle}</p>
                                    </div>
                                </div>

                                <div className='overlay'></div>
                            </div>
                        ))}
                    </div>

                </div>
            </section>
        </>
    )
}

export default UiBanner