import React, { useEffect } from 'react';
import ECommerceBanner from "./ECommerceBanner/ECommerceBanner.js";
import ECommerceContent from './ECommerceContent/ECommerceContent';
import Popup from '../AboutUs/Popup/Popup.js'

const ECommerce = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <ECommerceBanner/>
            <ECommerceContent/>
            <Popup />
        </div>
         
         )
     }
     
     export default ECommerce ;