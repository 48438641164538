import React from 'react'
import './Grow.css';
import Lottie from 'react-lottie';
import * as animationData from './grow.json';


function Grow() {


    const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: animationData,
            rendererSettings: {
             preserveAspectRatio: "xMidYMid slice"
            }
           };

  return (


    <section className='main'>
        <div className='girl'>
            <Lottie className="me-auto"
            options={defaultOptions}
            // height={60}
            // width={60}     
            />    
        </div>
    </section>
  )
}

export default Grow;
