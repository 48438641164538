import React from 'react'
import HeaderGlobal from './HeaderGlobal/HeaderGlobal.js';
import AOS from "aos";
import "aos/dist/aos.css";


const Header = () => {
    AOS.init({disable: 'mobile'});
    return (
        <>   
            <HeaderGlobal />
        </>

    )
}

export default Header;