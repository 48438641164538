import React, { useContext, useState, useEffect } from 'react'
import './Designingcompany.css';
import arrowimg from '../image/arrow-dropright.png'
import ListDesign from './ListDesign';
import ModalNew from './ModalNew';
import { Context } from '../../utils/context.js';
const DesigningCompany = () => {

    const { getData, imageUrl } = useContext(Context);
    var HtmlToReactParser = require('html-to-react').Parser;
    var htmlToReactParser = new HtmlToReactParser();

    const [getdesigncompany, setDesignCompany] = useState();
    const getcompany = async () => {
        const res = await getData(`/uiuxdesigncompany`);
        if (res?.success) {
                setDesignCompany(res?.data);
        }
        else {
            console.error('Error fetching  data:', res?.error);
        }
    }

    useEffect(() => {
        getcompany();
    }, [])


    return (
        <>
            <section className='design-company'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='heading-holder text-center'>
                                <h4>UI/UX Designing Company In Pune</h4>
                            </div>
                            {getdesigncompany?.map((item) => (
                                <ListDesign heading={item?.title} modalId={item?.id} />

                            ))}
                            {/* <ListDesign heading='WHAT IS UI / UX DESIGNING?' modalId='desingningModal' />
                            <ListDesign heading='WE PLAN AND DEVELOP YOUR UNIQUE IDEAS' modalId='ideaModal' />
                            <ListDesign heading='THE TECHNOLOGY USED ARE' modalId='technologyModal' /> */}
                        </div>
                    </div>
                </div>
            </section>

            {getdesigncompany?.map((item) => (
                <ModalNew modalId={item?.id} modalTitle={htmlToReactParser.parse(item?.subtitle)} modalclassName='technology-content ' modelImage={imageUrl + item?.image}>
                    <p>{htmlToReactParser.parse(item?.description)}</p>
                </ModalNew>
            ))}
            {/* <ModalNew modalId='desingningModal' modalTitle='WHAT IS UI/UX DESIGNING?' modalclassName="unique-content modddddddd">
                <p>Profcyma Global is committed to working with you over a range of quality services...</p>
            </ModalNew>
            <ModalNew modalId='ideaModal' modalTitle='WE PLAN AND DEVELOP YOUR UNIQUE IDEAS' modalclassName="hotal-content modddddddd">
                <p>We believe in ethical and accessible design solutions that are reliable and equal for everyone...</p>
            </ModalNew>
            <ModalNew modalId='technologyModal' modalTitle='THE TECHNOLOGY USED ARE' modalclassName="technology-content modddddddd">

                <div><img src={arrowimg} className='next-icon me-4' /><span>Adobe XD</span></div>
                <div><img src={arrowimg} className='next-icon me-4' /><span>Figma</span></div>
                <div><img src={arrowimg} className='next-icon me-4' /><span>Sketch</span></div>

            </ModalNew> */}





        </>
    )
}



export default DesigningCompany