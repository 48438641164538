import React from 'react'
import ScrollIndicator from './ScrollIndicator/ScrollIndicator.js'
import ScrollToTop from './ScrollTop/ScrollButton.js'


const ScrollTopBottom = () => {
    return (
        <div>
            <ScrollIndicator/>
            <ScrollToTop />           
        </div>
    )
} 

export default ScrollTopBottom;