import React, { useContext, useState, useEffect } from 'react'
import '../GraphicAdvantage/GraphicAdvantage.css'
import img3 from '../image/img3.png'
import Sixth from '../animation/js/Sixth';
import Seven from '../animation/js/Seven';
import Eight from '../animation/js/Eight';
import { LineAnimation } from "../../LineAinimation/LineAnimation";
import { Context } from '../../utils/context.js'


const GraphicAdvantage = () => {

    const { getData, imageUrl } = useContext(Context);
    var HtmlToReactParser = require('html-to-react').Parser;
    var htmlToReactParser = new HtmlToReactParser();


    const [getgraphicdesign, setGraphicDesign] = useState();
    const getdesign = async () => {
        const res = await getData(`/graphicdesignweuse`);

        if (res?.success) {
            setGraphicDesign(res.data);
        }
        else {
            console.error('Error fetching  data:', res?.error);
        }
    }


    const [getgraphicbenefits, setGraphicBenefits] = useState();
    const getbenefits = async () => {
        const res = await getData(`/graphicdesignbenefit`);

        if (res?.success) {
            setGraphicBenefits(res.data);
        }
        else {
            console.error('Error fetching  data:', res?.error);
        }
    }

    useEffect(() => {

        getdesign();
        getbenefits();

    }, []);


    return (
        <>
            <section className='Graphicadvantage'>
                <LineAnimation />
                <div className='container'>
                    <div className='row mt-5'>
                        <div className='col-md-1 text-center mt-md-5'>
                            <div className='yellow-line-title mt-lg-4 mt-md-4 mt-3'></div>
                        </div>
                        <div className='col-md-10 text-center title-project mt-md-5 mt-3'>
                            <h3>Some Benefits Of Custom <br />Software Development Include</h3>
                        </div>
                        <div className='col-md-1 text-center mt-md-5'>
                            <div className='yellow-line-title mt-lg-4 mt-md-4 mt-3'></div>
                        </div>
                        {getgraphicbenefits?.map((data) => (
                            <>
                                <div className='col-xxl-4 xol-xl-4 col-lg-4 col-md-6  mt-5'>


                                    <div className="commerce">
                                        <div
                                            className="accordion"
                                            id="accordionPanelsStayOpenExample"
                                        >
                                            <div className="accordion-item">
                                                <h2
                                                    className="accordion-header"
                                                    id="panelsStayOpen-headingOne"
                                                >
                                                    <button
                                                        className="accordion-button collapsed"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target={`#panelsStayOpen-collapseOne${data?.id}`}
                                                        aria-expanded="false"
                                                        aria-controls="panelsStayOpen-collapseOne"
                                                    >
                                                        <div className=" service-vdo">
                                                            <video src={imageUrl + data?.video} className='advantage-video' autoPlay muted loop />
                                                        </div>

                                                        <div className="service-heading">
                                                            <h4 className="heading-E">{data?.title}</h4>
                                                        </div>
                                                    </button>
                                                </h2>
                                                <div
                                                    id={`panelsStayOpen-collapseOne${data?.id}`}
                                                    className="accordion-collapse collapse"
                                                    aria-labelledby="panelsStayOpen-headingOne"
                                                >
                                                    <div className="accordion-body">
                                                        {htmlToReactParser.parse(data?.description)}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </>
                        ))}

                    </div>

                    {/* Fourth section */}
                    <div className='row mb-md-5 mt-5'>
                        <div className='col-md-1 text-center mt-md-5'>
                            <div className='yellow-line-title mt-lg-4 mt-md-4 mt-3'></div>
                        </div>
                        <div className='col-md-10 text-center title-project mt-md-5 mt-3'>
                            <h3>The Different Types Of Graphic Designing  Done By Us Are</h3>
                        </div>
                        <div className='col-md-1 text-center mt-md-5'>
                            <div className='yellow-line-title mt-lg-4 mt-md-4 mt-3'></div>
                        </div>
                        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6'>
                            <div className='text-holder-advantage mt-5 '>
                                <p className=''>Graphic design is very vast, which means that there is a wide range of graphic design areas and their specializations. Each type of graphic design type requires specific skills and techniques.</p>

                                {getgraphicdesign?.map((data) => (
                                    <>
                                        <h3 className='mt-md-5'>{data?.title}</h3>
                                        <p className='mt-3'>{htmlToReactParser.parse(data?.description)}</p>
                                    </>
                                ))}
                            </div>
                        </div>
                        <div className='col-xxl-6 col-xl-6 col-lg-6 col-md-6 text-center'>
                            <img src={img3} className='graphic-img' alt='...' />
                        </div>
                    </div>
                </div>
            </section >
        </>
    )
}

export default GraphicAdvantage