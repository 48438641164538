import React, { useEffect, useRef } from "react";
// import homebanner from '../BlogBanner/video/home.mp4';
import '../BlogBanner/BlogBanner.css'
// import "../AboutBanner/aboutbanner.css";
import LazyLoad from "react-lazyload";

const BlogBanner = (props) => {

    const blogbannertitle = props.blogbannertitle;
    const blogbannersubtitle = props.blogbannersubtitle;
    const homebanner1 = props.homebanner;
    const mediatype = props.mediatype;

    return (
        <>
            <section className="blog-banner-main about-banner-main">
                <div className="row ms-0 me-0">
                    <div className='col-md-12 p-0'>
                        <div className="blog-banner about-banner">
                            {mediatype === 'Video' ? (
                                <LazyLoad offset={100} once>
                                    <video
                                        src={homebanner1}
                                        muted
                                        autoPlay
                                        loop
                                        className="d-block video-class-about"
                                    />
                                </LazyLoad>
                            ) : (
                                <img
                                    src={homebanner1}
                                    className="d-block video-class-about"
                                    height={500}
                                />
                            )}
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="img-overlay ">
                                <div className="text-holder-banner">
                                    <p>{blogbannertitle}</p>
                                    <h2>{blogbannersubtitle}</h2>
                                </div>
                            </div>
                        </div>
                        <div className="overlay"></div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default BlogBanner
