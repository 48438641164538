import React, { useEffect, useRef,useState,useContext } from 'react'
// import sd_banner from "../Videos/softwarevdo.mp4"
import LazyLoad from 'react-lazyload';
import { Context } from '../../utils/context';
const Banner = () => {

  const { getData, imageUrl } = useContext(Context);
  
  
  const [getdeveloperbanner, setDeveloperBanner] = useState();

  const getdevbanner = async () => {

    const res = await getData(`/globalbanner?bannertype=softwaredevelopment`);

    if (res?.success) {

      setDeveloperBanner(res.data.list);

    }
    else {
      console.error('Error fetching  data:', res?.error);
    }

  }

  useEffect(() => {


    getdevbanner();
    

  }, []);


  return (
    <>
      <section className="sd-banner-main about-banner-main ">
        <div className='container-fluid p-0'>
          <div className="row me-0 ms-0  ">
            <div className='col-md-12 p-0'>
            {getdeveloperbanner?.map((data) => (
              <div className="sd-banner about-banner">
              {data?.mediatype === 'Video' ? (
                <LazyLoad offset={100} once>
                  <video src={imageUrl + data?.video} 
                    muted
                    autoPlay
                    loop 
                    className="d-block video-class-about" />
                </LazyLoad>
                ) : (
                  <img
                    key={data?.id} src={imageUrl + data?.video}
                    className="d-block video-class-about"
                    height={500}
                  />
                )}

                <div class="top-left">
                  <p className='case'>{data?.title}</p>
                  <h1 className='design'>{data?.subtitle}</h1>
                </div>

                <div className='overlay'></div>

              </div>
            ))}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Banner