import React, { useEffect, useState } from "react";
import { useWindowScroll } from "react-use";
import Arrow from "../../AboutUs/Popup/animation/Arrow";
import '../ScrollTop/ScrollButton.css'

const ScrollIndicator = () => {
    const { x, y } = useWindowScroll();
    const [scrolled, setScrolled] = useState(0);


    useEffect(() => {
        const height =
            document.documentElement.scrollHeight -
            document.documentElement.clientHeight;
        setScrolled((y / height) * 100);
    }, [y]);

    return (
        <div className="scroll-container">
            {/* <div className="indicator" style={{ width: `${scrolled}%` }}></div> */}
            <div className="slide-down-div">
                <button type="button" style={{ width: `${scrolled}%` }}></button>
            </div>
        </div>
    );
};

export default ScrollIndicator;