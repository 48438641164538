import React, { useContext, useState, useEffect } from 'react'
import './benefitsui.css'
import ImproveEffiency from '../../animation/improve-efficiency/ImproveEffiency'
import Scalability from '../../animation/scalibility/Scalability'
import Profitability from '../../animation/profitability/Profitability'
import animationData1 from "../../animation/improve-efficiency/improve-efficiency.json";
import animationData2 from "../../animation/scalibility/development-team.json"
import animationData3 from "../../animation/profitability/profit-gains.json";
import { Context } from '../../utils/context.js';

const BenefitsUi = () => {

    const { getData, imageUrl } = useContext(Context);
    var HtmlToReactParser = require('html-to-react').Parser;
    var htmlToReactParser = new HtmlToReactParser();

    const [getdesignbenefits, setDesignBenefits] = useState();
    const getbenefits = async () => {
        const res = await getData(`/uiuxdesignbenefit`);
        if (res?.success) {

            setDesignBenefits(res?.data);
        }
        else {
            console.error('Error fetching  data:', res?.error);
        }
    }

    useEffect(() => {
        getbenefits();
    }, [])

    return (
        <>
            <section className='benefits-software'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-1 col-md-1 col-sm-1'>
                            <div className='yellow-border mt-5'></div>
                        </div>
                        <div className='col-lg-10 col-md-10 col-sm-10'>
                            <div className='heading-holder mb-lg-5 mb-mb-5 mb-3'>
                                <h4>Some Benefits Of Custom Software <br />Development Include</h4>
                            </div>
                        </div>
                        <div className='col-lg-1 col-md-1 col-sm-1 mt-0'>
                            <div className='yellow-border mt-lg-5  mt-md-5 mt-sm-5 mt-0'></div>
                        </div>
                    </div>
                    <div className='row mt-4'>
                        {getdesignbenefits?.map((item) => (
                            <div className="col-lg-4 col-md-6 col-sm-6 mt-3">
                                <div className="commerce">
                                    <div class="accordion" id="accordionPanelsStayOpenExample">
                                        <div class="accordion-item">
                                            <h2
                                                class="accordion-header"
                                                id="panelsStayOpen-headingOne"
                                            >
                                                <button
                                                    class="accordion-button collapsed"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target={`#panelsStayOpen-collapseOne${item?.id}`}
                                                    aria-expanded="false"
                                                    aria-controls="panelsStayOpen-collapseOne"
                                                >
                                                    <div className="service-vdo">
                                                        <video
                                                            className="video-sites"
                                                            src={imageUrl + item?.video}
                                                            height={300}
                                                            muted
                                                            autoPlay
                                                            loop
                                                        ></video>
                                                    </div>

                                                    <div className=" service-heading">
                                                        <h5 className="heading-E text-center">
                                                            {item?.title}
                                                        </h5>
                                                    </div>
                                                </button>
                                            </h2>
                                            <div
                                                id={`panelsStayOpen-collapseOne${item?.id}`}
                                                class="accordion-collapse collapse"
                                                aria-labelledby="panelsStayOpen-headingOne"
                                            >
                                                <div class="accordion-body">
                                                    {htmlToReactParser.parse(item?.description)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            // <div className='col-lg-4 col-md-6 col-sm-12'>
                            //     <div className='card mb-3'>
                            //         <video src={imageUrl + item?.video} autoPlay muted loop />
                            //         <div class="card-body">
                            //             <h5>{item?.title}</h5>
                            //             <p>{htmlToReactParser.parse(item?.description)}</p>
                            //         </div>
                            //     </div>
                            // </div>
                        ))}
                        {/* <div className='col-lg-4 col-md-6 col-sm-12'>
                            <div className='card mb-3'>
                                <ImproveEffiency animationData = {animationData2}/>
                                <div class="card-body">
                                    <h5>Scalability</h5>
                                    <p>Our designers and developers assess future needs and requirements as part of their requirements gathering.</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-6 col-sm-12'>
                            <div className='card mb-3'>
                                <ImproveEffiency animationData = {animationData3}/>
                                <div class="card-body">
                                    <h5>Profitability</h5>
                                    <p>It’s possible to make money with custom software development based on the terms and conditions of the project.</p>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>
        </>
    )
}

export default BenefitsUi