import React, { useContext, useState, useEffect } from 'react'
import './marketing.css';
import { Context } from '../../utils/context.js';

const Marketing = () => {

    const { getData, imageUrl } = useContext(Context);
    var HtmlToReactParser = require('html-to-react').Parser;
    var htmlToReactParser = new HtmlToReactParser();

    const [getdesignmarketingproject, setDesignMarketingProject] = useState();
    const getproject = async () => {
        const res = await getData(`/uiuxdesignproject`);
        if (res?.success) {
            setDesignMarketingProject(res?.data);
        }
        else {
            console.error('Error fetching  data:', res?.error);
        }
    }

    useEffect(() => {
        getproject();
    }, [])



    return (
        <>
            <section className='marketing-design'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-12'>
                            <div className='heading-holder text-center'>
                                <h4>Our Digital Marketing Projects</h4>
                            </div>
                        </div>

                        {getdesignmarketingproject?.map((item) => (
                            <div className='col-lg-6 col-md-6'>
                                <div className='project mb-5' type="button" data-bs-toggle="modal" data-bs-target={`#hotalModal${item?.id}`}>
                                    <h5>{htmlToReactParser.parse(item?.title)}</h5>
                                </div>
                            </div>
                        ))}
                        {/* <div className='col-lg-6 col-md-6'>
                            <div className='project mb-5' >
                                <h5>SNAKE GAMES</h5>
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-6'>
                            <div className='project mb-5' type="button" data-bs-toggle="modal" data-bs-target="#astrologyModal">
                                <h5>ASTROLOGY</h5>
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-6'>
                            <div className='project mb-5'>
                                <h5>ORGANO INDIA</h5>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>


            <div className='development'>
                {getdesignmarketingproject?.map((item) => (
                    <div class="modal fade development-modal" id={`hotalModal${item?.id}`} tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog   modal-fullscreen">
                            <div class="modal-content hotal-content">
                                <div className="designing-img-holder">
                                    <img
                                        src={imageUrl + item?.image}
                                        className="designing-img"
                                        alt=""
                                    />
                                </div>
                                <div class="modal-header">
                                    <button type="button" class="btn-close mt-4" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div class="modal-body">
                                    <div className='container'>
                                        <div className='col-lg-4 col-md-6'>
                                            <div className='content-holder'>
                                                <h4>{item?.subtitle}</h4>
                                                <h5>{item?.hsubtitle}</h5>
                                                <div className='border-line my-3'></div>
                                                <p>{htmlToReactParser.parse(item?.description)}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>


            {/* *--hotalModal Modal--* */}
            {/* <div className='development'>
                <div class="modal fade" id="hotalModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog   modal-fullscreen">
                        <div class="modal-content hotal-content">
                            <div class="modal-header">
                                <button type="button" class="btn-close mt-4" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <div className='container'>
                                    <div className='col-lg-4 col-md-6'>
                                        <div className='content-holder'>
                                            <h4>PROFCYMA</h4>
                                            <h5>Hotel</h5>
                                            <div className='border-line my-3'></div>
                                            <p>We believe in ethical and accessible design solutions that are reliable and equal for everyone. Our tested and tried approach is proven and has made us the preferable option for design,
                                                across industries. Our previously designed products push the boundaries of design and reach the audiences effectively.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            {/* **--astrologyModal Modal-- 
            <div className='development'>
                <div class="modal fade" id="astrologyModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog   modal-fullscreen">
                        <div class="modal-content hotal-content">
                            <div class="modal-header">
                                <button type="button" class="btn-close mt-4" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <div className='container'>
                                    <div className='col-lg-4 col-md-6'>
                                        <div className='content-holder '>
                                            <h4>ASTROLOGY</h4>
                                            
                                            <div className='border-line my-3'></div>
                                            <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.
                                                At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    )
}

export default Marketing