import React, { useEffect, useState } from "react";
import "../WeFocus/WeFocus.css";
import userimg from "../../HomePage/HomeBanner/image/userimg.png";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {   } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";

import AOS from "aos";
import "aos/dist/aos.css";
import context from "react-bootstrap/esm/AccordionContext";

import { useContext } from "react";
import { Context } from "../../utils/context";

library.add(fas);

const WeFocus = () => {


  const { getData, imageUrl } = useContext(Context);
  const [getwefocusData, setWefocusdata] = useState();

  var HtmlToReactParser = require("html-to-react").Parser;
  var htmlToReactParser = new HtmlToReactParser();

  const getwefocus = async () => {
    const res = await getData(`/wefocus`);

    if (res?.success) {
      setWefocusdata(res.data);
    } else {
      console.error("Error fetching  data:", res?.error);
    }
  };

  useEffect(() => {
    AOS.init();
    AOS.refresh();
    getwefocus();
  }, []);

  return (
    <>
      <section className="WeFocus">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-4 col-lg-5 col-md-6 col-sm-6 col-9">
              <div className="swiper-wefocus">
                <Swiper
                  // install Swiper modules
                  modules={[Navigation]}
                  spaceBetween={10}
                  slidesPerView={1}
                  navigation={true}
                  onSwiper={(swiper) => console.log(swiper)}
                  onSlideChange={() => console.log("slide change")}
                >
                  {getwefocusData?.map((data) => (
                    <SwiperSlide>
                      <div className="carousel-item banner-slider active">
                        <div className="user-img-class">
                          <div>
                            <img
                              src={imageUrl + data?.backimage}
                              className="userimg img-fluid"
                              alt=""
                            />
                          </div>
                          <div className="text-holder-focus">
                            <h1 className="we-focus">{data?.title}</h1>
                            <p>
                              {htmlToReactParser.parse(data?.description)}
                              <br />
                            </p>
                          </div>
                          <div className="black_overlay"></div>
                          <div className="overlay"></div>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
            <div className="col-xl-7 col-lg-7 col-md-6 col-sm-6">
              <div className="title-focus">
                <h5 data-aos="fade-up" data-aos-duration="2000">
                  We focus on delivering <span> the highest quality </span> services                 
                </h5>
                <p>Our hard work, your growth !!</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WeFocus;
