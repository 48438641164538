import React, { useContext, useEffect, useRef, useState } from 'react'
// import career_banner from "./videos/Career_Banner.mp4"
import LazyLoad from 'react-lazyload';
import { Context } from '../utils/context';

const CarrerBanner1 = () => {

  const { getData, imageUrl } = useContext(Context);
  const [getcarrerbannerdata, setCarrerBannerData] = useState();

  const getbanner = async () => {

    const res = await getData(`/globalbanner?bannertype=carrers`);

    if (res?.success) {
      setCarrerBannerData(res.data.list);

    }

    else {
      console.error('Error fetching  data:', res?.error);
    }

  }

  const videoRef = useRef()


  useEffect(() => {


    getbanner();

  }, []);


  return (
    <>
      <section className="career-banner-main  about-banner-main">
        <div className='container-fluid p-0'>
          <div className="row ms-0 me-0">
            <div className='col-md-12 p-0'>
              {getcarrerbannerdata?.map((data) => (
                <div className="career-banner about-banner">
                  {data?.mediatype === 'Video' ? (
                    <LazyLoad offset={100} once>
                      <video
                        src={imageUrl + data?.video}
                        ref={videoRef}
                        muted
                        autoPlay
                        loop
                        className="d-block video-class-career"
                      />
                    </LazyLoad>
                  ) : (
                    <img
                      key={data?.id} src={imageUrl + data?.video}
                      className="d-block w-100 video-class-grid"
                      style={{ width: '100%' }}
                    />
                  )}

                  <div class="top-left">
                    <p className='case'>{data?.title}</p>
                    <h1 className='design'>{data?.subtitle}</h1>
                  </div>

                  <div className='overlay'>

                  </div>

                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default CarrerBanner1



