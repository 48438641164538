import React, { useEffect, useRef } from "react";
// import homebanner from '../BlogBanner/video/home.mp4';
import '../Blog/BlogBanner/BlogBanner.css'
import LazyLoad from "react-lazyload";

const BrandBanner = (props) => {
    const brandvideo = props.brandvideo;
    const brandtitle = props.brandtitle;
    const brandsubtitle = props.brandsubtitle;
    const mediatype = props.mediatype;


    return (
        <>
            <section className="blog-banner-main about-banner-main">
                <div className="row ms-0 me-0">
                    <div className='col-md-12 p-0'>
                        <div className="blog-banner about-banner">
                            {mediatype === 'Video' ? (
                                <LazyLoad offset={100} once>
                                    <video
                                        src={brandvideo}
                                        muted
                                        autoPlay
                                        loop
                                        className="d-block video-class-about"
                                    />
                                </LazyLoad>
                            ) : (
                                <img
                                    src={brandvideo}
                                    className="d-block video-class-about"
                                    height={500}
                                />
                            )}
                        </div>
                        <div className="img-overlay col-lg-6 col-md-6 col-sm-12">
                            <div className="text-holder-banner">
                                <h2>{brandtitle}</h2>
                                <p>{brandsubtitle}</p>
                            </div>
                        </div>

                        <div className="overlay"></div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default BrandBanner
