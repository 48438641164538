import React from 'react'
import '../Popup/Popup.css';
// import plusimg from '../Popup/image/plus.png'
import itpartner2 from '../../AboutUs/Popup/Popup.js'
import Plus from '../Popup/animation/Plus.js';
import Bg from '../Popup/animation/Bg.js'
// import itpartner2 from '../../AboutUs/Popup/image/partner.mp4'
const Popup = () => {
    return (
        <>
            <section className="services-popup-main">
                <button type="button" className="btn popup-button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasBottom" data-bs-whatever="@mdo"><Plus/></button>
                <div className="offcanvas offcanvas-bottom" tabindex="-1" id="offcanvasBottom" aria-labelledby="offcanvasBottomLabel">
                    <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvasBottomLabel"></h5>
                        <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    </div>
                    <div class="offcanvas-body small">
                        <div className='backround-ani'>
                            <Bg />
                        </div>
                        <div className='services-popup'>
                            <div className='row '>
                                <div className='col-md-1 bg-bottom-class'></div>
                                <div className='col-md-4'>
                                    <h1 className='services-title-name ms-5 mt-5'>Services</h1>
                                    <p className='subtitle-services ms-5'>that we provide</p>
                                    <div className='border-line-popup ms-5 '></div>
                                    <div className='list-services'>
                                        <ul className=' ms-3 mt-3'>
                                            <li>Directory</li>
                                            <li>Marketplace</li>
                                            <li>Community</li>
                                            <li>Jobs</li>
                                            <li>Events</li>
                                            <li>E-Learning</li>
                                            <li>Contribute</li>
                                            <li>Delivery</li>
                                            <li>Marketing</li>
                                            <li>Government</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className='col-md-6 mt-5'>
                                    <div className='dfjsh'>
                                    <video src={itpartner2} autoPlay="true" className="d-block w-100 video-class-popup" />
                                    </div>
                                  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Popup



