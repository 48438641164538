import React,{useState,useContext,useEffect} from 'react'
import '../DevelopProject/DeveloProject.css'
import Yellow from '../Animation/js/Yellow';
import {Context} from '../../utils/context.js'
import { Link } from 'react-router-dom';
const DevelopProject = () => {
    const [getecommerceproject, setEcommerceProject] = useState();
    const { getData, imageUrl } = useContext(Context);
    var HtmlToReactParser = require('html-to-react').Parser;
    var htmlToReactParser = new HtmlToReactParser();

    const [getdevelopmentproject, setDevelopmentProject] = useState();

    const getproject = async () => {

        const res = await getData(`/appdevandroid`);

        if (res?.success) {
            setDevelopmentProject(res.data)
        }
        else {
            console.error('Error fetching  data:', res?.error);
        }
    }

    useEffect(() => {
        getproject();
    }, []);

    return (
        <>
            <section className='Develop-Project'>
                <div className='container'>
                    <div className='row'>
                        
                        <div className='col-md-12'>
                       
                            <div className='develop-type mt-5'>
                            {getdevelopmentproject?.map((data) => (
                                <>
                                <h2>{data?.title}</h2>
                                {/* <ul> */}
                                    {htmlToReactParser.parse(data?.description)}
                                {/* </ul> */}
                                </>
                                ))}
                               
                            </div>
                            
                        </div>
                        
                        <div className='col-md-12 text-center'>
                            <div className='our-app   '>
                                <div className='row'>
                                    <div className='col-md-7'>
                                        <h5 className='text-center mt-4'>Our App Development <br /> Projects</h5>
                                    </div>
                                    <div className='col-md-4'>
                                    <Link to="/astrology">
                                        <Yellow/>
                                        <p>click Here</p></Link> 
                                        
                                      
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >

              {/**--astrologyModal Modal--**/}

      <div className="development">
        {getecommerceproject?.map((data, index) => (
          <div
            class="modal fade development-modal"
            id={`agroModal${data?.id}`}
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog  modal-fullscreen">
              <div class="modal-content hotal-content">
                <div className="designing-img-holder">
                  <img
                    src={imageUrl + data?.image}
                    className="designing-img"
                    alt=""
                  />
                </div>
                <div class="modal-header">
                  <button
                    type="button"
                    class="btn-close mt-4"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body">
                  <div className="container">
                    <div className="col-lg-4 col-md-6">
                      <div className="content-holder ">
                        <h4>{data?.subtitle}</h4>
                        <div className="border-line my-3"></div>
                        <p>
                          {htmlToReactParser.parse(data?.description)}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
        </>
    )
}

export default DevelopProject;