import React from 'react'
import './reviewanimation.css';
import Lottie from 'react-lottie';

const ReviewAnimation = ({animationData}) => {

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    return (
        <>
            <section className='review'>
                <div className='audience mx-auto'>
                    <Lottie className=""
                        options={defaultOptions}

                    />
                </div>
            </section>
        </>
    )
}

export default ReviewAnimation