import React, { useState } from 'react'
import FooterGlobal from './FooterGlobal/FooterGlobal';
import Enquiry_Form from '../Components/Contact-form/Enquiry_form';

const Footer = () => {
    const [isPopupOpen, setPopupOpen] = useState(false);
    
  const openPopup = () => {
    setPopupOpen(true);
  };

  const closePopup = () => {
    setPopupOpen(false);
  };

    return (
        <div>
           <FooterGlobal />
        </div>
    )
}

export default Footer;