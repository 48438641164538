import React, { useContext, useState, useEffect } from "react";
import "../OurServiceBanner/OurServiceBanner.css";
// import lappi from '../video/lappi.mp4'
import LazyLoad from "react-lazyload";
import { Context } from "../../utils/context.js";


const OurServiceBanner = (props) => {

  const { getData, imageUrl } = useContext(Context);
  const [getwebsitebanner, setWebsiteBanner] = useState();

  const getBanner = async () => {

    const res = await getData(`/globalbanner?bannertype=websitedevelopment`);

    if (res?.success) {
      setWebsiteBanner(res.data.list)
    }
    else {
      console.error('Error fetching  data:', res?.error);
    }
  }


  useEffect(() => {

    getBanner();

  }, []);

  return (

    <>
      <section className="ServiceBanner about-banner-main ">
        <div className="container-fluid">
          <div className="row">
            {getwebsitebanner?.map((data) => (
              <div className="col-md-12 p-0">
              <div className="about-banner">
                {data?.mediatype === 'Video' ? (
                  <LazyLoad offset={100} once>
                    <video
                      src={imageUrl + data?.video}
                      muted
                      autoPlay
                      loop
                      className="d-block video-class-about"
                    />
                  </LazyLoad>
                ) : (
                  <img
                    key={data?.id} src={imageUrl + data?.video}
                    className="d-block video-class-about"
                    style={{ width: '100%' }}
                  />
                )}
                
                <div className="content">
                  <h1 className="website title">
                    {data?.title}
                  </h1>
                  <p className="buisness title">
                    {data?.subtitle}
                  </p>
                </div>
                <div class="overlay"></div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default OurServiceBanner;
