import React from 'react'
import './Experts.css';
import Lottie from 'react-lottie';
import * as animationData from './experts.json';


function Experts() {


  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (


    <section className='main'>
      <div className='expert'>
        <Lottie className="me-auto"
          options={defaultOptions}

        />
      </div>
    </section>
  )
}

export default Experts;
