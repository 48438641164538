import React from 'react'
import Lottie from 'react-lottie';
import * as animationData from '../software-development.json';

function Method() {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    return (
        <>
            <div className='method-lotti'>
                <Lottie
                    options={defaultOptions}
                    // height={350}
                    // width={450}
                />
            </div>

        </>
    )
}

export default Method;