import React, { useState, useEffect } from "react";
import "./EnquiryForm.css";
import Mask_image from "../../Enquiry_form_Images/Mask Group 19.png";
import logo from "../../Enquiry_form_Images/logo.png";
import { Context } from "../../utils/context";
import { useContext } from "react";
import axios from "axios";

import { Link, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const Enquiry_Form = () => {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const { postData } = useContext(Context);
  const [errors, setErrors] = useState({});


  const [showModal, setShowModal] = useState({
    code: 0,
    message: "",
  });
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    industry_name: "",
    helpinfo: "",
  });

  const validateForm = () => {
    let errors = {};
    const regexAlfaNumric = /^[A-Za-z0-9]+(\s[A-Za-z0-9]+)*$/;
    const regexAlfa = /^[A-Za-z]+(\s[A-Za-z]+)*$/;
    const emailval =
      /^([A-Za-z0-9_\-\.])+\@(?!(?:[A-Za-z0-9_\-\.]+\.)?([A-Za-z]{2,4})\.\2)([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

    if (!formData.first_name) {
      errors.first_name = "First_name is required";
    } else if (!regexAlfaNumric.test(formData.title?.trim())) {
      errors.first_name = "Enter a valid first_name";
    }



    if (!formData.last_name) {
      errors.last_name = "Last_name is required";
    } else if (!regexAlfaNumric.test(formData.last_name?.trim())) {
      errors.last_name = "Enter a valid last_name";
    }

    if (!formData.email) {
      errors.email = "Email is required";
    } else if (!emailval.test(formData.email?.trim())) {
      errors.email = "Enter valid email id";
    }

    if (!formData.industry_name) {
      errors.industry_name = "Industry_name  is required";
    }

    if (!formData.helpinfo) {
      errors.helpinfo = "Informaton is required";
    } else if (!regexAlfaNumric.test(formData.main_topics?.trim())) {
      errors.main_topics = "Enter a valid data";
    }
    return errors;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };


  const handleSubmit = async (e) => {


    const validationErrors = validateForm();

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      try {
        const finalData = new FormData();
        finalData.append(`first_name`, formData.first_name);
        finalData.append(`last_name`, formData.last_name);
        finalData.append(`email`, formData.email);
        finalData.append(`industry_name`, formData.industry_name);
        finalData.append(`helpinfo`, formData.helpinfo);

        const res = await postData("/submitform", formData);
        if (res?.success) {
          console.log(res);
          setShow(true);
          setShowModal(false);
          // navigate('/');
          setErrors("");
          await setFormData({
            first_name: "",
            last_name: "",
            email: "",
            industry_name: "",
            helpinfo: "",
          });
          setTimeout(() => {
            setShow(true);
          }, );

          setShow(false);
        } else {

          console.error("Form submission failed:", res?.error);
        }

      } catch (error) {
        console.log(error);
      }
    }
  };
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const errorStyle = {
    color: "red",
    marginLeft: "5px",

  };
  useEffect(() => {
    if (show) {
      const timeoutId = setTimeout(() => {
        setShow(false);
      }, 4000);

      return () => clearTimeout(timeoutId);
    }
  }, [show]);

  useEffect(() => {
    const fakeEvent = {};
  }, [formData]);

  return (
    <>
      <section className="contact-section">
        <div className="Off-canvas-section cont-off">
          <div
            className="offcanvas offcanvas-top"
            tabindex="-1"
            id="contact_off-canvas"
            aria-labelledby="offcanvasTopLabel"
            style={{ top: "73px" }}
          >
            <div className="offcanvas-body p-0">
              <div className="mx-0 my-0">
                <form>
                  <div className="row main_sec  me-0 ms-0 ">
                    <div className="col-md-5 d-lg-block d-none left_sec p-0">
                      <div className="img_1">

                        <img
                          src={Mask_image}
                          alt="Mask_image"
                          className="img_2"
                        />
                        <div className="image-holder">
                          <img src={logo} alt="logo_image" className="img_logo" />
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-7 col-12 main_right_sec mx-auto p-0">
                      <div className="offcanvas-header">
                        <h5 id="offcanvasTopLabel"></h5>
                        <button
                          type="button"
                          className="btn-close text-reset"
                          data-bs-dismiss="offcanvas"
                          aria-label="Close"
                        ></button>
                      </div>

                      <div className="row me-0 ms-0">
                        <div className="col-lg-10 mx-auto">
                          <div className="right_sec">
                            <div>
                              <div className="heading">Talk to an Expert</div>
                              <p className="details">
                                We need a few details from you to put you in
                                touch with the right person.
                              </p>
                            </div>
                            <div className="row me-0 ms-0">
                              <div className="col-md-6 col-12">
                                <label className="label_name">
                                  First Name *
                                </label>
                                <input
                                  className="form-control"
                                  type="text"
                                  value={formData?.first_name}
                                  placeholder="Enter your First name"
                                  name="first_name"
                                  onChange={handleChange}
                                ></input>
                                {errors?.first_name && (
                                  <span style={errorStyle}>
                                    {errors?.first_name}
                                  </span>
                                )}
                              </div>

                              <div className="col-md-6 col-12">
                                <label className="label_name">
                                  Last Name *
                                </label>
                                <input
                                  className="form-control"
                                  type="text"
                                  value={formData?.last_name}
                                  placeholder="Enter your Last name"
                                  name="last_name"
                                  onChange={handleChange}
                                ></input>
                                {errors?.last_name && (
                                  <span style={errorStyle}>
                                    {errors?.last_name}
                                  </span>
                                )}
                              </div>
                              <br />
                              <div className="col-12">
                                <label className="label_name">Email *</label>
                                <input
                                  className="form-control"
                                  type="email"
                                  value={formData?.email}
                                  placeholder="Enter your Email"
                                  name="email"
                                  onChange={handleChange}
                                ></input>
                                <div>
                                  {errors?.email && (
                                    <span style={errorStyle}>
                                      {errors?.email}
                                    </span>
                                  )}
                                </div>
                                <label className="label_name">Industry *</label>
                                <input
                                  className="form-control"
                                  type="text"
                                  value={formData?.industry_name}
                                  placeholder="Enter Industry"
                                  name="industry_name"
                                  onChange={handleChange}
                                ></input>
                                <div>
                                  {errors?.industry_name && (
                                    <span style={errorStyle}>
                                      {errors?.industry_name}
                                    </span>
                                  )}
                                </div>
                                <label className="label_name">
                                  How we can help you *
                                </label>
                                <input
                                  className="form-control"
                                  type="text"
                                  value={formData?.helpinfo}
                                  placeholder="How we can help you"
                                  name="helpinfo"
                                  onChange={handleChange}
                                ></input>
                                <div>
                                  {errors?.helpinfo && (
                                    <span style={errorStyle}>
                                      {errors?.helpinfo}
                                    </span>
                                  )}
                                </div>

                                <button
                                  className="send_btn"
                                  type="button"
                                  onClick={handleSubmit}
                                >
                                  Send
                                </button>

                                {/* <button className="send_btn" type="reset">Reset</button> */}
                                {/* <Button variant="primary" onClick={handleShow,}>Launch demo modal</Button> */}
                                {/* <button className="send_btn" type="button" onClick={() => { handleSubmit(); handleShow(); }}>Send</button> */}
                              </div>
                              {show  && (
                                <span style={{ color: "green" }}>
                                  Form Submitted Successfully
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Enquiry_Form;
